import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

// we can access router now, but I think better will be to pass router data to action call from component
//import router from '../router'

import Address from './address/index'
import auth from './Auth/index'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        customer_ref: null,
        valuation_request_ref: null,
        loading: false,
        locked: null,
        status: null,
        helpPage: null,
        conflicting_valuation_requests: [],
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {
        getFullStore(state, getters, rootState, rootGetters) {
            let data = { ...state }
            data.Address.data = rootGetters['Address/getFullBuildingData']
            return data
        },
        flow_type(state) {
            return process.env.VUE_APP_FLOW_TYPE
        },
        getFeatures(state, gettes, rootState, rootGetters) {
            return rootGetters['Address/getFeatures']
        },
        getDVMFeatures(state, gettes, rootState, rootGetters) {
            return rootGetters['Address/getDVMFeatures']
        },
        getValuation(state, gettes, rootState, rootGetters) {
            return rootGetters['Address/getValuation']
        },
        isPredraft(state) {
            return state.status === 'predraft'
        },
        isDraft(state) {
            return state.status === 'draft'
        },
    },
    mutations: {
        SET_CUSTOMER_REF(state, ref) {
            state.customer_ref = ref
        },
        SET_CONFLICTING_VALUATION_REQUESTS(state, conflicting_valuation_requests) {
            state.conflicting_valuation_requests = conflicting_valuation_requests
        },
        SET_VALUATION_REQUEST_REF(state, ref) {
            state.valuation_request_ref = ref
        },
        // update data from api store
        UPDATE_STATUS(state, data) {
            Object.assign(state, data)
        },
        RESET_VAL(state) {
            Object.assign(state, getDefaultState())
        },
        SET_HELP_PAGE(state, page) {
            state.helpPage = page
        },
    },
    actions: {
        // update BE store
        updateStore(context, data) {
            let store = {
                data: data.data, // get data to pass
                customer_ref: data.customer_ref || context.state.customer_ref || null,
                valuation_request_ref:
                    data.valuation_request_ref ||
                    context.state.valuation_request_ref ||
                    null,
                locked: context.state.locked || false,
            }

            let query_param = ''
            if (store.valuation_request_ref == null) {
                data.action = 'create'
                query_param = `customer_ref=${store.customer_ref}`
                if (data.ignoreConflict) {
                    query_param += '&ignore_conflicts=true'
                }
            } else {
                query_param = `valuation_request_ref=${store.valuation_request_ref}`
            }
            return axios
                .post(
                    `${utils.urlJoin(
                        process.env.VUE_APP_VALUATION_API_URL,
                        'state'
                    )}?${query_param}&lang=${data.lang}&action=${data.action ||
                        'save-draft'}`,
                    store
                )
                .then((response) => {
                    if (
                        typeof data.update_from_response === 'undefined' ||
                        data.update_from_response
                    ) {
                        context.commit('UPDATE_STATUS', {
                            status: response.data.status,
                        })
                        if (response.data.valuation_request_ref) {
                            context.commit(
                                'SET_VALUATION_REQUEST_REF',
                                response.data.valuation_request_ref
                            )
                        }
                        if (response.data.data.avm_transaction) {
                            context.commit('Address/SET_AVM', {
                                avm_transaction: response.data.data.avm_transaction,
                                avm_rental: response.data.data.avm_rental,
                            })
                        }
                    }
                })
                .catch((error) => {
                    let response = error.response
                    if (
                        response.status === 403 &&
                        response.data.errors?.customer_ref_conflict
                    ) {
                        context.commit(
                            'SET_CONFLICTING_VALUATION_REQUESTS',
                            response.data.errors.customer_ref_conflict
                                .conflicting_valuation_requests
                        )
                    } else {
                        console.error(error)
                    }
                })
        },
        // get BE store
        getStore(context) {
            return axios
                .get(
                    `${utils.urlJoin(
                        process.env.VUE_APP_VALUATION_API_URL,
                        'state'
                    )}?valuation_request_ref=${context.state.valuation_request_ref}`
                )
                .then((result) => {
                    // commit locked status
                    context.commit('UPDATE_STATUS', {
                        locked: result.data.locked,
                        status: result.data.status,
                    })

                    if (typeof result.data.customer_ref !== 'undefined') {
                        context.commit('SET_CUSTOMER_REF', result.data.customer_ref)
                    }

                    // commit address info
                    context.commit(
                        'Address/SET_ADDRESS_TEXT',
                        result.data.data.address_text,
                        {
                            root: true,
                        }
                    )
                    context.commit('Address/SET_FEATURES', result.data.data.features, {
                        root: true,
                    })
                    context.dispatch('Address/fetchStreetviewInfo', { root: true })
                    if (typeof result.data.data.avm_transaction !== 'undefined') {
                        context.commit(
                            'Address/SET_AVM',
                            {
                                avm_transaction: result.data.data.avm_transaction,
                                avm_rental: result.data.data.avm_rental,
                            },
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.data.last_dvm_rejection !== 'undefined') {
                        context.commit(
                            'Address/SET_LAST_DVM_REJECTION',
                            result.data.data.last_dvm_rejection,
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.data.valuation !== 'undefined') {
                        let date = result.data.data.valuation.date
                        if (Array.isArray(date)) {
                            date = date[0]
                        }
                        if (typeof date === 'string') {
                            date = new Date(date)
                        }
                        context.commit(
                            'Address/SET_VALUATION',
                            {
                                date: date,
                                type: result.data.data.valuation.type,
                                market_value: result.data.data.valuation.market_value,
                                reconstruction_value: result.data.data.valuation
                                    .reconstruction_value
                                    ? result.data.data.valuation.reconstruction_value
                                    : null,
                                rental_value: result.data.data.valuation.rental_value,
                                forced_sale_value:
                                    result.data.data.valuation.forced_sale_value,
                                valuer: result.data.data.valuation.valuer,
                            },
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.data.dvm_features !== 'undefined') {
                        context.commit(
                            'Address/SET_DVM_FEATURES',
                            result.data.data.dvm_features,
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.data.map_scene2d !== 'undefined') {
                        context.commit(
                            'Address/SET_MAP_SCENE2D',
                            result.data.data.map_scene2d,
                            {
                                root: true,
                            }
                        )
                    } else if (result.data.data.features.building_id !== null) {
                        return context.dispatch('Address/fetch_map_data')
                    }
                    if (typeof result.data.data.map_scene3d !== 'undefined') {
                        context.commit(
                            'Address/SET_MAP_SCENE3D',
                            result.data.data.map_scene3d,
                            {
                                root: true,
                            }
                        )
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    },
    modules: {
        Address,
        auth,
    },
})
