<i18n>
{
  "nl-BE": {
    "title": "Welk type pand wordt gefinancierd?",
    "tooltip_text": "Financiert de klant een ééngezinswoning of een appartement?",
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en-BE": {
    "title": "What type of building is being financed?",
    "tooltip_text": "Is the Borrower financing a single-family home or an apartment?",
    "previous": "Previous",
    "next": "Next"
  },
  "fr-BE": {
    "title": "Quel est le type de bien financé ?",
    "tooltip_text": "Est-que le client finance une maison unifamiliale ou un appartement?",
    "previous": "Précédent",
    "next": "Suivant"
  }
}
</i18n>

<template>
    <div class="container section is-fluid">
        <title-with-tooltip :label="$t('tooltip_text')">
            <template #title>
                {{ $t('title') }}
            </template>
        </title-with-tooltip>

        <div class="columns">
            <div class="column">
                <building-type-select
                    ref="parent"
                    :building-type.sync="buildingType"
                    :value="buildingType"
                    class="pb-4"
                />
                <div class="is-flex is-justify-content-space-between pt-5 is-btn-box">
                    <b-button
                        icon-left="arrow-left"
                        type="is-dark"
                        tag="router-link"
                        size="is-medium"
                        :to="prevLink"
                    >
                        <span>{{ $t('previous') }}</span>
                    </b-button>
                    <b-button
                        v-if="buildingType != null"
                        tag="router-link"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :to="{
                            name: 'request',
                            params: {
                                lang: $route.params.lang,
                                action: 'address',
                            },
                            query: $route.query,
                        }"
                    >
                        <span>{{ $t('next') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import BuildingTypeSelect from '@/shared/components/BuildingTypeSelect.vue'

export default {
    name: 'BuildingType',
    components: { BuildingTypeSelect },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['flow_type', 'getFullStore']),
        buildingType: {
            get() {
                return this.$store.state.Address.data.features.f_building_type
            },
            set(value) {
                this.SET_FEATURES({ f_building_type: value })
            },
        },
        prevLink() {
            if (this.flow_type === 'avm_demo') {
                return {
                    name: 'request',
                    params: {
                        lang: this.$route.params.lang,
                        action: 'start',
                    },
                    query: {},
                }
            }
            return {
                name: 'request',
                params: {
                    lang: this.$route.params.lang,
                    action: 'enter',
                },
                query: {},
            }
        },
    },
    watch: {
        buildingType() {
            this.$router.push({
                name: 'request',
                params: {
                    lang: this.$route.params.lang,
                    action: 'address',
                },
                query: this.$route.query,
            })
        },
    },
    methods: {
        ...mapMutations('Address', ['SET_FEATURES']),
    },
}
</script>

<style lang="scss" scoped>
.is {
    &-text {
        &_line {
            font-family: Lato;
            font-style: normal;
            font-size: 18px;
            line-height: 22px;
        }
    }
    &-btn-box {
        max-width: 760px;
        @media screen and (max-width: 1023px) {
            max-width: 100%;
        }
    }
}
</style>
