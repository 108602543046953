<i18n>
{
    "fr-BE":{
        "step4": {
            "plot_title": {
                "f_parcel_area": "Suface de la parcelle",
                "f_living_area": "Surface habitable",
                "f_construction_year": "Année de construction",
                "f_epc": "PEB"
            },
            "subtitle": "C'est une maison avec un jardin relativement petit | La maison est assez spacieuse | C'est une maison assez récente",
            "details": {
                "link": "Voir en détail >",
                "close": "Fermer",
                "title": "La principale caractéristique est la surface habitable",
                "text": "Plus la surface habitable est grande, plus la maison est chère. Le graphique ci-dessous montre la corrélation plus en détail"
            }
        }
    },
    "nl-BE":{
        "step4": {
            "plot_title": {
                "f_parcel_area": "Perceel oppervlakte",
                "f_living_area": "Bewoonbare oppervlakte",
                "f_construction_year": "Bouwjaar",
                "f_epc": "EPC"
            },
            "subtitle": "Het is een woning met een relatief kleine tuin | De woning is redelijk ruim | Het is een vrij recente woning",
            "details": {
                "link": "Bekijk in detail >",
                "close": "Sluiten",
                "title": "De belangrijkste eigenschap is de bewoonbare oppervlakte",
                "text": "Hoe groter de bewoonbare oppervlakte, hoe duurder de woning. Op onderstaande grafiek zie je de correlatie in meer detail"
            }
        }
    },
    "en-BE":{
        "step4": {
            "plot_title": {
                "f_parcel_area": "Parcel area",
                "f_living_area": "Living area",
                "f_construction_year": "Construction year",
                "f_epc": "EPC"
            },
            "subtitle": "It is a house with a relatively small garden | The house is quite spacious | It is a fairly recent home",
            "details": {
                "link": "View details >",
                "close": "Close",
                "title": "The main feature is the habitable surface",
                "text": "The larger the habitable surface, the more expensive the house. The graph below shows the correlation in more detail"
            }
        }
    }
}
</i18n>

<template>
    <div id="step4">
        <transition-group v-show="!detailChart" name="chart-list" tag="div">
            <div v-for="chart in chartsToRender" :key="chart.id" class="chart-box">
                <div class="chart-title">
                    {{ $t(`step4.plot_title.${chart.feature}`) }}
                </div>
                <!-- <div
                    v-if="chart.detailed"
                    class="chart-detail-link"
                    @click="openDetails(chart)"
                >
                    {{ $t('step4.details.link') }}
                </div> -->
                <!-- <div class="chart"></div> -->
                <univariate-plots
                    class="chart"
                    :feature="chart.feature"
                    :estimation_results_data="addressInfo.estimationResultsData"
                    :input_data="getFeatures"
                />
            </div>
        </transition-group>
        <div v-if="detailChart" class="deatiled-section">
            <transition name="fade-opacity">
                <div
                    v-show="animation.detail_chart"
                    class="detailed-close"
                    @click="closeDetails"
                >
                    <img src="@/assets/icons/bttn-close-big.svg" alt="" />
                    {{ $t('step4.details.close') }}
                </div>
            </transition>
            <transition name="fade-move-opacity-small">
                <div v-show="animation.detail_chart" class="detailed-title">
                    {{ $t('step4.details.title') }}
                </div>
            </transition>
            <transition name="fade-move-opacity-small">
                <div v-show="animation.detail_chart" class="detailed-subtitle">
                    {{ $t('step4.details.text') }}
                </div>
            </transition>
            <transition name="fade-move-opacity-small">
                <div v-show="animation.detail_chart2" class="detailed-chart-box">
                    <!-- <img src="@/assets/images/group-19.svg" alt=""> -->
                    <correlation-plot
                        :just_charts="true"
                        :estimation_results_data="addressInfo.estimationResultsData"
                        :input_data="getFeatures"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import CorrelationPlot from './CorrelationPlot.vue'
import UnivariatePlots from './UnivariatePlots.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Step4',
    components: { CorrelationPlot, UnivariatePlots },
    data() {
        return {
            animation: {
                listDelay: 200,
                detail_chart: false,
                detail_chart2: false,
            },
            chartsToRender: [],
            // it and
            features: ['f_living_area', 'f_construction_year', 'f_building_area'],
            detailChart: false, // false
        }
    },
    computed: {
        ...mapGetters('Address', ['getFeatures', 'addressInfo']),
        available_charts() {
            let features = []
            if (this.getFeatures.f_building_type === 'house') {
                features = [
                    'f_parcel_area',
                    'f_living_area',
                    'f_construction_year',
                    'f_epc',
                ]
            } else {
                features = ['f_living_area', 'f_construction_year', 'f_epc']
            }
            let charts = []
            for (let i = 0; i < features.length; i++) {
                if (this.getFeatures[features[i]] !== null) {
                    charts.push({
                        id: i + 1,
                        feature: features[i],
                        detailed: features[i] == 'f_living_area',
                    })
                }
            }
            return charts
        },
    },
    created() {
        this.showList(0).then()
    },
    methods: {
        showList(i) {
            return new Promise((resolve, reject) => {
                if (i === 0) this.chartsToRender = []
                setTimeout(() => {
                    this.chartsToRender.push(this.available_charts[i])
                    if (i + 1 === this.available_charts.length) {
                        resolve(true)
                        return
                    } else this.showList(++i).then((res) => resolve(false))
                }, this.animation.listDelay)
            })
        },
        hideList() {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (this.chartsToRender.length === 0) {
                        resolve(true)
                        return
                    } else {
                        this.chartsToRender.pop()
                        this.hideList().then((res) => resolve(false))
                    }
                }, this.animation.listDelay)
            })
        },
        openDetails(chart) {
            this.hideList().then((res) => {
                this.detailChart = true
                setTimeout(() => {
                    this.animation.detail_chart = true
                    setTimeout(() => {
                        this.animation.detail_chart2 = true
                    }, 200)
                }, 100)
            })
        },
        closeDetails() {
            this.animation.detail_chart = false
            this.animation.detail_chart2 = false
            setTimeout(() => {
                this.detailChart = false
                this.showList(0).then((res) => {})
            }, 200)
        },
    },
}
</script>

<style lang="scss" scoped>
.chart-box {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 8px;
    box-sizing: border-box;
}
.chart {
    width: 100%;
    height: 120px;
    margin-top: 40px;
}
.chart-title {
    font-size: 18px;
    font-weight: bold;
    color: #001837;
    margin-bottom: 10px;
}
.chart-subtitle {
    font-size: 16px;
    line-height: 1.5;
    color: #798da6;
    margin-bottom: 15px;
}
.chart-detail-link {
    font-weight: bold;
    text-align: right;
    color: #0096ff;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -5px;
    margin-bottom: 10px;
}
.deatiled-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.detailed-close {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #001837;
    cursor: pointer;
    transition: 0.4s all;
    img {
        margin-right: 14px;
    }
}
.detailed-title {
    max-width: 455px;
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    color: #001837;
    margin-bottom: 20px;
    margin-top: 10px;
    transition: 0.4s all;
    position: relative;
}
.detailed-subtitle {
    max-width: 455px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #798da6;
    margin-bottom: 60px;
    transition: 0.4s all;
    position: relative;
}
.detailed-chart-box {
    position: relative;
    transition: 0.4s all;
    width: 816px;
    height: 464px;
}
</style>
