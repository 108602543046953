var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid section is-max-desktop"},[_c('div',{staticClass:"columns is-mobile is-multiline is-variable is-6"},[_c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")])]),(_vm.getFeatures.buildingnotfound)?_c('extra-info-not-found',{attrs:{"valid":_vm.validAll},on:{"notFound":function($event){_vm.notFoundValid = $event}}}):_vm._e(),_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"is-flex is-align-content-flex-end"},[_c('b-field',{attrs:{"custom-class":"is-small-tooltip"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.f_epc'))+" "),_c('b-tooltip',{attrs:{"type":"is-primary","label":_vm.$t('features.f_epc_tooltip'),"multilined":""}},[_c('div',{staticClass:"is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"},[_vm._v(" ? ")])])],1),_c('b-radio-button',{attrs:{"native-value":true,"size":"is-medium","type":"is-primary"},model:{value:(_vm.structural.is_epc_number),callback:function ($$v) {_vm.$set(_vm.structural, "is_epc_number", $$v)},expression:"structural.is_epc_number"}},[_c('span',[_vm._v(_vm._s(_vm.$t('unit.kwh/m2year')))])]),_c('b-radio-button',{attrs:{"native-value":false,"size":"is-medium","type":"is-primary"},model:{value:(_vm.structural.is_epc_number),callback:function ($$v) {_vm.$set(_vm.structural, "is_epc_number", $$v)},expression:"structural.is_epc_number"}},[_c('span',[_vm._v("Label")])])],2),(_vm.structural.is_epc_number)?_c('b-field',{staticClass:"is-w100 ml-5 mt-5 pt-2",attrs:{"type":{
                        'is-danger':
                            !_vm.$v.structural.f_epc_numeric.decimal ||
                            !_vm.$v.structural.f_epc_numeric.required ||
                            !_vm.$v.structural.f_epc_numeric.maxValue ||
                            (_vm.is_incoherent('f_epc') &&
                                !_vm.$v.structural.f_epc_numeric.$dirty),
                    },"message":[
                        !_vm.$v.structural.f_epc_numeric.decimal
                            ? _vm.$t('number_only')
                            : null,
                        !_vm.$v.structural.f_epc_numeric.maxValue
                            ? _vm.$t('value_too_large')
                            : null,
                        !_vm.$v.structural.f_epc_numeric.required
                            ? _vm.$t('field_required')
                            : null,
                        _vm.is_incoherent('f_epc') && !_vm.$v.structural.f_epc_numeric.$dirty
                            ? _vm.$t('incoherent_feature')
                            : null ]}},[_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.f_epc_numeric.$touch()}},model:{value:(_vm.structural.f_epc_numeric),callback:function ($$v) {_vm.$set(_vm.structural, "f_epc_numeric", $$v)},expression:"structural.f_epc_numeric"}})],1):_vm._e(),(!_vm.structural.is_epc_number)?_c('b-field',{staticClass:"is-w100 ml-5 mt-5 pt-2",attrs:{"type":{
                        'is-danger': !_vm.$v.structural.f_epc_label.required,
                    },"message":[
                        !_vm.$v.structural.f_epc_label.required
                            ? _vm.$t('field_required')
                            : null ]}},[_c('b-autocomplete',{attrs:{"size":"is-medium","placeholder":"B","open-on-focus":true,"data":_vm.epc_labels,"disabled":!_vm.isDraft},model:{value:(_vm.structural.f_epc_label),callback:function ($$v) {_vm.$set(_vm.structural, "f_epc_label", $$v)},expression:"structural.f_epc_label"}})],1):_vm._e()],1)]),_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-6"},[_c('div',{staticClass:"column is-6-desktop is-12-mobile"},[_c('text-input',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_construction_year'),"tooltip":_vm.$t('features.f_construction_year_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_construction_year.decimal ||
                                !_vm.$v.structural.f_construction_year.required ||
                                ((!_vm.$v.structural.f_construction_year.minValue ||
                                    !_vm.$v.structural.f_construction_year.maxValue) &&
                                    _vm.$v.structural.f_construction_year.decimal) ||
                                (_vm.is_incoherent('f_construction_year') &&
                                    !_vm.$v.structural.f_construction_year.$dirty),
                        },"message":[
                            (!_vm.$v.structural.f_construction_year.minValue ||
                                !_vm.$v.structural.f_construction_year.maxValue) &&
                            _vm.$v.structural.f_construction_year.decimal
                                ? _vm.$t('valid_years')
                                : null,
                            !_vm.$v.structural.f_construction_year.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_construction_year.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_construction_year') &&
                            !_vm.$v.structural.f_construction_year.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.structural.f_construction_year.$touch()}},model:{value:(_vm.structural.f_construction_year),callback:function ($$v) {_vm.$set(_vm.structural, "f_construction_year", $$v)},expression:"structural.f_construction_year"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('text-input',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_living_area'),"tooltip":_vm.$t('features.f_living_area_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_living_area.required ||
                                !_vm.$v.structural.f_living_area.maxValue ||
                                !_vm.$v.structural.f_living_area.decimal ||
                                (_vm.is_incoherent('f_living_area') &&
                                    !_vm.$v.structural.f_living_area.$dirty),
                        },"message":[
                            !_vm.$v.structural.f_living_area.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_living_area.maxValue
                                ? _vm.$t('value_too_large')
                                : null,
                            !_vm.$v.structural.f_living_area.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_living_area') &&
                            !_vm.$v.structural.f_living_area.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.structural.f_living_area.$touch()}},model:{value:(_vm.structural.f_living_area),callback:function ($$v) {_vm.$set(_vm.structural, "f_living_area", $$v)},expression:"structural.f_living_area"}})],1)])]),(_vm.is_apartment)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-6"},[_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_bedrooms'),"tooltip":_vm.$t('features.f_bedrooms_tooltip'),"min":0,"max":20,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_bedrooms') &&
                                !_vm.$v.apartment.f_bedrooms.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_bedrooms') &&
                            !_vm.$v.apartment.f_bedrooms.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_bedrooms.$touch()}},model:{value:(_vm.apartment.f_bedrooms),callback:function ($$v) {_vm.$set(_vm.apartment, "f_bedrooms", $$v)},expression:"apartment.f_bedrooms"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_balcony_present'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_balcony_present_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_balcony_present') &&
                                !_vm.$v.apartment.f_balcony_present.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_balcony_present') &&
                            !_vm.$v.apartment.f_balcony_present.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_balcony_present.$touch()}},model:{value:(_vm.apartment.f_balcony_present),callback:function ($$v) {_vm.$set(_vm.apartment, "f_balcony_present", $$v)},expression:"apartment.f_balcony_present"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_bottom_floor'),"tooltip":_vm.$t('features.f_bottom_floor_tooltip'),"min":-5,"max":0,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_bottom_floor') &&
                                !_vm.$v.apartment.f_bottom_floor.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_bottom_floor') &&
                            !_vm.$v.apartment.f_bottom_floor.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_bottom_floor.$touch()}},model:{value:(_vm.apartment.f_bottom_floor),callback:function ($$v) {_vm.$set(_vm.apartment, "f_bottom_floor", $$v)},expression:"apartment.f_bottom_floor"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_top_floor'),"tooltip":_vm.$t('features.f_top_floor_tooltip'),"max":40,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_top_floor') &&
                                !_vm.$v.apartment.f_top_floor.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_top_floor') &&
                            !_vm.$v.apartment.f_top_floor.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_top_floor.$touch()}},model:{value:(_vm.apartment.f_top_floor),callback:function ($$v) {_vm.$set(_vm.apartment, "f_top_floor", $$v)},expression:"apartment.f_top_floor"}})],1),_c('range-selector',{attrs:{"label":_vm.$t('features.f_floors'),"tooltip":_vm.$t('features.f_floors_tooltip'),"message":[
                        !_vm.$v.apartment.f_floors.consecutive
                            ? _vm.$t('floors_not_consecutive')
                            : null,
                        _vm.is_incoherent('f_floors') && !_vm.$v.apartment.f_floors.$dirty
                            ? _vm.$t('incoherent_feature')
                            : null ],"type":{
                        'is-danger':
                            !_vm.$v.apartment.f_floors.consecutive ||
                            (_vm.is_incoherent('f_floors') &&
                                !_vm.$v.apartment.f_floors.$dirty),
                    },"range-list":_vm.floor_list,"button-label-renderer":function (floor) { return (floor === 0 ? _vm.$t('ground_floor') : floor); }},on:{"input":function($event){return _vm.$v.apartment.f_floors.$touch()}},model:{value:(_vm.apartment.f_floors),callback:function ($$v) {_vm.$set(_vm.apartment, "f_floors", $$v)},expression:"apartment.f_floors"}}),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_garden_common'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_garden_common_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_garden_common') &&
                                !_vm.$v.apartment.f_garden_common.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_garden_common') &&
                            !_vm.$v.apartment.f_garden_common.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_garden_common.$touch()}},model:{value:(_vm.apartment.f_garden_common),callback:function ($$v) {_vm.$set(_vm.apartment, "f_garden_common", $$v)},expression:"apartment.f_garden_common"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_garden_private'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_garden_private_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_garden_private') &&
                                !_vm.$v.apartment.f_garden_private.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_garden_private') &&
                            !_vm.$v.apartment.f_garden_private.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_garden_private.$touch()}},model:{value:(_vm.apartment.f_garden_private),callback:function ($$v) {_vm.$set(_vm.apartment, "f_garden_private", $$v)},expression:"apartment.f_garden_private"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_n_closed_garage_tooltip'),"is-big":true,"label":_vm.$t('features.f_n_closed_garage'),"min":0,"max":20,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_n_closed_garage') &&
                                !_vm.$v.apartment.f_n_closed_garage.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_n_closed_garage') &&
                            !_vm.$v.apartment.f_n_closed_garage.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_n_closed_garage.$touch()}},model:{value:(_vm.apartment.f_n_closed_garage),callback:function ($$v) {_vm.$set(_vm.apartment, "f_n_closed_garage", $$v)},expression:"apartment.f_n_closed_garage"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"is-big":true,"label":_vm.$t('features.f_n_parking_spot'),"tooltip":_vm.$t('features.f_n_parking_spot_tooltip'),"min":0,"max":40,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_n_parking_spot') &&
                                !_vm.$v.apartment.f_n_parking_spot.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_n_parking_spot') &&
                            !_vm.$v.apartment.f_n_parking_spot.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_n_parking_spot.$touch()}},model:{value:(_vm.apartment.f_n_parking_spot),callback:function ($$v) {_vm.$set(_vm.apartment, "f_n_parking_spot", $$v)},expression:"apartment.f_n_parking_spot"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_cellar_attic'),"disabled":!_vm.isDraft,"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_cellar_attic') &&
                                !_vm.$v.apartment.f_cellar_attic.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_cellar_attic') &&
                            !_vm.$v.apartment.f_cellar_attic.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.apartment.f_cellar_attic.$touch()}},model:{value:(_vm.apartment.f_cellar_attic),callback:function ($$v) {_vm.$set(_vm.apartment, "f_cellar_attic", $$v)},expression:"apartment.f_cellar_attic"}})],1)],1)]):_vm._e(),(_vm.allow_renovation)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"is-flex is-align-content-flex-end"},[_c('b-field',{attrs:{"custom-class":"is-small-tooltip"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('section.renovation'))+" ")]),_c('b-radio-button',{attrs:{"native-value":false,"size":"is-medium","type":"is-primary"},on:{"click":function($event){return _vm.$v.structural.renovation_cost.$touch()}},model:{value:(_vm.structural.has_renovation),callback:function ($$v) {_vm.$set(_vm.structural, "has_renovation", $$v)},expression:"structural.has_renovation"}},[_c('span',[_vm._v(_vm._s(_vm.$t('no')))])]),_c('b-radio-button',{attrs:{"native-value":true,"size":"is-medium","type":"is-primary"},on:{"click":function($event){return _vm.$v.structural.renovation_cost.$touch()}},model:{value:(_vm.structural.has_renovation),callback:function ($$v) {_vm.$set(_vm.structural, "has_renovation", $$v)},expression:"structural.has_renovation"}},[_c('span',[_vm._v(_vm._s(_vm.$t('yes')))])])],2),(_vm.structural.has_renovation)?_c('b-field',{staticClass:"is-w100 ml-5",attrs:{"type":{
                        'is-danger':
                            !_vm.$v.structural.renovation_cost.decimal ||
                            !_vm.$v.structural.renovation_cost.required ||
                            (_vm.is_incoherent('renovation_cost') &&
                                !_vm.$v.structural.renovation_cost.$dirty) ||
                            _vm.renovation_cost_zero_invalid,
                    },"message":[
                        !_vm.$v.structural.renovation_cost.decimal
                            ? _vm.$t('number_only')
                            : null,

                        !_vm.$v.structural.renovation_cost.required
                            ? _vm.$t('field_required')
                            : null,
                        _vm.renovation_cost_zero_invalid
                            ? _vm.$t('renovation_cost_zero_invalid')
                            : null,
                        _vm.is_incoherent('renovation_cost') &&
                        !_vm.$v.structural.renovation_cost.$dirty
                            ? _vm.$t('incoherent_feature')
                            : null ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.renovation_cost'))+" ")]),_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.renovation_cost.$touch()}},model:{value:(_vm.structural.renovation_cost),callback:function ($$v) {_vm.$set(_vm.structural, "renovation_cost", $$v)},expression:"structural.renovation_cost"}})],2):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-6"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":_vm.prev_page}},[_vm._v(" "+_vm._s(_vm.$t('previous'))+" ")]),(
                        (_vm.isDraft || _vm.sending) &&
                            _vm.flow_type === 'avm_input_only' &&
                            _vm.$v.$anyDirty
                    )?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"content-save","disabled":_vm.form_any_error,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('save-draft')}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),((_vm.isDraft || _vm.sending) && _vm.flow_type === 'avm_input_only')?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"send","disabled":!_vm.form_complete,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" ")]):_vm._e(),(!_vm.isDraft && !_vm.sending && _vm.flow_type === 'avm_input_only')?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"view-dashboard","tag":"router-link","to":{
                        name: 'dashboard',
                        params: {
                            lang: _vm.$route.params.lang,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('dashboard'))+" ")]):_vm._e(),(_vm.flow_type === 'avm_demo')?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right","disabled":!_vm.form_complete,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")]):_vm._e()],1)])],1),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},model:{value:(_vm.isExitingModalActive),callback:function ($$v) {_vm.isExitingModalActive=$$v},expression:"isExitingModalActive"}},[(_vm.isExitingModalActive)?_c('div',{staticClass:"exit-modal"},[_c('h2',{staticClass:"title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('leave_modal.content')))]),_c('div',[(_vm.isExiting && _vm.cancelFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.cancelFn}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.cancel'))+" ")]):_vm._e(),(_vm.isExiting && _vm.proceedFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":function () {
                            _vm.submitForm('save-draft')
                            _vm.proceedFn()
                        }}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.save'))+" ")]):_vm._e(),(_vm.isExiting && _vm.proceedFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.proceedFn}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.confirm'))+" ")]):_vm._e()],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }