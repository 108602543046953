<i18n>
{
    "fr-BE":{
        "sidebar5": {
            "title1": "Notre prix-cible",
            "title2": "Que voyons-nous exactement sur le graphique?",
            "subtitle": "Compte tenu de l'emplacement et des caractéristiques spécifiés, nous arrivons à un prix cible de:",
            "text": "Avec les mêmes paramètres et dans le même quartier, plus de 50% des propriétés seront vendues dans cette intervalle.",
            "submit": "Voir en détail",
            "download": "Téléchargez le rapport",
            "new": "Recommencer"
        }
    },
    "nl-BE":{
        "sidebar5": {
            "title1": "Onze richtprijs",
            "title2": "Wat zien we juist op de grafiek?",
            "subtitle": "Gegeven de opgegeven locatie en kenmerken, komen we tot een richtprijs van:",
            "text": "Met gelijkaardige parameters in dezelfde buurt werden meer dan 50% van de panden in dit interval verkocht.",
            "submit": "Bekijk in detail",
            "download": "Download het rapport",
            "new": "Opnieuw beginnen"
        }
    },
    "en-BE":{
        "sidebar5": {
            "title1": "Our target price",
            "title2": "What exactly do we see on the graph?",
            "subtitle": "Given the specified location and characteristics, we arrive at a target price of:",
            "text": "With the same parameters and within the same neighborhood, more than 50% of the properties will be sold within the interval.",
            "submit": "View in detail",
            "download": "Download the report",
            "new": "Start over"
        }
    }
}
</i18n>

<template>
    <div v-if="getEstimate" id="sidebar5" class="sidebar-inner">
        <h1>{{ $t('sidebar5.title1') }}</h1>
        <div class="subtitle">
            {{ $t('sidebar5.subtitle') }}
        </div>
        <transition name="fade-opacity">
            <div class="price">
                {{ rounded_estimation }}
            </div>
        </transition>
        <transition name="fade-move-opacity-small">
            <div class="part2">
                <div class="separator"></div>
                <h2>{{ $t('sidebar5.title2') }}</h2>
                <div class="my-4">
                    <div class="legend-box">
                        <div class="dashed">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        Interval
                    </div>
                </div>
                <div class="subtitle">
                    {{ $t('sidebar5.text') }}
                </div>

                <div class="bottom-btns">
                    <transition name="fade-opacity">
                        <b-button
                            tag="router-link"
                            type="is-primary"
                            size="is-medium"
                            :to="{
                                name: 'request',
                                params: { action: 'start', lang: $route.params.lang },
                            }"
                        >
                            {{ $t('sidebar5.new') }}
                        </b-button>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Sidebar6',
    computed: {
        ...mapGetters('Address', ['addressInfo', 'getEstimate']),
        rounded_estimation() {
            let number =
                Math.round(
                    this.addressInfo.estimationResultsData.estimation.asking_price_q50 /
                        1000
                ) * 1000
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            }).format(number)
        },
    },
}
</script>

<style lang="scss" scoped>
.price {
    font-size: 28px;
    font-weight: 900;
    color: #ff6f00;
    transition: 0.4s all;
}
.dashed {
    display: flex;
    margin-right: 10px;
    div {
        height: 2px;
        width: 5px;
        background: #ff3100;
        margin-right: 3px;
        margin-top: 10px;
    }
}
</style>
