/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 30,
    height: 26,
    viewBox: '0 0 30 26',
    data: '<path pid="0" d="M12 26v-9.177h6V26h7.5V13.765H30L15 0 0 13.765h4.5V26H12z" _fill="#798DA6"/>'
  }
})
