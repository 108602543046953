/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 20,
    height: 14,
    viewBox: '0 0 20 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.808 6.121a1 1 0 000 1.415l5.657 5.656a1 1 0 001.414 0L19.192 1.88A1 1 0 0017.778.464L7.172 11.071l-4.95-4.95a1 1 0 00-1.414 0z" _fill="#52D860"/>'
  }
})
