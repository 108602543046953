var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container section is-fluid"},[_c('title-with-tooltip',{attrs:{"label":_vm.$t('tooltip_text')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]},proxy:true}])}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('building-type-select',{ref:"parent",staticClass:"pb-4",attrs:{"building-type":_vm.buildingType,"value":_vm.buildingType},on:{"update:buildingType":function($event){_vm.buildingType=$event},"update:building-type":function($event){_vm.buildingType=$event}}}),_c('div',{staticClass:"is-flex is-justify-content-space-between pt-5 is-btn-box"},[_c('b-button',{attrs:{"icon-left":"arrow-left","type":"is-dark","tag":"router-link","size":"is-medium","to":_vm.prevLink}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),(_vm.buildingType != null)?_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-primary","icon-right":"arrow-right","to":{
                        name: 'request',
                        params: {
                            lang: _vm.$route.params.lang,
                            action: 'address',
                        },
                        query: _vm.$route.query,
                    }}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))])]):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }