import URI from 'urijs'

const utils = {}
utils.epc_mapping = {
    Wallonia: {
        'A++': -20,
        'A+': 22.5,
        A: 65,
        B: (86 + 170) / 2,
        C: (170 + 255) / 2,
        D: (255 + 340) / 2,
        E: (340 + 425) / 2,
        F: (425 + 510) / 2,
        G: 550,
    },
    Flanders: {
        'A+': -20,
        A: 50,
        B: 150,
        C: 250,
        D: 350,
        E: 450,
        F: 550,
    },
    Brussels: {
        A: 22.5,
        B: (25 + 95) / 2,
        C: (95 + 150) / 2,
        D: (150 + 210) / 2,
        E: (210 + 275) / 2,
        F: (275 + 345) / 2,
        G: 450,
    },
}

utils.value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 3,
}).format

utils.quantile_formatter = (value) => {
    if (value > 0.5) {
        return `top ${Math.ceil((1 - value) * 20) * 5}%`
    } else {
        return `bottom ${Math.ceil(value * 20) * 5}%`
    }
}

utils.isEmptyStr = str => {
    return !str.replace(/\s/g, '').length
}

utils.urlJoin = (baseUrl, paths) => {
    var fullPath = "";

    if (!(baseUrl.startsWith('/') || baseUrl.startsWith('http'))){
        fullPath += "/"
    }

    if (baseUrl.endsWith('/')) {
        fullPath += baseUrl.slice(0, -1)
    } else {
        fullPath += baseUrl
    }
    
    if (!Array.isArray(paths)) {
        if (!(paths.startsWith('/'))) {
            fullPath += '/'
        }
        
        fullPath += paths

        try {
            var returnURI = new URI(fullPath)

            return returnURI.toString();
        } catch(e) {
            throw new Error("Invalid parameters for urlJoin function.");
        }
    } else {
        if (!paths[0].startsWith('/')) {
            fullPath += '/'
        }

        fullPath += paths[0]

        try {
            var returnURI = new URI(fullPath)

            if (paths.length === 1) {
                return returnURI.toString();
            }
        } catch(e) {
            throw new Error("Invalid parameters for urlJoin function.");
        }

        var remainingPaths = [...paths];
        remainingPaths.shift();

        return utils.urlJoin(returnURI.toString(), remainingPaths)
    }
}

utils.parseJwt = token => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

utils.getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
    return undefined;
}

utils.deleteCookie = name => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

export default utils
