<i18n>
{
    "en-BE": {
        "actions": "Actions",
        "address": "Address",
        "automatic_valuation": "Automatic valuation",
        "cancel": "Cancel",
        "customer_ref": "Customer ref",
        "edit": "Edit",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Number of facades",
            "f_construction_year": "Construction year",
            "f_living_area": "Living area",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "More information",
        "last_modified": "Last modified",
        "created_at": "Created",
        "missing_features": "The following features are missing, preventing an automatic valuation:",
        "new_request": "New Request",
        "ok": "OK",
        "per_page": "per page",
        "request_ref": "Request ref",
        "search": "Search",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submitted": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued"
        },
        "tooltip_text": "Follow here the status of your valuation requests",
        "review_tooltip": "This request has been analyzed by a valuation specialist and some answers need to be reviewed",
        "view_request": "View request",
        "valuer": "Valuer",
        "unassigned": "Unassigned",
        "valuer_actions": {
            "take": "Take",
            "steal": "Steal",
            "release": "Release"
        },
        "ing": {
            "customer_ref": "LOP ID"
        }
    },
    "fr-BE": {
        "actions": "Actions",
        "address": "Adresse",
        "automatic_valuation": "Valorisation automatique",
        "cancel": "Annuler",
        "customer_ref": "Référence interne",
        "edit": "Modifier",
        "features": {
            "f_epc": "PEB",
            "f_touching_sides": "Nombre de façades",
            "f_construction_year": "Année de construction",
            "f_living_area": "Surface habitable",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Plus d'information",
        "last_modified": "Dernière modification",
        "created_at": "Créé",
        "missing_features": "Les données suivantes sont manquantes, ce qui empêche une valorisation automatique:",
        "new_request": "Nouvelle demande",
        "ok": "OK",
        "per_page": "par page",
        "request_ref": "Référence de demande",
        "search": "rechercher",
        "status_col": "Statut",
        "status": {
            "awaiting-onsite": "Expertise sur place nécessaire",
            "under-dvm-valuation": "Envoyé",
            "draft": "À compléter",
            "submitted": "Envoyé",
            "exotic": "Expertise sur place nécessaire",
            "needs-review": "Plus d'information nécessaire",
            "cancelled": "Annulé",
            "valued": "Evalué"
        },
        "tooltip_text": "Suivez ici l'évolution de vos demandes",
        "review_tooltip": "Cette demande a été analysée par un expert immobilier et certaines réponses doivent être révisées",
        "view_request": "Voir la demande",
        "valuer": "Expert immobilier",
        "unassigned": "Non assignée",
        "valuer_actions": {
            "take": "Take",
            "steal": "Steal",
            "release": "Release"
        },
        "ing": {
            "customer_ref": "LOP ID"
        }
    },
    "nl-BE": {
        "actions": "Acties",
        "address": "Adres",
        "automatic_valuation": "Automatische waardering",
        "cancel": "Annuleren",
        "customer_ref": "Interne referentie",
        "edit": "Bewerken",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Aantal gevels",
            "f_construction_year": "Bouwjaar",
            "f_living_area": "Bewoonbare oppervlakte",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Meer informatie",
        "last_modified": "Laatst gewijzigd",
        "created_at": "Gecreëerd",
        "missing_features": "De volgende functies ontbreken, waardoor automatische waardering niet mogelijk is:",
        "new_request": "Nieuw aanvraag",
        "ok": "OK",
        "per_page": "per pagina",
        "request_ref": "Aanvraag referentie",
        "search": "Zoeken",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "On-site expertise vereist",
            "under-dvm-valuation": "Ingediend",
            "draft": "Ontwerp",
            "submitted": "Ingediend",
            "exotic": "On-site expertise vereist",
            "needs-review": "Meer informatie nodig",
            "cancelled": "Geannuleerd",
            "valued": "Gewaardeerd"
        },
        "tooltip_text": "Volg hier de status van uw aanvraagen",
        "review_tooltip": "Deze aanvraag is door een vastgoeddeskundige geanalyseerd en sommige antwoorden moeten worden herzien",
        "view_request": "Aanvraag bekijken",
        "valuer": "Vastgoeddeskundige",
        "unassigned": "Niet toegewezen",
        "valuer_actions": {
            "take": "Take",
            "steal": "Steal",
            "release": "Release"
        },
        "ing": {
            "customer_ref": "LOP ID"
        }
    }
}
</i18n>

<template>
    <div class="container section is-fluid">
        <title-with-tooltip :label="t('tooltip_text')">
            <template #title>
                <span class="is-capitalized">Dashboard</span>
            </template>
        </title-with-tooltip>
        <b-button
            class="mt-1"
            size="is-medium"
            type="is-primary"
            icon-left="plus"
            tag="router-link"
            :to="{
                name: 'request',
                params: {
                    action: 'start',
                    lang: $route.params.lang,
                },
            }"
        >
            <span>{{ t('new_request') }}</span>
        </b-button>
        <div class="columns is-multiline mt-4">
            <div class="column is-12">
                <!-- input section -->
                <div class="is-flex is-justify-content-space-between is-w100">
                    <div class="searchbox">
                        <!-- search input -->
                        <b-field position="is-left">
                            <b-input
                                v-model="searchQuery"
                                size="is-small"
                                :placeholder="t('search')"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- per-page select -->
                    <b-field position="is-right">
                        <b-select v-model="perPage" size="is-small">
                            <option v-for="item in perPageList" :key="item" :value="item">
                                {{ item }} {{ t('per_page') }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <!-- input section -->
            </div>
            <div class="column is-12">
                <!-- table section -->
                <b-table
                    :data="tableData"
                    hoverable
                    mobile-cards
                    paginated
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isLoading"
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                >
                    <!-- lop_id column -->
                    <b-table-column
                        v-slot="props"
                        field="customer_ref"
                        :label="t('customer_ref')"
                        width="40"
                        numeric
                        sortable
                        centered
                    >
                        {{ props.row.customer_ref }}
                    </b-table-column>
                    <!-- lop_id column -->

                    <!-- valuation_request_ref column -->
                    <b-table-column
                        v-slot="props"
                        field="valuation_request_ref"
                        :label="t('request_ref')"
                        centered
                    >
                        {{ props.row.valuation_request_ref }}
                    </b-table-column>
                    <!-- valuation_request_ref column -->

                    <!-- address column -->
                    <b-table-column
                        v-slot="props"
                        centered
                        field="address"
                        :label="t('address')"
                    >
                        {{ props.row.address }}
                    </b-table-column>
                    <!-- address column -->

                    <!-- status column -->
                    <b-table-column
                        v-slot="props"
                        centered
                        field="status"
                        sortable
                        :label="t('status_col')"
                    >
                        <span
                            :class="
                                !!(
                                    props.row.last_dvm_action_at &&
                                    props.row.status === 'draft'
                                )
                                    ? 'has-text-weight-bold has-text-danger'
                                    : ''
                            "
                        >
                            {{ t(`status.${props.row.status}`) }}
                        </span>
                        <b-tooltip
                            v-if="
                                props.row.last_dvm_action_at &&
                                    props.row.status === 'draft'
                            "
                            type="is-danger"
                            :label="$t('review_tooltip')"
                            multilined
                        >
                            <div
                                class="is-rounded is-tootip-btn has-text-weight-bold has-text-white has-background-danger"
                            >
                                !
                            </div>
                        </b-tooltip>
                    </b-table-column>
                    <!-- status column -->

                    <b-table-column
                        v-slot="props"
                        field="created_at"
                        :label="t('created_at')"
                        sortable
                        centered
                    >
                        {{ date_format(props.row.created_at) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="modified_at"
                        :label="t('last_modified')"
                        sortable
                        centered
                    >
                        {{ date_format(props.row.modified_at) }}
                    </b-table-column>
                    <!-- date column -->

                    <!-- action column -->
                    <b-table-column
                        v-slot="props"
                        field="action"
                        :label="t('actions')"
                        centered
                    >
                        <div class="buttons is-centered">
                            <!--<b-button
                                v-if="props.row.status === 'needs-review'"
                                icon-left="help-circle"
                                size="is-small"
                                @click="view_details(props.row)"
                            >
                                {{ t('info') }}
                            </b-button>-->
                            <b-button
                                v-if="props.row.status === 'draft'"
                                type="is-warning"
                                icon-left="pencil"
                                size="is-small"
                                tag="router-link"
                                :to="{
                                    name: 'request',
                                    params: {
                                        action: 'extra-info',
                                        lang: $route.params.lang,
                                    },
                                    query: {
                                        valuation_request_ref:
                                            props.row.valuation_request_ref,
                                    },
                                }"
                            >
                                {{ t('edit') }}
                            </b-button>
                            <b-button
                                v-if="
                                    props.row.status === 'submitted' &&
                                        now - props.row.modified_at < 1000 * 60 * 60
                                "
                                type="is-warning"
                                icon-left="pencil"
                                size="is-small"
                                @click="unsubmit(props.row)"
                            >
                                {{ t('edit') }}
                            </b-button>
                            <b-button
                                v-if="
                                    props.row.status === 'draft' ||
                                        (props.row.status === 'submitted' &&
                                            now - props.row.modified_at < 1000 * 60 * 60)
                                "
                                type="is-danger"
                                icon-left="cancel"
                                size="is-small"
                                @click="cancel(props.row)"
                            >
                                {{ t('cancel') }}
                            </b-button>
                            <b-button
                                v-if="
                                    props.row.status === 'submitted' ||
                                        props.row.status === 'needs-review' ||
                                        props.row.status === 'valued' ||
                                        props.row.status === 'exotic'
                                "
                                type="is-primary"
                                icon-left="eye"
                                size="is-small"
                                tag="router-link"
                                :to="{
                                    name: 'request',
                                    params: {
                                        action: 'extra-info',
                                        lang: $route.params.lang,
                                    },
                                    query: {
                                        valuation_request_ref:
                                            props.row.valuation_request_ref,
                                    },
                                }"
                            >
                                {{ t('view_request') }}
                            </b-button>
                            <!--<b-button
                                v-if="
                                    props.row.status === 'submitted' ||
                                        props.row.status === 'valued'
                                "
                                type="is-success"
                                icon-left="eye"
                                size="is-small"
                                tag="router-link"
                                :to="{
                                    name: 'avm',
                                    params: {
                                        lang: $route.params.lang,
                                        step: 1,
                                    },
                                    query: {
                                        valuation_request_ref:
                                            props.row.valuation_request_ref,
                                    },
                                }"
                            >
                                {{ t('automatic_valuation') }}
                            </b-button>-->
                            <b-button
                                v-if="
                                    (props.row.status === 'valued' &&
                                        visible_reports.includes('dvm_web')) ||
                                        (props.row.status === 'submitted' &&
                                            visible_reports.includes('avm_web'))
                                "
                                type="is-success"
                                icon-left="file-document-outline"
                                size="is-small"
                                tag="router-link"
                                :to="{
                                    name: 'report',
                                    params: {
                                        lang: $route.params.lang,
                                    },
                                    query: {
                                        valuation_request_ref:
                                            props.row.valuation_request_ref,
                                        static: 'true',
                                    },
                                }"
                            >
                                Web report
                            </b-button>
                            <b-button
                                v-if="
                                    (props.row.status === 'valued' &&
                                        visible_reports.includes('dvm_pdf')) ||
                                        (props.row.status === 'submitted' &&
                                            visible_reports.includes('avm_pdf'))
                                "
                                type="is-success"
                                icon-left="download"
                                size="is-small"
                                tag="a"
                                :disabled="props.row.disabled"
                                :href="report_url(props.row.valuation_request_ref)"
                                @click="disable_button(props.row.valuation_request_ref)"
                            >
                                {{ props.row.disabled ? 'Downloading...' : 'PDF report' }}
                            </b-button>
                        </div>
                    </b-table-column>
                    <!-- action column -->

                    <!-- valuer attribution column -->
                    <b-table-column
                        v-if="$store.state.auth.roles.includes('valuer')"
                        v-slot="props"
                        field="valuer"
                        :label="t('valuer')"
                        sortable
                        centered
                    >
                        <div class="level top">
                            <span class="level-left">
                                {{
                                    props.row.valuer.assigned
                                        ? props.row.valuer.email
                                        : t('unassigned')
                                }}
                            </span>
                            <b-button
                                v-if="
                                    !props.row.valuer.assigned &&
                                        props.row.status === 'submitted'
                                "
                                type="is-success"
                                size="is-small"
                                :disabled="props.row.disabled"
                                class="level-right"
                                @click="take_request(props.row)"
                            >
                                {{ t('valuer_actions.take') }}
                            </b-button>
                            <b-button
                                v-if="
                                    props.row.valuer.assigned &&
                                        !props.row.valuer.is_self &&
                                        props.row.status === 'submitted'
                                "
                                type="is-primary"
                                size="is-small"
                                :disabled="props.row.disabled"
                                class="level-right"
                                @click="take_request(props.row, true)"
                            >
                                {{ t('valuer_actions.steal') }}
                            </b-button>
                            <b-button
                                v-if="
                                    props.row.valuer.assigned &&
                                        props.row.valuer.is_self &&
                                        props.row.status === 'submitted'
                                "
                                type="is-danger"
                                size="is-small"
                                :disabled="props.row.disabled"
                                class="level-right"
                                @click="release_request(props.row)"
                            >
                                {{ t('valuer_actions.release') }}
                            </b-button>
                        </div>
                    </b-table-column>
                    <!-- valuer attribution column -->
                </b-table>
            </div>
        </div>
        <!--<b-modal
            v-model="modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="modal_open" class="card confirm">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ t('info_title') }}</p>
                    </header>
                    <b-message type="is-primary" has-icon class="mb-0" size="is-medium">
                        <span class="is-modal-message">
                            {{ t('missing_features') }}

                            <ul>
                                <li
                                    v-for="(error,
                                    index) in row_data.avm_transaction.body.errors.filter(
                                        (error) => error.message === 'missing_parameter'
                                    )"
                                    :key="index"
                                >
                                    {{ t(`features.${error.parameter}`) }}
                                </li>
                            </ul>
                        </span>
                    </b-message>
                    <footer class="modal-card-foot">
                        <b-button
                            :label="t('ok')"
                            type="is-primary"
                            icon-left="close"
                            expanded
                            @click="modal_open = false"
                        />
                    </footer>
                </div>
            </div>
        </b-modal>-->
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios.js'
import utils from '@/shared/plugins/utils'
import { mapActions, mapGetters } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})

export default {
    name: 'Overview',
    mixins: [localeMixin],
    data() {
        return {
            data: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            perPageList: [5, 10, 25, 50],
            isLoading: true,
            result: [],
            //modal_open: false,
            row_data: {},
            disabled_downloads: [],
            now: Date.now(),
        }
    },
    computed: {
        ...mapGetters(['flow_type']),
        // return filtered text
        tableData() {
            let lowSearch = this.searchQuery.toLowerCase()
            let date_threshold = new Date(2021, 4, 26, 23, 0, 0, 0)
            return this.data
                .map((row) => {
                    return {
                        ...row,
                        disabled: this.disabled_downloads.includes(
                            row.valuation_request_ref
                        ),
                    }
                })
                .filter((item) => {
                    return (
                        !['cancelled', 'predraft'].includes(item.status) &&
                        item.modified_at >= date_threshold &&
                        (lowSearch === '' ||
                            Object.values(item).some((val) =>
                                String(val)
                                    .toLowerCase()
                                    .includes(lowSearch)
                            ))
                    )
                })
        },
        visible_reports() {
            if (
                ['rock.estate', 'rockestate.be'].includes(
                    this.$store.state.auth.organization
                )
            ) {
                return ['avm_pdf', 'dvm_pdf', 'avm_web', 'dvm_web']
            } else {
                return process.env.VUE_APP_REPORT_VISIBLE.split(',')
            }
        },
    },
    watch: {
        // fire search function on input
        // we can add debounce or length condition until search fires
        searchQuery(val) {
            this.search(val)
        },
    },
    // load data
    mounted() {
        let url = utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'requests')
        if (typeof this.$route.query.valuation_request_refs !== 'undefined') {
            url += `?valuation_request_refs=${this.$route.query.valuation_request_refs}`
        }
        axios.get(url).then((result) => {
            const data = result.data
            let index = 0
            data.forEach((el, __) => {
                try {
                    this.$set(this.data, index, {
                        customer_ref: el.customer_ref,
                        valuation_request_ref: el.valuation_request_ref,
                        address: el.full_address,
                        status: el.status,
                        modified_at: new Date(el.modified_at),
                        created_at: new Date(el.created_at),
                        last_dvm_action_at: el.last_dvm_action_at
                            ? new Date(el.last_dvm_action_at)
                            : null,
                        valuer: el.valuer,
                    })
                } catch (error) {
                    console.error({ error, el })
                }
                index += 1
            })
            this.isLoading = false
        })
        var self = this
        setInterval(function() {
            self.now = Date.now()
        }, 1000)
    },
    methods: {
        ...mapActions(['updateStore']),
        // search with multiple query
        search(search) {},
        date_format: date_formatter.format,
        report_url(valuation_request_ref) {
            return (
                `${utils.urlJoin(process.env.VUE_APP_API_URL, [
                    process.env.VUE_APP_VALUATION_API_URL,
                    '/report',
                ])}?` +
                `valuation_request_ref=${valuation_request_ref}` +
                `&lang=${this.$route.params.lang}`
            )
        },
        button_enabled(valuation_request_ref) {
            return this.disabled_downloads.contains(valuation_request_ref)
        },
        disable_button(valuation_request_ref) {
            this.disabled_downloads.push(valuation_request_ref)
        },
        cancel(row) {
            let store = {
                data: {},
                customer_ref: row.customer_ref,
                valuation_request_ref: row.valuation_request_ref,
                locked: true,
            }
            return axios
                .post(
                    `${utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'state')}?` +
                        `valuation_request_ref=${row.valuation_request_ref}` +
                        `&lang=${this.$route.params.lang}` +
                        '&action=cancel',
                    store
                )
                .then((response) => {
                    this.$set(row, 'status', 'cancelled')
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        unsubmit(row) {
            this.updateStore({
                data: {},
                lang: this.$i18n.locale,
                action: 'unsubmit',
                customer_ref: row.customer_ref,
                valuation_request_ref: row.valuation_request_ref,
                update_from_response: false,
            }).then((response) => this.edit_draft(row))
        },
        edit_draft(row) {
            this.$router.push({
                name: 'request',
                params: {
                    action: 'extra-info',
                    lang: this.$route.params.lang,
                },
                query: {
                    valuation_request_ref: row.valuation_request_ref,
                },
            })
        },
        take_request(row, steal = false) {
            return axios
                .post(
                    `${utils.urlJoin(
                        process.env.VUE_APP_VALUATION_API_URL,
                        'set_valuer'
                    )}?` +
                        `valuation_request_ref=${row.valuation_request_ref}${
                            steal ? '&steal=true' : ''
                        }`
                )
                .then((response) => {
                    this.$set(row, 'valuer', response.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        release_request(row) {
            return axios
                .post(
                    `${utils.urlJoin(
                        process.env.VUE_APP_VALUATION_API_URL,
                        'set_valuer'
                    )}?` +
                        `valuation_request_ref=${row.valuation_request_ref}` +
                        '&release=true'
                )
                .then((response) => {
                    this.$set(row, 'valuer', response.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        /*view_details(row) {
            return axios
                .get(
                    `${utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'state')}?` +
                        `valuation_request_ref=${row.valuation_request_ref}` +
                        `&lang=${this.$route.params.lang}`
                )
                .then((response) => {
                    this.row_data = response.data.data
                    this.modal_open = true
                })
                .catch((error) => {
                    console.error(error)
                })
        },*/
    },
}
</script>

<style lang="scss">
.table-wrapper {
    min-height: 300px;
    position: relative;
}
.searchbox {
    min-width: 300px;
}
.table > tbody > tr > td {
    vertical-align: middle !important;
}
</style>
