export const config = [
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address_text',
        name: 'full_address',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'date',
        unit: '',
        location: 'valuation',
        name: 'date',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'type',
        icon: '',
        fallback: '',
        values: ['avm', 'dvm', 'ovm'],
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'references',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'valuation_request_ref',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'customer_ref',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reconstruction_value',
        icon: 'ic-reconstruction',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'address_location',
        icon: 'ic-location',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'secondary_buildings',
        icon: 'ic-cube',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'economic_activity',
        icon: 'ic-economic',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'roof',
        icon: 'ic-roof',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'warnings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'bank',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'valuation',
        name: 'valuer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'valuation',
        name: 'monitor',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'customer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'mortgage',
        name: 'purpose',
        icon: '',
        fallback: '',
        values: ['new_loan', 'revaluation'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'request',
        name: 'package',
        icon: '',
        fallback: '',
        values: ['classic', 'renovation_light', 'renovation_full', 'new_construction'],
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'renovation',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: [],
        building_type_scope: [],
        functionality: 'feature',
        type: 'float',
        unit: '',
        location: 'features',
        name: 'renovation_cost',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'general_info',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_building_type',
        icon: '',
        fallback: '',
        values: ['house', 'apartment'],
        sections: ['general_info', 'building', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_number_of_facades',
        icon: '',
        fallback: '',
        values: ['attached', 'semi', 'detached'],
        sections: ['general_info', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_parcel_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels_and_buildings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_parcels',
        icon: 'ic-environment',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_building',
        icon: 'ic-building',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_building_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_annexes',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_annexes_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_area_largest_annex',
        icon: '',
        fallback: '',
        values: null,
        sections: ['secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'custom_parcel',
        unit: '',
        location: 'features',
        name: 'parcel_ids',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lng',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lat',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_number_of_addresses',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_living_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'style_of_house',
        icon: '',
        fallback: '',
        values: ['traditional', 'modern', 'contemporary', 'mediterranean', 'other'],
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_construction_year',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric or string',
        unit: '',
        location: 'features',
        name: 'f_epc',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_epc_label',
        icon: '',
        fallback: '',
        values: ['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'],
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_epc_numeric',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_bedrooms',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_floor',
        icon: '',
        fallback: '',
        values: ['ground_floor', '1', '2', '3', '4', '>4'],
        sections: ['building'],
    },
    {
        report_scope: [],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_bottom_floor',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: [],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_top_floor',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'array_of_int',
        unit: '',
        location: 'features',
        name: 'f_floors',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_balcony_present',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garage_present',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_closed_garage',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parking_spot',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_cellar_attic',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_garden_access',
        icon: '',
        fallback: '',
        values: ['none', '\nprivate', 'common', 'private+common'],
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_common',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_private',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_structure',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm3',
        location: 'features',
        name: 'f_volume',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'exterior_state',
        icon: '',
        fallback: '',
        values: ['maintenance_required', 'good', 'very_good'],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'facade_material',
        icon: '',
        fallback: '',
        values: [
            'cinder_blocks',
            'wood',
            'plaster',
            'natural_stone',
            'metal',
            'concrete',
            'brick',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_roof_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_mean_tilt',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'percent',
        location: 'features',
        name: 'f_percent_of_roof_flat',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_min_height',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_max_height',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parts',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_roof_panes',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'roof_material',
        icon: '',
        fallback: '',
        values: [
            'tiles',
            'bitumen_roofing',
            'metal',
            'slate_shingles',
            'black_concrete_tiles',
            'thatched',
            'asbestos',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_windows',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_dormers',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'dvm_features',
        name: 'solar_panel_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_purpose_classification',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_building_listed',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_building_protected',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: '',
        name: 'clarify here',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_matched_entities',
        icon: '',
        fallback: '',
        values: null,
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_class',
        icon: '',
        fallback: '',
        values: [
            'home_liberal_profession_or_office',
            'home_other_professional_activity',
            'home',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_level',
        icon: '',
        fallback: '',
        values: [
            'no_economic_activity',
            'activity_unlikely',
            'activity_possible',
            'activity_likely',
            'activity_very_likely',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_garden_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_orientation',
        icon: '',
        fallback: '',
        values: ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_arrangement',
        icon: '',
        fallback: '',
        values: ['limited', 'good', 'very_good'],
        sections: ['parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_swimming_pool',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'dvm_features',
        name: 'f_swimming_pool_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'fixed',
        unit: '',
        location: 'fixed',
        name: 'parcels_and_building_shapes',
        icon: '',
        fallback: '',
        values: ['details_previous_page'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'surroundings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_distance_to_street',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'direct_traffic_level',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'indirect_traffic_level',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood_insurance_exclusion',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'unknown', 'none'],
        sections: ['surroundings', 'flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_insurance_exclusion',
        icon: '',
        fallback: '',
        values: ['high', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_composite',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'surroundings',
        icon: '',
        fallback: '',
        values: ['city', 'suburbs', 'village', 'isolated'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_positive',
        icon: '',
        fallback: '',
        values: [
            'classified_facade',
            'green_neighbourhood',
            'sea_view',
            'no_direct_traffic',
            'exceptional_view',
        ],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_negative',
        icon: '',
        fallback: '',
        values: ['tram', 'train', 'metro', 'traffic', 'busy_area'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multiline-text',
        unit: '',
        location: 'dvm_features',
        name: 'remarks',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multiline-text',
        unit: '',
        location: 'dvm_features',
        name: 'internal_remarks',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'valuations',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'market_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'forced_sale_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro/month',
        location: 'valuation',
        name: 'rental_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'reconstruction_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: '',
        location: 'valuation',
        name: 'index_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'index_type',
        icon: '',
        fallback: '',
        values: ['abex'],
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'level',
        icon: '',
        fallback: '',
        values: null,
        sections: ['reconstruction_value'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_location',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_characteristics',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'flood',
        icon: 'ic-flood',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'flood_simple',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_ground_height_above_sea',
        icon: '',
        fallback: '',
        values: null,
        sections: ['flood', 'flood_simple'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'faq',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'questions_feedback',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'contact_info',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'disclaimer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
]
export const translations = {
    'fr-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2an',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/mois',
            'euro/month_tooltip': '',
        },
        address_text: {
            full_address: 'Adresse',
            full_address_tooltip:
                "S\u00e9lectionnez l'adresse du bien financ\u00e9. Merci de ne pas confondre ceci avec l'adresse d'autres bien \u00e9ventuels qui peuvent servir comme s\u00fbret\u00e9.",
        },
        valuation: {
            date: 'Date de la valorisation',
            date_tooltip: '',
            type: 'Type de valorisation',
            type_tooltip: '',
            valuer: 'R\u00e9alis\u00e9 par',
            valuer_tooltip: '',
            monitor: 'Contr\u00f4l\u00e9 par',
            monitor_tooltip: '',
            market_value: 'Valeur de march\u00e9',
            market_value_tooltip: '',
            forced_sale_value: 'Valeur de vente forc\u00e9e',
            forced_sale_value_tooltip: '',
            rental_value: 'Valeur locative mensuelle',
            rental_value_tooltip: '',
            reconstruction_value: 'Valeur de reconstruction',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: "Type d'index",
            index_type_tooltip: '',
            explanation_1:
                "La valorisation de ce bien repose en partie sur un mod\u00e8le statistique fond\u00e9 sur des donn\u00e9es. Son r\u00e9sultat a \u00e9t\u00e9 revu et affin\u00e9 par un expert immobilier. Il s'agit d'un processus en deux \u00e9tapes :",
            explanation_1_tooltip: '',
            explanation_2: '\u00c9tape 1 : Estimation par le mod\u00e8le de valorisation',
            explanation_2_tooltip: '',
            explanation_3:
                'Une premi\u00e8re estimation de la valeur de march\u00e9 de la propri\u00e9t\u00e9 est calcul\u00e9e par le mod\u00e8le sur la base de toutes les informations fournies par le client et de certains param\u00e8tres automatiques (cf. page 2). Cette valorsiation repr\u00e9sente la valeurde march\u00e9 moyenne pour une propri\u00e9t\u00e9 ayant cette localisation et ces caract\u00e9ristiques.',
            explanation_3_tooltip: '',
            explanation_4:
                '\u00c9tape 2 : Evaluation et mise au point par un expert immobilier',
            explanation_4_tooltip: '',
            explanation_5:
                "L'expert immobilier \u00e9value la valorisation du mod\u00e8le utilis\u00e9e en \u00c9tape 1 et prend ensuite en compte les autres param\u00e8tres entr\u00e9s automatiquement, ainsi que les param\u00e8tres entr\u00e9s par l'expert. Il en r\u00e9sulte une valuer de march\u00e9 du bien (ligne rouge dans le graphique). En fonction des corrections appliqu\u00e9es par l'expert immobilier, la valeur de march\u00e9 peut \u00eatre inf\u00e9rieure ou sup\u00e9rieure \u00e0 l'estimation initiale du mod\u00e8le de valorisation.\n\nLe graphique ci-dessous illustre comment la valeur de march\u00e9 de cette propri\u00e9t\u00e9 se compare \u00e0 d'autres propri\u00e9t\u00e9s dans le voisinage. Il permet toujours de contextualiser la valorisation r\u00e9alis\u00e9e par l'expert.",
            explanation_5_tooltip: '',
            explanation_6:
                "La valeur de march\u00e9 indiqu\u00e9e dans ce rapport est bas\u00e9e sur une valorisation \u00e0 distance r\u00e9alis\u00e9e par un expert. Elle vise \u00e0 donner la meilleure vue possible sur la valeur actuelle du bien. Cependant, elle reste une estimation o\u00f9 les \u00e9l\u00e9ments suivants doivent \u00eatre pris en compte :\n- Valorisation \u00e0 distance: l'expert a \u00e9valu\u00e9 le bien \u00e0 distance et n'a pas r\u00e9ellement visit\u00e9 le bien.\n- Pr\u00e9cision du mod\u00e8le: la valorisation repose, en partie, sur un mod\u00e8le statistique avec une certaine marge d'erreur.\n- Degr\u00e9 de subjectivit\u00e9: m\u00eame la meilleure valorisation par un expert est quelque peu biais\u00e9e par une forme de subjectivit\u00e9.",
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Valorisation automatique',
            avm_tooltip: '',
            dvm: 'Valorisation \u00e0 distance',
            dvm_tooltip: '',
            ovm: 'Valorisation sur place',
            ovm_tooltip: '',
            new_loan: 'Demande d\u2019un pr\u00eat immobilier',
            new_loan_tooltip: '',
            revaluation: 'Revalorisation',
            revaluation_tooltip: '',
            classic: '',
            classic_tooltip: '',
            renovation_light: '',
            renovation_light_tooltip: '',
            renovation_full: '',
            renovation_full_tooltip: '',
            new_construction: '',
            new_construction_tooltip: '',
            house: 'Maison',
            house_tooltip: '',
            apartment: 'Appartement',
            apartment_tooltip: '',
            annex: 'Annexe',
            annex_tooltip: '',
            attached: '2 fa\u00e7ades',
            attached_tooltip: '',
            semi: '3 fa\u00e7ades',
            semi_tooltip: '',
            detached: '4 fa\u00e7ades',
            detached_tooltip: '',
            yes: 'Oui',
            yes_tooltip: '',
            no: 'Non',
            no_tooltip: '',
            traditional: 'Traditionel',
            traditional_tooltip: '',
            modern: 'Moderne',
            modern_tooltip: '',
            contemporary: 'Contemporain',
            contemporary_tooltip: '',
            mediterranean: 'M\u00e9diterran\u00e9en',
            mediterranean_tooltip: '',
            other: 'Autres',
            other_tooltip: '',
            ground_floor: 'Rez-de-chauss\u00e9e',
            ground_floor_tooltip: '',
            '1': '1',
            '1_tooltip': '',
            '2': '2',
            '2_tooltip': '',
            '3': '3',
            '3_tooltip': '',
            '4': '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Jardin priv\u00e9',
            private_tooltip: '',
            common: 'Jardin commun',
            common_tooltip: '',
            'private+common': 'Jardin priv\u00e9 et commun',
            'private+common_tooltip': '',
            maintenance_required: "Besoin d'entretien",
            maintenance_required_tooltip: '',
            good: 'Bon',
            good_tooltip: '',
            very_good: 'Tr\u00e8s bon',
            very_good_tooltip: '',
            cinder_blocks: 'Parpaings',
            cinder_blocks_tooltip: '',
            wood: 'Bois',
            wood_tooltip: '',
            plaster: 'Cr\u00e9pi',
            plaster_tooltip: '',
            natural_stone: 'Pierres naturelles',
            natural_stone_tooltip: '',
            metal: 'M\u00e9tal',
            metal_tooltip: '',
            concrete: 'B\u00e9ton',
            concrete_tooltip: '',
            brick: 'Briques',
            brick_tooltip: '',
            tiles: 'Tuiles',
            tiles_tooltip: '',
            bitumen_roofing: 'Bit\u00fbme',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Bardeaux en ardoise',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Tuiles noires en b\u00e9ton',
            black_concrete_tiles_tooltip: '',
            thatched: 'Chaume',
            thatched_tooltip: '',
            asbestos: 'Amiante',
            asbestos_tooltip: '',
            home_liberal_profession_or_office:
                'Maison, profession lib\u00e9rale ou bureau',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Autre activit\u00e9 professionelle',
            home_other_professional_activity_tooltip: '',
            home: 'Maison',
            home_tooltip: '',
            no_economic_activity: "Pas d'activit\u00e9 \u00e9conomique",
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activit\u00e9 improbable',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activit\u00e9 possible',
            activity_possible_tooltip: '',
            activity_likely: 'Activit\u00e9 probable',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activit\u00e9 tr\u00e8s probable',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NE',
            ne_tooltip: '',
            e: 'E',
            e_tooltip: '',
            se: 'SE',
            se_tooltip: '',
            s: 'S',
            s_tooltip: '',
            sw: 'SO',
            sw_tooltip: '',
            w: 'O',
            w_tooltip: '',
            nw: 'NO',
            nw_tooltip: '',
            limited: 'Limit\u00e9',
            limited_tooltip: '',
            details_previous_page: '(d\u00e9tails sur la page pr\u00e9c\u00e9dente)',
            details_previous_page_tooltip: '',
            low: 'Faible',
            low_tooltip: '',
            medium: 'Mod\u00e9r\u00e9',
            medium_tooltip: '',
            high: 'Elev\u00e9',
            high_tooltip: '',
            unknown: 'Inconnu',
            unknown_tooltip: '',
            city: 'Ville',
            city_tooltip: '',
            suburbs: 'Banlieue',
            suburbs_tooltip: '',
            village: 'Village',
            village_tooltip: '',
            isolated: 'Isol\u00e9',
            isolated_tooltip: '',
            none: 'Aucun',
            none_tooltip: '',
            classified_facade: 'Fa\u00e7ade class\u00e9e',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Environnement vert',
            green_neighbourhood_tooltip: '',
            sea_view: 'Vue sur mer',
            sea_view_tooltip: '',
            no_direct_traffic: 'Pas de trafic direct',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Vue exceptionelle',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Train',
            train_tooltip: '',
            metro: 'M\u00e9tro',
            metro_tooltip: '',
            traffic: 'Trafic',
            traffic_tooltip: '',
            busy_area: 'Zone fr\u00e9quent\u00e9e',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
        },
        request: {
            references: 'R\u00e9f\u00e9rences',
            references_tooltip: '',
            valuation_request_ref: 'R\u00e9f\u00e9rence de la valorisation',
            valuation_request_ref_tooltip: '',
            customer_ref: 'R\u00e9f\u00e9rence interne',
            customer_ref_tooltip:
                "Ceci permet de relier la demande d'\u00e9valuation au pr\u00eat.",
            bank: 'Banque',
            bank_tooltip: '',
            customer: 'Client',
            customer_tooltip: '',
            package: '',
            package_tooltip: '',
        },
        footer: {
            text: 'Pour de plus amples informations, visitez {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Export\u00e9 par',
            by_tooltip: '',
            date: 'Date',
            date_tooltip: '',
            reference: 'R\u00e9f\u00e9rence',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            reconstruction_value_tooltip: '',
            address_location: 'Adresse et localisation',
            address_location_tooltip: '',
            secondary_buildings: 'B\u00e2timents secondaires',
            secondary_buildings_tooltip: '',
            economic_activity: 'Activit\u00e9 \u00e9conomique',
            economic_activity_tooltip: '',
            roof: 'Toit',
            roof_tooltip: '',
            warnings: 'Avertissements',
            warnings_tooltip: '',
            renovation: 'R\u00e9novation pr\u00e9vue',
            renovation_tooltip: '',
            general_info: 'Information g\u00e9n\u00e9rales',
            general_info_tooltip: '',
            parcels_and_buildings: 'Parcelles et b\u00e2timents',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Terrain',
            c_parcels_tooltip: '',
            c_building: 'B\u00e2timent principal',
            c_building_tooltip: '',
            building: 'B\u00e2timent',
            building_tooltip: '',
            building_structure: 'Structure du b\u00e2timent',
            building_structure_tooltip: '',
            building_purpose_classification:
                'Utilisation & classification du b\u00e2timent',
            building_purpose_classification_tooltip: '',
            parcels: 'Parcelle(s)',
            parcels_tooltip: '',
            surroundings: 'Environnement proche',
            surroundings_tooltip: '',
            valuations: 'Valorisations',
            valuations_tooltip: '',
            reference_properties_location: 'Biens de r\u00e9f\u00e9rences : emplacement',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics:
                'Biens de r\u00e9f\u00e9rences : caract\u00e9ristiques',
            reference_properties_characteristics_tooltip: '',
            flood: 'Zones inondables',
            flood_tooltip: '',
            flood_simple: 'Zones inondables',
            flood_simple_tooltip: '',
            faq: 'Foire aux questions',
            faq_tooltip: '',
            questions_feedback: 'Questions et commentaires',
            questions_feedback_tooltip: '',
            contact_info: 'Coordonn\u00e9es de contact',
            contact_info_tooltip: '',
            disclaimer: 'Mentions l\u00e9gales',
            disclaimer_tooltip: '',
        },
        front: {
            title: 'Rapport de valorisation de la propri\u00e9t\u00e9',
            title_tooltip: '',
            core_title: 'Rapport de propri\u00e9t\u00e9',
            core_title_tooltip: '',
        },
        mortgage: {
            purpose: 'Objectif de la valorisation',
            purpose_tooltip: '',
        },
        features: {
            renovation_cost: 'Co\u00fbt de r\u00e9novation (HTVA)',
            renovation_cost_tooltip: '',
            f_building_type: 'Type de bien',
            f_building_type_tooltip:
                'Est-que le client finance une maison unifamiliale ou un appartement?',
            f_number_of_facades: 'Nombre de fa\u00e7ades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Superficie totale de la parcelle',
            f_parcel_area_tooltip: '',
            f_building_area: 'Surface au sol du b\u00e2timent',
            f_building_area_tooltip: '',
            f_x_annexes: 'B\u00e2timents secondaires',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Surface totale des b\u00e2timents secondaires',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Plus grand b\u00e2timent secondaire',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'R\u00e9f\u00e9rences cadastrales',
            parcel_ids_tooltip: '',
            f_lng: 'Longitude',
            f_lng_tooltip: '',
            f_lat: 'Latitude',
            f_lat_tooltip: '',
            f_number_of_addresses: "Nombre d'adresses",
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Superficie habitable',
            f_living_area_tooltip:
                "La surface habitable totale du bien. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_construction_year: 'Ann\u00e9e de construction',
            f_construction_year_tooltip:
                "L'ann\u00e9e dans laquelle le bien a \u00e9t\u00e9 construit officiellement. Ceci ne tient pas compte d'\u00e9ventuelles r\u00e9novations qui ont eu lieu \u00e0 un moment ult\u00e9rieur. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_epc: 'PEB',
            f_epc_tooltip:
                "Le certificat PEB (Performance \u00c9nerg\u00e9tique du B\u00e2timent) est la carte d'identit\u00e9 \u00e9nerg\u00e9tique d'un b\u00e2timent. Cette information se retrouve normalement dans l'annonce immobili\u00e8re.",
            f_epc_label: 'PEB',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'PEB',
            f_epc_numeric_tooltip: '',
            f_bedrooms: 'Nombre de chambres',
            f_bedrooms_tooltip:
                'Nombre de pi\u00e8ces pouvant servir comme chambre \u00e0 coucher. Cuisine, salles de bain et salon ne sont pas inclus.',
            f_floor: '\u00c9tage',
            f_floor_tooltip:
                "L'\u00e9tage auquel l'appartement se situe. S'il est sur plusieurs \u00e9tages, choisissez le plus bas.",
            f_bottom_floor: "\u00c9tage le plus bas de l'immeuble",
            f_bottom_floor_tooltip:
                "L'\u00e9tage inf\u00e9rieur d'un b\u00e2timent est soit 0 soit un nombre n\u00e9gatif en fonction du nombre d'\u00e9tages souterrains. \nAttribuez la valeur 0 si le b\u00e2timent n'a pas de niveau souterrain. \nAttribuez une valeur n\u00e9gative si le b\u00e2timent poss\u00e8de un sous-sol/un garage souterrain/...",
            f_top_floor: "Dernier \u00e9tage de l'immeuble",
            f_top_floor_tooltip:
                "L'\u00e9tage le plus haut d'un b\u00e2timent l'\u00e9tage situ\u00e9 sous le toit du b\u00e2timent.",
            f_floors: "\u00c9tage(s) de l'appartement",
            f_floors_tooltip:
                "Apr\u00e8s avoir entr\u00e9 l'\u00e9tage le plus bas et le plus haut du b\u00e2timent, s\u00e9lectionnez le ou les \u00e9tages o\u00f9 se trouve l'appartement dans la liste g\u00e9n\u00e9r\u00e9e.\nPar exemple : pour un duplex se trouvant aux 2\u00e8me et 3\u00e8me \u00e9tage, s\u00e9lectionnez les deux \u00e9tages.",
            f_balcony_present: 'Terrasse',
            f_balcony_present_tooltip:
                "Choisissez 'oui' si l'appartement a au moins une terrasse capable d'accueillir une table de 4 personnes.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                "L'appartement peut disposer d'un ou plusieurs emplacements de parking. Il peut s'agir aussi bien d'un emplacement simple ou un box, \u00e0 l'interieur ou \u00e0 l'ext\u00e9rieur.",
            f_n_closed_garage: 'Nombre de garages ferm\u00e9s',
            f_n_closed_garage_tooltip:
                "Un garage ferm\u00e9 peut \u00eatre d'int\u00e9rieur ou d'ext\u00e9rieur. Indiquez le nombre de garages ferm\u00e9s appartenant \u00e0 l'appartement \u00e9valu\u00e9.",
            f_n_parking_spot: "Nombre d'emplacement de parkings",
            f_n_parking_spot_tooltip:
                'Une place de stationnement est un emplacement ext\u00e9rieur ou int\u00e9rieur d\u00e9limit\u00e9 par des marques de signalisation et non par des murs (par opposition aux garages ferm\u00e9s).\nIndiquez le nombre de places de stationnement li\u00e9es \u00e0 la propri\u00e9t\u00e9.',
            f_cellar_attic: 'Cave et/ou grenier',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Jardin',
            f_garden_access_tooltip:
                "L'appartement peut donner acc\u00e8s \u00e0 un jardin privatif ou commun.",
            f_garden_common: 'Acc\u00e8s \u00e0 un jardin commun',
            f_garden_common_tooltip:
                "Un jardin commun n'est accessible qu'aux occupants de l'immeuble o\u00f9 le bien est \u00e9valu\u00e9. \nS'il existe un jardin commun, l'appartement \u00e9valu\u00e9 y donne-t-il acc\u00e8s ? Si oui, s\u00e9lectionnez oui.",
            f_garden_private: 'Acc\u00e8s \u00e0 un jardin priv\u00e9',
            f_garden_private_tooltip:
                "Un jardin priv\u00e9 est r\u00e9serv\u00e9 aux occupants de l'appartement \u00e9valu\u00e9 et est adjacent \u00e0 l'appartement. La diff\u00e9rence entre un jardin et une terrasse est qu'un jardin dispose de gazon.",
            f_volume: 'Volume du b\u00e2timent principal',
            f_volume_tooltip: '',
            f_roof_area: 'Surface du toit',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Inclinaison moyenne du toit',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'Proportion de toit plat',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Hauteur minimale du toit',
            f_min_height_tooltip: '',
            f_max_height: 'Hauteur maximale du toit',
            f_max_height_tooltip: '',
            f_n_parts: 'Parties du b\u00e2timent principal',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Pans de toit',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Entit\u00e9s connues \u00e0 cette adresse',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: "Classe d'activit\u00e9 \u00e9conomique",
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: "Niveau d'activit\u00e9 \u00e9conomique",
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Surface du jardin',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Distance par rapport au milieu de la rue',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Zone inondable la plus proche',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                "Zone inondable la plus proche\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: "Risque d'inondation",
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion:
                "Risque d'inondation\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: "Risque d'inondation composite",
            f_flood_risk_composite_tooltip: '',
            level: "Niveau d'information",
            level_tooltip: '',
            f_ground_height_above_sea: 'Altitude (au-dessus du niveau de la mer)',
            f_ground_height_above_sea_tooltip: '',
        },
        disclaimer: {
            avm: '',
            avm_tooltip: '',
            dvm:
                "Ce rapport de valorisation est le r\u00e9sultat d'une valorisation \u00e0 distance du bien par un expert immobilier. L'expert n'a pas visit\u00e9 le bien mais s'est appuy\u00e9 sur diverses sources de donn\u00e9es et un mod\u00e8le statistique pour effectuer une valorisation ind\u00e9pendante du bien. Pour plus d'informations concernant cette approche de valorisation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            dvm_tooltip: '',
            ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat d'une \u00e9valuation sur place du bien par un expert immobilier. La valorisation ind\u00e9pendante du bien, est bas\u00e9e sur la situation observ\u00e9e sur place par l'expert, et est soustenue par un mod\u00e8le statistique. Pour plus d'informations concernant cette approche d'\u00e9valuation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            ovm_tooltip: '',
            part_1:
                "Cette valorisation a \u00e9t\u00e9 r\u00e9alis\u00e9e dans le but exprim\u00e9 ici, aucune responsabilit\u00e9 n'est accept\u00e9e dans le cas o\u00f9 ce rapport serait utilis\u00e9 dans un autre but ou par une autre partie que celle \u00e0 laquelle il est adress\u00e9. Si une autre partie souhaite utiliser ou s'appuyer sur ce rapport \u00e0 quelque fin que ce soit, elle doit d'abord obtenir le consentement \u00e9crit de Rockestate.\n",
            part_1_tooltip: '',
            part_2:
                "La publication de ce rapport, en tout ou en partie, est interdite sans l'accord \u00e9crit de Rockestate. Les droits d'auteur de ce rapport restent \u00e0 tout moment la propri\u00e9t\u00e9 de Rockestate. Ce rapport ne peut \u00eatre c\u00e9d\u00e9 \u00e0 un tiers sans le consentement expr\u00e8s du propri\u00e9taire du droit d'auteur.",
            part_2_tooltip: '',
            reconstruction_value_title: 'Calcul de la valeur de reconstruction',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                "La valeur de reconstruction est le co\u00fbt total de reconstruction d'un b\u00e2timent \u00e0 neuf consid\u00e9rant les normes de construction actuelles. Ce calcul inclus la TVA et les frais d'architecte, ne d\u00e9pend pas de l'emplacement, et n'inclus pas les frais de d\u00e9molition ni les b\u00e2timents secondaires. Par cons\u00e9quent, il est possible que la valeur de reconstruction soit plus \u00e9lev\u00e9e que la valeur de march\u00e9. La valeur de reconstruction est bas\u00e9e sur l'indice ABEX 847.",
            reconstruction_value_tooltip: '',
            part_3:
                "En faisant ce rapport de valorisation, nous n'exprimons aucune opinion et n'acceptons aucune responsabilit\u00e9 quant \u00e0 la capacit\u00e9 et \u00e0 la volont\u00e9 du d\u00e9biteur hypoth\u00e9caire de respecter ses engagements dans le cadre du pr\u00eat propos\u00e9.",
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'donn\u00e9es saisies par le client',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Style de construction',
            style_of_house_tooltip: '',
            exterior_state: '\u00c9tat ext\u00e9rieur de la propri\u00e9t\u00e9',
            exterior_state_tooltip: '',
            facade_material: 'Mat\u00e9riaux des fa\u00e7ades',
            facade_material_tooltip: '',
            roof_material: 'Mat\u00e9riaux de toiture',
            roof_material_tooltip: '',
            n_roof_windows: 'Nombre de fen\u00eatres de toit',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Nombre de lucarnes',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Surface des panneaux solaires',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Class\u00e9 \u00e0 des fins historiques',
            f_building_listed_tooltip: '',
            f_building_protected: 'Classification',
            f_building_protected_tooltip: '',
            garden_orientation: 'Orientation du jardin',
            garden_orientation_tooltip: '',
            garden_arrangement: 'Am\u00e9nagement (possible) du jardin',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Piscine ext\u00e9rieure',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Taille de la piscine ext\u00e9rieure',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Niveau de trafic direct dans la voirie',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Proximit\u00e9 du trafic indirecte sur la voirie',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Description des environs',
            surroundings_tooltip: '',
            other_positive: 'Autres \u00e9l\u00e9ments ayant un impact positif',
            other_positive_tooltip: '',
            other_negative: 'Autres \u00e9l\u00e9ments ayant un impact n\u00e9gatif',
            other_negative_tooltip: '',
            remarks: "Remarques de l'expert immobilier",
            remarks_tooltip: '',
            internal_remarks: 'Remarques internes',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Activit\u00e9 \u00e9conomique',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Formes de parcelles et de b\u00e2timents',
            parcels_and_building_shapes_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                "La valorisation de cette propri\u00e9t\u00e9 repose sur 200 biens de r\u00e9f\u00e9rence qui ont \u00e9t\u00e9 s\u00e9lectionn\u00e9es dans le voisinage de la propri\u00e9t\u00e9. Chaque bien de r\u00e9f\u00e9rence pr\u00e9sente un certain nombre de similitudes avec la propri\u00e9t\u00e9 \u00e9valu\u00e9e en termes d'emplacement, de taille et de plusieurs autres caract\u00e9ristiques. Le mod\u00e8le statistique sous-jacent de cette valorisation est bas\u00e9 sur une r\u00e9gression g\u00e9ographiquement pond\u00e9r\u00e9e (GWR) o\u00f9 les biens de r\u00e9f\u00e9rence situ\u00e9s \u00e0 proximit\u00e9 imm\u00e9diate de la propri\u00e9t\u00e9 re\u00e7oivent une pond\u00e9ration plus importante que les biens de r\u00e9f\u00e9rence situ\u00e9s dans un rayon plus \u00e9loign\u00e9.",
            explanation_1_tooltip: '',
            explanation_2:
                "La carte ci-dessous donne un aper\u00e7u de l'emplacement des biens de r\u00e9f\u00e9rence et de leur distance par rapport \u00e0 la propri\u00e9t\u00e9 \u00e9valu\u00e9e. La l\u00e9gende des couleur sur la carte donne une indication de la valeur de march\u00e9 moyenne des biens immobiliers dans votre r\u00e9gion.",
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                "Une propri\u00e9t\u00e9 poss\u00e8de diverses caract\u00e9ristiques objectives qui contribuent \u00e0 son prix, telles que l'emplacement, la taille et l'efficacit\u00e9 \u00e9nerg\u00e9tique. Les graphiques ci-dessous comparent certaines caract\u00e9ristiques de cette propri\u00e9t\u00e9 avec d\u2019autres biens plus ou moins proches.",
            explanation_1_tooltip: '',
            property_characteristics: 'Caract\u00e9ristiques de la propri\u00e9t\u00e9',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Propri\u00e9t\u00e9s dans votre r\u00e9gion',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                "Aper\u00e7u des zones d'inondation officielles \u00e0 proximit\u00e9 de la propri\u00e9t\u00e9. Plusieurs cat\u00e9gories de risque d'inondation sont calcul\u00e9es en fonction de diff\u00e9rents sc\u00e9narios de risque d'inondation, allant de faible \u00e0 \u00e9lev\u00e9.",
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                "L'adresse du bien \u00e9valu\u00e9 est incorrecte, que dois-je faire?",
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                "Il semble que quelque chose ait d\u00fb mal se passer lors de la s\u00e9lection de l'adresse, du b\u00e2timent ou des parcelles li\u00e9es \u00e0 la propri\u00e9t\u00e9. Vous pouvez revoir les informations sur l'adresse donn\u00e9e via le lien suivant : {url.dashboard}\u2028.",
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                "L'adresse du bien \u00e9valu\u00e9 est correcte, mais certaines des autres caract\u00e9ristiques de ce rapport sont incorrectes, que dois-je faire?",
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'Si vous pensez que certaines informations dans ce rapport peuvent diff\u00e9rer substantiellement de la situation r\u00e9elle, ce qui pourrait avoir un impact important sur la valorisation de la propri\u00e9t\u00e9, suivez ce lien {url.dashboard} pour revoir et clarifier les informations incorrectes/manquantes.\u2028',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                "Pourquoi le prix de la transaction que j'ai pay\u00e9 est diff\u00e9rent de la valeur de march\u00e9 d\u00e9termin\u00e9e dans ce rapport?",
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                "Ce rapport de valorisation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance du bien en question par un expert immobilier, soutenue par un mod\u00e8le statistique. Le rapport devrait donner une indication pr\u00e9cise de la valeur de march\u00e9 attendue du bien, \u00e0 base des informations disponibles. \u00c9tant donn\u00e9 que l'expert n'a pas r\u00e9ellement visit\u00e9 la propri\u00e9t\u00e9, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans la valuer de march\u00e9 estim\u00e9e. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance d'un expert immobilier, soutenue par un mod\u00e8le statistique bas\u00e9 sur des donn\u00e9es. Le rapport doit donner une indication pr\u00e9cise de la valeur attendue du bien, sur la base des informations disponibles. \u00c9tant donn\u00e9 que l'\u00e9valuateur n'a pas r\u00e9ellement visit\u00e9 le bien, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans le prix de transaction estim\u00e9. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                "Maintenant que j'ai re\u00e7u mon rapport de valorisation, cela signifie-t-il que ma demande de pr\u00eat a \u00e9t\u00e9 accept\u00e9e?",
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                "Nous avons partag\u00e9 ce rapport de valorisation avec vous et votre banque. Ce rapport de valorisation sera utilis\u00e9 par la banque comme l'un des \u00e9l\u00e9ments de son processus d'approbation du risque de cr\u00e9dit. La d\u00e9cision finale reste la leur. Rockestate n'intervient en aucune fa\u00e7on dans cette d\u00e9cision.",
            a_loan_accepted_tooltip: '',
        },
        questions_feedback: {
            explanation_1:
                "Vous avez une question ou un commentaire sp\u00e9cifique concernant ce rapport? Ou vous souhaitez simplement obtenir plus d'informations sur la mani\u00e8re dont Rockestate proc\u00e8de \u00e0 ces valorisations?",
            explanation_1_tooltip: '',
            explanation_2:
                'Veuillez visiter notre plateforme de valorisation : {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject:
                "Votre demande d'\u00e9valuation a \u00e9t\u00e9 soumise",
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                "Ch\u00e8re Madame, cher Monsieur,\n\nNous avons bien re\u00e7u votre nouvelle demande d'\u00e9valuation avec les r\u00e9f\u00e9rences suivantes:",
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                "Notre \u00e9quipe d'experts en \u00e9valuation va traiter votre demande dans les plus brefs d\u00e9lais. Vous pouvez suivre le progr\u00e8s de votre demande dans votre tableau r\u00e9capitulatif Rockestate.\n\nD'avance merci,\nL'\u00e9quipe d'experts en \u00e9valuation Rockestate.",
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject:
                "Votre demande d'\u00e9valuation a \u00e9t\u00e9 trait\u00e9e",
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Cher Madame, Monsieur,\n\nLa demande suivante a \u00e9t\u00e9 examin\u00e9e et \u00e9valu\u00e9e avec succ\u00e8s par nos experts:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                "Veuillez vous connecter \u00e0 votre tableau de bord pour t\u00e9l\u00e9charger le rapport.\n\nBien \u00e0 vous,\nL'\u00e9quipe de sp\u00e9cialistes de l'\u00e9valuation de Rockestate",
            valuation_notification_2_tooltip: '',
            incoherent_features_subject:
                "Votre demande de valorisation n'a pas pu \u00eatre trait\u00e9e",
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                "Ch\u00e8re Madame, cher Monsieur,\n\nLa demande d'\u00e9valuation que vous avez envoy\u00e9e (voir r\u00e9f\u00e9rences ci-dessous) ne peut pas \u00eatre compl\u00e9t\u00e9e en raison d'une information incorrecte:",
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Selon notre expert en \u00e9valuation a remarqu\u00e9 pour cette demande que les caract\u00e9ristiques suivantes pourraient \u00eatre incorrectes: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3:
                "Nous vous remercions d'adapter cette information depuis votre tableau r\u00e9capitulatif Rockestate. D\u00e8s que tout est rempli, veuillez soumettre \u00e0 nouveau votre demande et notre expert proc\u00e9dera \u00e0 l'\u00e9valuation. \n\nD'avance merci,\nL'\u00e9quipe d'experts en \u00e9valuation Rockestate.",
            incoherent_features_3_tooltip: '',
            on_site_requested_value:
                "Ch\u00e8re Madame, cher Monsieur,\n\nSelon notre expert en \u00e9valuation, la demande d'\u00e9valuation que vous avez envoy\u00e9e (voir r\u00e9f\u00e9rences ci-dessous) ne peut pas \u00eatre finalis\u00e9e:\n- Identifiant LOP:\n- Num\u00e9ro d'\u00e9valuation:\n- Adresse:\n\nEn raison du caract\u00e8re unique de ce bien et du contraste avec d'autres propri\u00e9t\u00e9s avoisinantes, une expertise sur site est pr\u00e9f\u00e9rable.\n\nD'avance merci,\n\nL'\u00e9quipe d'experts en \u00e9valuation Rockestate",
            on_site_requested_value_tooltip: '',
            on_site_requested_characteristics_subject:
                'Une \u00e9valuation sur place est recommand\u00e9e',
            on_site_requested_characteristics_subject_tooltip: '',
            on_site_requested_characteristics_1:
                "Ch\u00e8re Madame, cher Monsieur,\n\nSelon notre expert en \u00e9valuation, la demande d'\u00e9valuation que vous avez envoy\u00e9e (voir r\u00e9f\u00e9rences ci-dessous) ne peut pas \u00eatre finalis\u00e9e:",
            on_site_requested_characteristics_1_tooltip: '',
            on_site_requested_characteristics_2:
                "En raison de certaines caract\u00e9ristiques du bien (b\u00e2timent class\u00e9/prot\u00e9g\u00e9, bien non visible depuis la rue, etc...), une expertise sur site est pr\u00e9f\u00e9rable.\n\nD'avance merci,\nL'\u00e9quipe d'experts en \u00e9valuation Rockestate",
            on_site_requested_characteristics_2_tooltip: '',
            address_not_found:
                "Cher Monsieur, Madame,\n\nVous avez demand\u00e9 une \u00e9valuation via notre outil, cependant, il semble y avoir un probl\u00e8me concernant l'adresse du bien. \n\nPourriez-vous revoir la demande pour la propri\u00e9t\u00e9 avec les r\u00e9f\u00e9rences suivantes : \n- LOP ID : [LOP ID]\n- Valuation ID : [ID d'\u00e9valuation]\n- Adresse : [Adresse]\n\nNous n'avons pas pu trouver cette adresse dans nos outils internes ni sur internet. \nSi l'adresse est incorrecte, veuillez la corriger directement dans votre tableau de bord.\n\nNous avons besoin que toutes les informations soient remplies et enti\u00e8rement compl\u00e9t\u00e9es pour donner \u00e0 [BANK] une estimation correcte de la valeur de ce bien. D\u00e8s que l'adresse sera confirm\u00e9e, notre sp\u00e9cialiste en \u00e9valuation poursuivra l'\u00e9valuation. \n\nBien \u00e0 vous,\n\nL'\u00e9quipe de sp\u00e9cialistes en \u00e9valuation de Rockestate",
            address_not_found_tooltip: '',
        },
    },
    'nl-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2jr',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/maand',
            'euro/month_tooltip': '',
        },
        address_text: {
            full_address: 'Adres',
            full_address_tooltip:
                'Selecteer het adres van het pand dat wordt gefinancierd. Gelieve dit niet te verwarren met het adres van eventuele andere panden die gebruikt worden als extra zekerheid.',
        },
        valuation: {
            date: 'Waarderingsdatum',
            date_tooltip: '',
            type: 'Soort waardering',
            type_tooltip: '',
            valuer: 'Uitgevoerd door',
            valuer_tooltip: '',
            monitor: 'Gecontroleerd door',
            monitor_tooltip: '',
            market_value: 'Marktwaarde',
            market_value_tooltip: '',
            forced_sale_value: 'Gedwongen verkoopwaarde',
            forced_sale_value_tooltip: '',
            rental_value: 'Maandelijkse huurprijs',
            rental_value_tooltip: '',
            reconstruction_value: 'Heropbouwwaarde',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: 'Soort index',
            index_type_tooltip: '',
            explanation_1:
                'De waardering van dit gebouw berust gedeeltelijk op een data-gedreven statistisch model. Het resultaat werd door een vastgoedschatter gecontroleerd en bijgestuurd waar nodig. Het betreft een proces in twee stappen:',
            explanation_1_tooltip: '',
            explanation_2: 'Stap 1: Schatting door waarderingsmodel',
            explanation_2_tooltip: '',
            explanation_3:
                'Een eerste schatting van de marktwaarde van het pand wordt door het model berekend op basis van alle input van de klant alsook enkele bijkomende inputparameters (cf. blz. 2). Deze schatting geeft de gemiddelde marktwaarde voor een pand met deze locatie en karakteristieken.',
            explanation_3_tooltip: '',
            explanation_4: 'Stap 2: Evaluatie en verfijning door vastgoedschatter',
            explanation_4_tooltip: '',
            explanation_5:
                'De vastgoedschatter verifieert het model in stap 1 en houdt vervolgens rekening met de andere inputparameters. Dit resulteert in de marktwaarde van het pand (rode lijn in grafiek). Afhankelijk van de correcties die door de vastgoedschatter worden toegepast, kan de marktwaarde lager of hoger uitvallen dan de oorspronkelijke waardering van het model.\nDe onderstaande grafiek illustreert hoe de marktwaarde van dit pand zich vergelijkt met andere panden in de omgeving. Het kan helpen om deze waardering te contextualiseren.',
            explanation_5_tooltip: '',
            explanation_6:
                'De marktwaarde in dit rapport is gebaseerd op een waardering uitgevoerd door een vastgoedschatter op afstand. De waardering tracht een zo goed mogelijk zicht te geven op de huidige waarde van het pand. Het blijft echter een schatting waarbij de volgende elementen in aanmerking moeten worden genomen:\n- Waardering op afstand: de schatter heeft het pand van op afstand ge\u00ebvalueerd en heeft dit niet effectief bezocht.\n- Nauwkeurigheid van het model: de waardering berust gedeeltelijk op een statistisch model met een bepaalde foutenmarge.\n- Subjectiviteit: zelfs de best mogelijke waardering uitgevoerd door een schatter bevat een zeker niveau van subjectiviteit.',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Automatische waardering',
            avm_tooltip: '',
            dvm: 'Waardering op afstand',
            dvm_tooltip: '',
            ovm: 'Waardering ter plaatse',
            ovm_tooltip: '',
            new_loan: 'Nieuwe kredietaanvraag',
            new_loan_tooltip: '',
            revaluation: 'Herwaardering',
            revaluation_tooltip: '',
            classic: '',
            classic_tooltip: '',
            renovation_light: '',
            renovation_light_tooltip: '',
            renovation_full: '',
            renovation_full_tooltip: '',
            new_construction: '',
            new_construction_tooltip: '',
            house: 'Huis',
            house_tooltip: '',
            apartment: 'Appartement',
            apartment_tooltip: '',
            annex: 'Bijgebouw',
            annex_tooltip: '',
            attached: 'Gesloten bebouwing',
            attached_tooltip: '',
            semi: 'Half-open bebouwing',
            semi_tooltip: '',
            detached: 'Open bebouwing',
            detached_tooltip: '',
            yes: 'Ja',
            yes_tooltip: '',
            no: 'Nee',
            no_tooltip: '',
            traditional: 'Traditioneel',
            traditional_tooltip: '',
            modern: 'Modern',
            modern_tooltip: '',
            contemporary: 'Hedendaags',
            contemporary_tooltip: '',
            mediterranean: 'Mediterraans',
            mediterranean_tooltip: '',
            other: 'Andere',
            other_tooltip: '',
            ground_floor: 'Gelijkjvloers',
            ground_floor_tooltip: '',
            '1': '1',
            '1_tooltip': '',
            '2': '2',
            '2_tooltip': '',
            '3': '3',
            '3_tooltip': '',
            '4': '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Priv\u00e9 tuin',
            private_tooltip: '',
            common: 'Gemeenschappelijke tuin',
            common_tooltip: '',
            'private+common': 'Priv\u00e9 en gemeenschappelijke tuin',
            'private+common_tooltip': '',
            maintenance_required: 'Onderhoud nodig',
            maintenance_required_tooltip: '',
            good: 'Goed',
            good_tooltip: '',
            very_good: 'Zeer goed',
            very_good_tooltip: '',
            cinder_blocks: 'Sintelblokken',
            cinder_blocks_tooltip: '',
            wood: 'Hout',
            wood_tooltip: '',
            plaster: 'Gips',
            plaster_tooltip: '',
            natural_stone: 'Natuursteen',
            natural_stone_tooltip: '',
            metal: 'Metaal',
            metal_tooltip: '',
            concrete: 'Beton',
            concrete_tooltip: '',
            brick: 'Baksteen',
            brick_tooltip: '',
            tiles: 'Pannen',
            tiles_tooltip: '',
            bitumen_roofing: 'Bitumen dakbedekking',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Leistenen dakpan',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Zwarte betontegels',
            black_concrete_tiles_tooltip: '',
            thatched: 'Rieten dak',
            thatched_tooltip: '',
            asbestos: 'Asbest',
            asbestos_tooltip: '',
            home_liberal_profession_or_office: 'Huis, vrij beroep of kantoor',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Andere beroepsactiviteit',
            home_other_professional_activity_tooltip: '',
            home: 'Huis',
            home_tooltip: '',
            no_economic_activity: 'Geen economische activiteit',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activiteit onwaarschijnlijk',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activiteit mogelijk',
            activity_possible_tooltip: '',
            activity_likely: 'Activiteit waarschijnlijk',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activiteit zeer waarschijnlijk',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NO',
            ne_tooltip: '',
            e: 'O',
            e_tooltip: '',
            se: 'ZO',
            se_tooltip: '',
            s: 'Z',
            s_tooltip: '',
            sw: 'ZW',
            sw_tooltip: '',
            w: 'W',
            w_tooltip: '',
            nw: 'NW',
            nw_tooltip: '',
            limited: 'Beperkt',
            limited_tooltip: '',
            details_previous_page: '(details op vorige pagina)',
            details_previous_page_tooltip: '',
            low: 'Laag',
            low_tooltip: '',
            medium: 'Gemiddeld',
            medium_tooltip: '',
            high: 'Hoog',
            high_tooltip: '',
            unknown: 'Onbekend',
            unknown_tooltip: '',
            city: 'Stad',
            city_tooltip: '',
            suburbs: 'Stedelijk',
            suburbs_tooltip: '',
            village: 'Dorp',
            village_tooltip: '',
            isolated: 'Ge\u00efsoleerd',
            isolated_tooltip: '',
            none: 'Geen',
            none_tooltip: '',
            classified_facade: 'Beschermde gevel',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Groene buurt',
            green_neighbourhood_tooltip: '',
            sea_view: 'Zeezicht',
            sea_view_tooltip: '',
            no_direct_traffic: 'Geen direct verkeer',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Uitzonderlijk zicht',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Trein',
            train_tooltip: '',
            metro: 'Metro',
            metro_tooltip: '',
            traffic: 'Verkeer',
            traffic_tooltip: '',
            busy_area: 'Drukke omgeving',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
        },
        request: {
            references: 'Referenties',
            references_tooltip: '',
            valuation_request_ref: 'Waarderingsreferentie',
            valuation_request_ref_tooltip: '',
            customer_ref: 'Interne referentie',
            customer_ref_tooltip:
                'Hiermee wordt de link gelegd tussen de aanvraag en de lening.',
            bank: 'Bank',
            bank_tooltip: '',
            customer: 'Klant',
            customer_tooltip: '',
            package: '',
            package_tooltip: '',
        },
        footer: {
            text: 'Voor meer informatie, bezoek {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Uitgevoerd door',
            by_tooltip: '',
            date: 'Datum',
            date_tooltip: '',
            reference: 'Referentie',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Heropbouw',
            reconstruction_value_tooltip: '',
            address_location: 'Adres en locatie',
            address_location_tooltip: '',
            secondary_buildings: 'Bijgebouwen',
            secondary_buildings_tooltip: '',
            economic_activity: 'Economische activiteit',
            economic_activity_tooltip: '',
            roof: 'Dak',
            roof_tooltip: '',
            warnings: 'Waarschuwingen',
            warnings_tooltip: '',
            renovation: 'Geplande renovatie',
            renovation_tooltip: '',
            general_info: 'Algemene informatie',
            general_info_tooltip: '',
            parcels_and_buildings: 'Percelen en gebouwen',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Grond',
            c_parcels_tooltip: '',
            c_building: 'Hoofdgebouw',
            c_building_tooltip: '',
            building: 'Gebouw',
            building_tooltip: '',
            building_structure: 'Structuur van het gebouw',
            building_structure_tooltip: '',
            building_purpose_classification: 'Bestemming en klassering van het gebouw',
            building_purpose_classification_tooltip: '',
            parcels: 'Perceel',
            parcels_tooltip: '',
            surroundings: 'Directe omgeving',
            surroundings_tooltip: '',
            valuations: 'Waarderingen',
            valuations_tooltip: '',
            reference_properties_location: 'Referentiepanden: locatie',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics: 'Referentiepanden: kenmerken',
            reference_properties_characteristics_tooltip: '',
            flood: 'Overstromingsgebieden',
            flood_tooltip: '',
            flood_simple: 'Overstromingsgebieden',
            flood_simple_tooltip: '',
            faq: 'Veelgestelde vragen',
            faq_tooltip: '',
            questions_feedback: 'Vragen en feedback',
            questions_feedback_tooltip: '',
            contact_info: 'Contactgegevens',
            contact_info_tooltip: '',
            disclaimer: 'Disclaimer',
            disclaimer_tooltip: '',
        },
        front: {
            title: 'Waarderingsverslag',
            title_tooltip: '',
            core_title: 'Eigendomsrapport ',
            core_title_tooltip: '',
        },
        mortgage: {
            purpose: 'Doel van waardering',
            purpose_tooltip: '',
        },
        features: {
            renovation_cost: 'Renovatiekosten (excl BTW)',
            renovation_cost_tooltip: '',
            f_building_type: 'Type woning',
            f_building_type_tooltip:
                'Financiert de klant een \u00e9\u00e9ngezinswoning of een appartement?',
            f_number_of_facades: 'Aantal gevels',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Totale perceeloppervlakte',
            f_parcel_area_tooltip: '',
            f_building_area: 'Oppervlakte aan de grond van het hoofdgebouw',
            f_building_area_tooltip: '',
            f_x_annexes: 'Bijgebouwen',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Totale oppervlakte van bijgebouwen',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Grootste bijgebouw',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'Kadastrale referenties',
            parcel_ids_tooltip: '',
            f_lng: 'Lengtegraad',
            f_lng_tooltip: '',
            f_lat: 'Breedtegraad',
            f_lat_tooltip: '',
            f_number_of_addresses: 'Aantal adressen',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Bewoonbare oppervlakte',
            f_living_area_tooltip:
                'De totale bewoonbare oppervlakte van de woning. Deze info is doorgaans te vinden in de advertentie van de woning.',
            f_construction_year: 'Bouwjaar',
            f_construction_year_tooltip:
                'Het jaar waarin de woning officieel gebouwd werd. Dit houdt geen rekening met eventuele renovaties op een later tijdstip. Deze info is doorgaans te vinden in de verkoopadvertentie.',
            f_epc: 'EPC',
            f_epc_tooltip:
                'Het Energie Prestatie Co\u00ebfficient (EPC) is de energiescore van een woning. Deze info is doorgaans te vinden in de verkoopadvertentie.',
            f_epc_label: 'EPC',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'EPC',
            f_epc_numeric_tooltip: '',
            f_bedrooms: 'Aantal slaapkamers',
            f_bedrooms_tooltip:
                'Totaal aantal kamers die kunnen dienen als slaapkamer. Keuken, badkamer en woonkamer worden hier niet bij meegerekend.',
            f_floor: 'Verdieping',
            f_floor_tooltip:
                'De verdieping waarop het appartement zich bevindt. Indien meerdere, kies de laagste.',
            f_bottom_floor: 'Onderste verdieping van het gebouw',
            f_bottom_floor_tooltip:
                'De onderste verdieping van een gebouw is ofwel 0 ofwel een negatief getal, afhankelijk van het aantal ondergrondse verdiepingen. \nWijs een waarde van 0 toe als het gebouw geen ondergrondse verdiepingen heeft. \nWijs een negatieve waarde toe als het gebouw een kelder/ondergrondse garage/... heeft.',
            f_top_floor: 'Bovenste verdieping van het gebouw',
            f_top_floor_tooltip:
                'De bovenste verdieping van een gebouw is de verdieping onder het dak van het gebouw.',
            f_floors: 'Verdieping(en) van het appartement',
            f_floors_tooltip:
                'Nadat u de onderste en bovenste verdieping van het gebouw hebt ingesteld, selecteert u de verdieping(en) waarop het appartement zich bevindt in de gegenereerde lijst.\nBijvoorbeeld: voor een duplex op verdieping 2 en 3, selecteert u beide verdiepingen.',
            f_balcony_present: 'Terras',
            f_balcony_present_tooltip:
                "Indien het appartment minstens \u00e9\u00e9n terras heeft waarop een tafel voor 4 personen kan staan, dan selecteert u hier 'Ja'.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                'Het appartment kan beschikken over \u00e9\u00e9n of meerdere garageplaatsen. Dit kan zowel een standplaats zijn als een box, binnen of buiten.',
            f_n_closed_garage: 'Aantal gesloten garages',
            f_n_closed_garage_tooltip:
                'Een gesloten garage is een garagebox, dit kan binnen of buiten zijn. Vermeld het aantal gesloten garages die bij het beoordeelde appartement behoren.',
            f_n_parking_spot: 'Aantal parkeerplaatsen',
            f_n_parking_spot_tooltip:
                'Een parkeerplaats is een staanplaats buiten of binnen en is afgebakend door markeringen en niet door muren (in tegenstelling tot gesloten garages).\nVermeld het aantal parkeerplaatsen die bij het pand horen.',
            f_cellar_attic: 'Kelder en/of zolder',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Tuin',
            f_garden_access_tooltip:
                'Het appartement kan toegang hebben tot een privatieve of gemeenschappelijke tuin.',
            f_garden_common: 'Toegang tot een gemeenschappelijke tuin',
            f_garden_common_tooltip:
                'Een gemeenschappelijke tuin is alleen toegankelijk voor de bewoners van het gebouw waar de woning wordt beoordeeld. \nAls er een gemeenschappelijke tuin is, heeft het te beoordelen appartement er dan toegang toe? Zo ja, selecteer ja.',
            f_garden_private: 'Toegang tot een privatieve tuin',
            f_garden_private_tooltip:
                'Een priv\u00e9tuin is enkel voor de bewoners van het beoordeelde appartement en grenst aan het appartement. Het verschil tussen een tuin en een terras is dat een tuin gras heeft.',
            f_volume: 'Volume hoofdgebouw',
            f_volume_tooltip: '',
            f_roof_area: 'Dakoppervlakte',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Gemiddelde dakhelling',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: '% plat dak',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Minimale dakhoogte',
            f_min_height_tooltip: '',
            f_max_height: 'Maximale dakhoogte',
            f_max_height_tooltip: '',
            f_n_parts: 'Delen van het hoofdgebouw',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Dakdelen',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Entiteiten bekend op dit adres',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'Soort economische activiteit',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'Niveau van economische activiteit',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Tuinoppervlak',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Afstand tot midden van straat',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Dichtstbijzijnd overstromingsgebied',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'Dichtstbijzijnd overstromingsgebied\n(risicozones)',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'Overstromingsrisico',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'Overstromingsrisico\n(risicozones)',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'Samengesteld overstromingsrisico',
            f_flood_risk_composite_tooltip: '',
            level: 'Informatieniveau',
            level_tooltip: '',
            f_ground_height_above_sea: 'Hoogte (boven zeespiegel)',
            f_ground_height_above_sea_tooltip: '',
        },
        disclaimer: {
            avm: '',
            avm_tooltip: '',
            dvm:
                'Dit waarderingsverslag is het resultaat van een evaluatie op afstand van het pand door een vastgoedschatter. Deze persoon heeft het pand niet bezocht, maar heeft op basis van diverse beschikbare data en een statistisch model een onafhankelijke waardering van het pand gemaakt. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit rapport.',
            dvm_tooltip: '',
            ovm:
                'Dit waarderingsverslag is het resultaat van een evaluatie van het pand gemaakt ter plaatse door een vastgoedexpert. Deze onafhankelijke waardering werd gemaakt op basis van de geobserveerde situatie van het pand door de expert, en wordt verder ondersteund door een statistisch model. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit verslag.',
            ovm_tooltip: '',
            part_1:
                'Deze evaluatie werd uitgevoerd voor het hierbij aangegeven doel. Er wordt geen verantwoordelijkheid aanvaard voor het geval dat dit rapport wordt gebruikt voor een ander doel of door een andere partij dan de partij tot wie het is gericht. Indien een andere partij dit rapport wenst te gebruiken, dient zij vooraf de schriftelijke toestemming van Rockestate te krijgen.\n',
            part_1_tooltip: '',
            part_2:
                'De publicatie van dit waarderingsverslag, in zijn geheel of gedeeltelijk, is verboden zonder de schriftelijke toestemming van Rockestate. Het auteursrecht van dit rapport blijft te allen tijde eigendom van Rockestate. Dit rapport mag niet worden overgedragen aan een derde partij zonder de expliciete toestemming van de eigenaar van het copyright.',
            part_2_tooltip: '',
            reconstruction_value_title: 'Heropbouwwaarde berekening',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                'De heropbouwwaarde is de totale kostprijs om een gebouw opnieuw op te bouwen volgens de huidige bouwnormen. Deze berekening is inclusief BTW en erelonen van architecten, is niet afhankelijk van de locatie en omvat geen sloopkosten of bijgebouwen. Hierdoor is het mogelijk dat de heropbouwwaarde hoger is dan de marktwaarde. De heropbouwwaarde is gebaseerd op de ABEX 847 index.',
            reconstruction_value_tooltip: '',
            part_3:
                'Dit waarderingsverslag spreekt zich niet uit over, noch aanvaardt het enige verantwoordelijkheid voor, het (on)vermogen en de (on)wil van de hypotheeknemer om zijn verplichtingen in het kader van de voorgestelde lening na te komen.',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'data verkregen van de klant',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Stijl van gebouw',
            style_of_house_tooltip: '',
            exterior_state: 'Staat van het pand aan de buitenkant',
            exterior_state_tooltip: '',
            facade_material: 'Gevelmateriaal',
            facade_material_tooltip: '',
            roof_material: 'Materiaal dak',
            roof_material_tooltip: '',
            n_roof_windows: 'Aantal dakvensters',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Aantal dakkapellen',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Zonnepanelen oppervlakte',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Erfgoed',
            f_building_listed_tooltip: '',
            f_building_protected: 'Klassificatie',
            f_building_protected_tooltip: '',
            garden_orientation: 'Ori\u00ebntatie van de tuin',
            garden_orientation_tooltip: '',
            garden_arrangement: '(Mogelijke) tuininrichting',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Buitenzwembad',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Grootte buitenzwembad',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Niveau van direct straatverkeer',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Nabijheid van indirect straatverkeer',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Beschrijving van de omgeving',
            surroundings_tooltip: '',
            other_positive:
                'Andere elementen die een positieve invloed hebben op de vastgoedwaardering',
            other_positive_tooltip: '',
            other_negative:
                'Andere elementen die een negatieve invloed hebben op de vastgoedwaardering',
            other_negative_tooltip: '',
            remarks: 'Opmerkingen van de vastgoeddeskundige',
            remarks_tooltip: '',
            internal_remarks: 'Interne opmerkingen',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Economische activiteit',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Vorm van perceel en gebouw',
            parcels_and_building_shapes_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                'De waardering van dit pand is gebaseerd op 200 referentiepanden in de buurt. Elk referentiepand deelt een aantal elementen inzake ligging, grootte en andere vergelijkbare kenmerken. Het onderliggende statistische model van deze waardering is gebaseerd op een "Geographically Weighted Regression" (GWR), waarbij referentiepunten in de onmiddellijke nabijheid van het pand een grotere gewicht krijgen in vergelijking met referentiepunten die zich verderaf bevinden.',
            explanation_1_tooltip: '',
            explanation_2:
                'De onderstaande kaart geeft een overzicht van de locatie van de referentiepanden en de afstand tot het te evalueren pand. De kleurenlegende geeft een goede indicatie van de gemiddelde marktwaarde van woningen in uw omgeving.',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                'Een pand heeft diverse objectieve kenmerken die bijdragen aan de prijs zoals locatie, grootte en energie-effici\u00ebntie. De onderstaande grafieken vergelijken een aantal van deze kenmerken van deze woning met de andere referentiepanden in de buurt.',
            explanation_1_tooltip: '',
            property_characteristics: 'Kenmerken pand',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Referentiepanden in uw buurt',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                "Overzicht van de offici\u00eble overstromingsgebieden in de nabijheid van de woning. Er worden verschillende risicocategorie\u00ebn berekend op basis van verschillende overstromingsrisicoscenario's, vari\u00ebrend van laag tot hoog.",
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                'Het adres van de gewaardeerde woning is onjuist, wat moet ik doen?',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                'Misschien ging er iets mis bij het selecteren van het adres, het gebouw of de percelen die bij het pand horen. U kunt de adresgegevens bekijken via de volgende link: {url.dashboard}.\u2028',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                'Het adres van het pand is juist, maar andere kenmerken in dit waarderingsverslag zijn niet correct, wat moet ik doen?',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'Indien u denkt dat bepaalde informatie in dit verslag substantieel kunnen verschillen, en dit zou kunnen leiden tot een belangrijke impact op de waardering van het onroerend goed, volg dan deze link {url.dashboard} om de onjuiste/ontbrekende informatie te controleren en te verduidelijken.',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                'Waarom verschilt de transactieprijs die ik heb betaald van de marktwaarde die in dit verslag wordt bepaald?',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                'Dit waarderingsverslag is het resultaat van de waardering van het pand in kwestie, uitgevoerd door een vastgoedschatter op afstand, en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de schatter het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                'Dit waarderingsverslag is het resultaat van de evaluatie, door een vastgoedexpert, op afstand en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de expert het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                'Nu ik mijn waarderingsverslag heb ontvangen, betekent dit dat mijn kredietaanvraag is goedgekeurd?',
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                'Wij hebben dit waarderingsverslag met u en uw bank gedeeld. Dit verslag zal door de bank worden gebruikt als een van de verschillende elementen in haar beslissingsproces. De uiteindelijke beslissing komt toe aan de bank. Rockestate komt hier op geen enkele manier in tussen.',
            a_loan_accepted_tooltip: '',
        },
        questions_feedback: {
            explanation_1:
                'Heeft u een specifieke vraag of opmerking over dit rapport? Of bent u gewoon op zoek naar wat meer informatie over hoe Rockestate tot deze waardering komt?',
            explanation_1_tooltip: '',
            explanation_2: 'Bezoek ons waarderingsportaal: {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'Je schattingsaanvraag is ingediend',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                'Beste,\n\nWe hebben je nieuwe schattingsaanvraag in goede orde ontvangen en dit met de volgende referentie:',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                'Ons team van schatters-experten zal je aanvraag zo spoedig mogelijk behandelen. Je kan steeds de status van je aanvraag opvolgen via je persoonlijke Rockestate dashboard.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rockestate',
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'Je schattingsaanvraag is verwerkt',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Beste,\n\nDe volgende aanvraag is met succes beoordeeld en ge\u00ebvalueerd door onze experten:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                'Gelieve in te loggen op je dashboard om het rapport te downloaden.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rockestate',
            valuation_notification_2_tooltip: '',
            incoherent_features_subject: 'Je schattingsaanvraag kon niet worden verwerkt',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                'Beste,\n\nJe schattingsaanvraag (zie referentie hieronder) kan niet worden gefinaliseerd en dit door incorrecte informatie:',
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Onze schatter-expert heeft opgemerkt dat bij deze aanvraag volgende kenmerken mogelijk incorrect zijn: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3:
                'Gelieve er voor te zorgen dat deze informatie correct is en pas dit aan in je dashboard. Van zodra alles ingevuld is, dien je de aanvraag nogmaals in en onze schatter-expert zal de waardebepaling afronden.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rockestate',
            incoherent_features_3_tooltip: '',
            on_site_requested_value:
                'Beste,\n\nNa een grondige analyse kan onze schatting-expert je schattingsaanvraag (zie referentie hieronder) niet finaliseren:\n- LOP ID:\n- Schatting ID:\n- Adres:\n\nDoor het unieke karakter van deze woning en het contrast met andere eigendommen in de buurt, stellen wij voor om een schatter ter plaatse te sturen.\n\nMet vriendelijke groeten,\n\nTeam van de schatters-experten Rockestate',
            on_site_requested_value_tooltip: '',
            on_site_requested_characteristics_subject:
                'Een schatting ter plaatse wordt aanbevolen',
            on_site_requested_characteristics_subject_tooltip: '',
            on_site_requested_characteristics_1:
                'Beste,\n\nNa een grondige analyse kan onze schatting-expert je schattingsaanvraag (zie referentie hieronder) niet finaliseren:',
            on_site_requested_characteristics_1_tooltip: '',
            on_site_requested_characteristics_2:
                'Vanwege bepaalde kenmerken (bv exclusieve verbouwingen, een historisch beschermd gebouw, de woning is niet zichtbaar via de straat, \u2026) stellen we voor om een schatter ter plaatse te sturen.\n\nMet vriendelijke groeten,\nTeam van de schatters-experten Rockestate',
            on_site_requested_characteristics_2_tooltip: '',
            address_not_found:
                'Beste,\n\nJe hebt een schatting aangevraagd via onze tool, maar er blijkt een probleem te zijn met het adres van de woning.\n \nKan je de aanvraag nakijken voor de woning met volgende referenties: \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nWij konden dit adres zowel in onze tools als online niet terugvinden. Gelieve het adres in je dashboard aan te passen indien dit incorrect is. \n\nWij hebben alle correcte informatie nodig om [BANK] een correcte waardebepaling te geven van deze woning. Van zodra het adres bevestigd is zullen onze schatter-experts de waardebepaling van de desbetreffende woning zo snel mogelijk afronden.\n\nMet vriendelijke groeten,\n\nTeam van de schatters-experten Rockestate',
            address_not_found_tooltip: '',
        },
    },
    'en-BE': {
        unit: {
            m: 'm',
            m_tooltip: '',
            m2: 'm\u00b2',
            m2_tooltip: '',
            m3: 'm\u00b3',
            m3_tooltip: '',
            'kwh/m2year': 'kWh/m\u00b2yr',
            'kwh/m2year_tooltip': '',
            euro: '\u20ac',
            euro_tooltip: '',
            'euro/month': '\u20ac/month',
            'euro/month_tooltip': '',
        },
        address_text: {
            full_address: 'Address',
            full_address_tooltip:
                'Select the address of the property that is being financed. Please do not confuse this with the address of possible other properties that are being used as additional collateral.',
        },
        valuation: {
            date: 'Valuation date',
            date_tooltip: '',
            type: 'Valuation type',
            type_tooltip: '',
            valuer: 'Performed by',
            valuer_tooltip: '',
            monitor: 'Monitored by',
            monitor_tooltip: '',
            market_value: 'Market value',
            market_value_tooltip: '',
            forced_sale_value: 'Forced sale value',
            forced_sale_value_tooltip: '',
            rental_value: 'Monthly rental value',
            rental_value_tooltip: '',
            reconstruction_value: 'Reconstruction value',
            reconstruction_value_tooltip: '',
            index_value: 'Index',
            index_value_tooltip: '',
            index_type: 'Index type',
            index_type_tooltip: '',
            explanation_1:
                'The valuation of this property relies in part on a data-driven statistical model. Its result has been reviewed and fine-tuned by a real estate expert. This is a two-step process:',
            explanation_1_tooltip: '',
            explanation_2: 'Step 1: Estimate by valuation model',
            explanation_2_tooltip: '',
            explanation_3:
                'An initial estimate of the property\u2019s Market value is calculated by the model based on all Customer input and some of the other input parameters (cf. page 2). This valuation represents the average market value for a property with this location and characteristics.',
            explanation_3_tooltip: '',
            explanation_4: 'Step 2: Review and fine-tuning by real estate expert',
            explanation_4_tooltip: '',
            explanation_5:
                "The real estate expert supervises the model valuation in Step 1 and subsequently factors in the remaining input parameters. This results in the Market value of the property (red line in graph). Depending on the corrections applied by the real estate expert, the Market value can be lower or higher compared to the initial estimate of the valuation model.\n\nThe chart below illustrates how the Market value of this property compares to other properties in the neighbourhoud. It always contextualizes the expert's review.",
            explanation_5_tooltip: '',
            explanation_6:
                'The Market value in this report is based on an expert-reviewed desktop valuation and attempts to give the best possible view on the property\u2019s current value. However, it remains an estimation where the following elements need to be taken into account:\n- Remote valuation: the expert has evaluated the property remotely and did not actually visit the property.\n- Model accuracy: the valuation relies, at least partially, on a statistical model which has a certain margin of error.\n- Level of subjectivity: even the best expert valuation is somewhat biased by a level of subjectivity.',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'Automatic valuation',
            avm_tooltip: '',
            dvm: 'Remote valuation',
            dvm_tooltip: '',
            ovm: 'On-site valuation',
            ovm_tooltip: '',
            new_loan: 'New loan application',
            new_loan_tooltip: '',
            revaluation: 'Revaluation',
            revaluation_tooltip: '',
            classic: '',
            classic_tooltip: '',
            renovation_light: '',
            renovation_light_tooltip: '',
            renovation_full: '',
            renovation_full_tooltip: '',
            new_construction: '',
            new_construction_tooltip: '',
            house: 'House',
            house_tooltip: '',
            apartment: 'Apartment',
            apartment_tooltip: '',
            annex: 'Annex',
            annex_tooltip: '',
            attached: 'Attached',
            attached_tooltip: '',
            semi: 'Semi detached',
            semi_tooltip: '',
            detached: 'Detached',
            detached_tooltip: '',
            yes: 'Yes',
            yes_tooltip: '',
            no: 'No',
            no_tooltip: '',
            traditional: 'Traditional',
            traditional_tooltip: '',
            modern: 'Modern',
            modern_tooltip: '',
            contemporary: 'Contemporary',
            contemporary_tooltip: '',
            mediterranean: 'Mediterranean',
            mediterranean_tooltip: '',
            other: 'Other',
            other_tooltip: '',
            ground_floor: 'Ground floor',
            ground_floor_tooltip: '',
            '1': '1',
            '1_tooltip': '',
            '2': '2',
            '2_tooltip': '',
            '3': '3',
            '3_tooltip': '',
            '4': '4',
            '4_tooltip': '',
            '>4': '>4',
            '>4_tooltip': '',
            private: 'Private garden',
            private_tooltip: '',
            common: 'Common garden',
            common_tooltip: '',
            'private+common': 'Private and common garden',
            'private+common_tooltip': '',
            maintenance_required: 'Maintenance required',
            maintenance_required_tooltip: '',
            good: 'Good',
            good_tooltip: '',
            very_good: 'Very good',
            very_good_tooltip: '',
            cinder_blocks: 'Cinder blocks',
            cinder_blocks_tooltip: '',
            wood: 'Wood',
            wood_tooltip: '',
            plaster: 'Plaster',
            plaster_tooltip: '',
            natural_stone: 'Natural stone',
            natural_stone_tooltip: '',
            metal: 'Metal',
            metal_tooltip: '',
            concrete: 'Concrete',
            concrete_tooltip: '',
            brick: 'Brick',
            brick_tooltip: '',
            tiles: 'Tiles',
            tiles_tooltip: '',
            bitumen_roofing: 'Bitumen roofing',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'Slate shingles',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'Black concrete tiles',
            black_concrete_tiles_tooltip: '',
            thatched: 'Thatched roof',
            thatched_tooltip: '',
            asbestos: 'Asbestos',
            asbestos_tooltip: '',
            home_liberal_profession_or_office: 'Home, liberal profession or office',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'Other professional activity',
            home_other_professional_activity_tooltip: '',
            home: 'Home',
            home_tooltip: '',
            no_economic_activity: 'No economic activity',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'Activity unlikely',
            activity_unlikely_tooltip: '',
            activity_possible: 'Activity possible',
            activity_possible_tooltip: '',
            activity_likely: 'Activity likely',
            activity_likely_tooltip: '',
            activity_very_likely: 'Activity very likely',
            activity_very_likely_tooltip: '',
            n: 'N',
            n_tooltip: '',
            ne: 'NE',
            ne_tooltip: '',
            e: 'E',
            e_tooltip: '',
            se: 'SE',
            se_tooltip: '',
            s: 'S',
            s_tooltip: '',
            sw: 'SW',
            sw_tooltip: '',
            w: 'W',
            w_tooltip: '',
            nw: 'NW',
            nw_tooltip: '',
            limited: 'Limited',
            limited_tooltip: '',
            details_previous_page: '(details on previous page)',
            details_previous_page_tooltip: '',
            low: 'Low',
            low_tooltip: '',
            medium: 'Medium',
            medium_tooltip: '',
            high: 'High',
            high_tooltip: '',
            unknown: 'Unknown',
            unknown_tooltip: '',
            city: 'City',
            city_tooltip: '',
            suburbs: 'Suburbs',
            suburbs_tooltip: '',
            village: 'Village',
            village_tooltip: '',
            isolated: 'Isolated',
            isolated_tooltip: '',
            none: 'None',
            none_tooltip: '',
            classified_facade: 'Classified facade',
            classified_facade_tooltip: '',
            green_neighbourhood: 'Green neighbourhood',
            green_neighbourhood_tooltip: '',
            sea_view: 'Sea view',
            sea_view_tooltip: '',
            no_direct_traffic: 'No direct traffic',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'Exceptional view',
            exceptional_view_tooltip: '',
            tram: 'Tram',
            tram_tooltip: '',
            train: 'Train',
            train_tooltip: '',
            metro: 'Metro',
            metro_tooltip: '',
            traffic: 'Traffic',
            traffic_tooltip: '',
            busy_area: 'Busy area',
            busy_area_tooltip: '',
            abex: 'ABEX',
            abex_tooltip: '',
        },
        request: {
            references: 'References',
            references_tooltip: '',
            valuation_request_ref: 'Valuation reference',
            valuation_request_ref_tooltip: '',
            customer_ref: 'Internal reference',
            customer_ref_tooltip:
                'This allows to link the valuation request with the loan.',
            bank: 'Bank',
            bank_tooltip: '',
            customer: 'Customer',
            customer_tooltip: '',
            package: '',
            package_tooltip: '',
        },
        footer: {
            text: 'For more information, check out {url.rockestate_main}',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            rockestate_main_tooltip: '',
            dashboard: '/valuation/default/dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'https://www.rock.estate/',
            rockestate_info_tooltip: '',
            valuation_app: '/valuation/default/',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'Exported by',
            by_tooltip: '',
            date: 'Date',
            date_tooltip: '',
            reference: 'Reference',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            reconstruction_value_tooltip: '',
            address_location: 'Address and location',
            address_location_tooltip: '',
            secondary_buildings: 'Secondary buildings',
            secondary_buildings_tooltip: '',
            economic_activity: 'Economic activity',
            economic_activity_tooltip: '',
            roof: 'Roof',
            roof_tooltip: '',
            warnings: 'Warnings',
            warnings_tooltip: '',
            renovation: 'Planned renovation',
            renovation_tooltip: '',
            general_info: 'General info',
            general_info_tooltip: '',
            parcels_and_buildings: 'Parcels and buildings',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'Land',
            c_parcels_tooltip: '',
            c_building: 'Main building',
            c_building_tooltip: '',
            building: 'Building',
            building_tooltip: '',
            building_structure: 'Building structure',
            building_structure_tooltip: '',
            building_purpose_classification: 'Building purpose & classification',
            building_purpose_classification_tooltip: '',
            parcels: 'Parcel(s)',
            parcels_tooltip: '',
            surroundings: 'Surroundings',
            surroundings_tooltip: '',
            valuations: 'Valuations',
            valuations_tooltip: '',
            reference_properties_location: 'Reference properties: location',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics: 'Reference properties: characteristics',
            reference_properties_characteristics_tooltip: '',
            flood: 'Flood zones',
            flood_tooltip: '',
            flood_simple: 'Flood zones',
            flood_simple_tooltip: '',
            faq: 'Frequently asked questions',
            faq_tooltip: '',
            questions_feedback: 'Questions and feedback',
            questions_feedback_tooltip: '',
            contact_info: 'Contact information',
            contact_info_tooltip: '',
            disclaimer: 'Disclaimer',
            disclaimer_tooltip: '',
        },
        front: {
            title: 'Property valuation report',
            title_tooltip: '',
            core_title: 'Property report',
            core_title_tooltip: '',
        },
        mortgage: {
            purpose: 'Valuation purpose',
            purpose_tooltip: '',
        },
        features: {
            renovation_cost: 'Renovation cost (excl VAT)',
            renovation_cost_tooltip: '',
            f_building_type: 'Building type',
            f_building_type_tooltip:
                'Is the Borrower financing a single-family home or an apartment?',
            f_number_of_facades: 'Number of facades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'Total parcel area',
            f_parcel_area_tooltip: '',
            f_building_area: 'Building footprint area',
            f_building_area_tooltip: '',
            f_x_annexes: 'Secondary buildings',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'Total area of secondary buildings',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'Largest secondary building',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'Cadastral references',
            parcel_ids_tooltip: '',
            f_lng: 'Longitude',
            f_lng_tooltip: '',
            f_lat: 'Latitude',
            f_lat_tooltip: '',
            f_number_of_addresses: 'Number of addresses',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'Living area',
            f_living_area_tooltip:
                'Total living area of the home. This info can usually be found in the sales ad.',
            f_construction_year: 'Construction year',
            f_construction_year_tooltip:
                'The official year of construction. This does not take into account any renovation works that may have followed at a later point in time. This info can usually be found in the sales ad.',
            f_epc: 'EPC',
            f_epc_tooltip:
                'The Energy Performance Coefficient (EPC) is the energy score of a home. This info can usually be found in the sales ad.',
            f_epc_label: 'EPC',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'EPC',
            f_epc_numeric_tooltip: '',
            f_bedrooms: 'Number of bedrooms',
            f_bedrooms_tooltip:
                'Total number of rooms that could serve as bedroom. Kitchen, bathroom and living room should not be included.',
            f_floor: 'Floor',
            f_floor_tooltip:
                'The floor on which the apartment is located. If multiple, select the lowest.',
            f_bottom_floor: 'Bottom floor of the building',
            f_bottom_floor_tooltip:
                'The bottom floor of a building is either 0 or a negative number depending on the number of underground floors. \nAssign the value 0 if the building has no floor under the ground/earth level. \nAssign a negative value if the building has a basement/ underground garage/\u2026',
            f_top_floor: 'Top floor of the building',
            f_top_floor_tooltip:
                'The top floor of a building is the floor under the roof of the building.',
            f_floors: 'Floor(s) of the apartment',
            f_floors_tooltip:
                'After having set the bottom and top floors of the building, select the floor(s) the apartment is located on in the list generated.\nFor example: for a duplex located on floors 2 and 3, select both floors.',
            f_balcony_present: 'Terrace',
            f_balcony_present_tooltip:
                "If the apartment has at least one terrace with sufficient space to put a 4-person table you can answer 'Yes'.",
            f_garage_present: 'Garage',
            f_garage_present_tooltip:
                'The apartment can give access to one or multiple parking spaces. This includes both a simple parking spot as well as a garage box, inside or outside.',
            f_n_closed_garage: 'Number of closed garages',
            f_n_closed_garage_tooltip:
                'A closed garage is a box garage that can be indoor or outdoor. Enter the number of closed garages belonging to the assessed apartment.',
            f_n_parking_spot: 'Number of parking spots',
            f_n_parking_spot_tooltip:
                'A parking spot is an outdoor or indoor parking place that is delineated by surface markings and not walls (vs. closed garages).\nEnter the number of parking spots sold with the property assessed.',
            f_cellar_attic: 'Cellar and/or attic',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'Garden',
            f_garden_access_tooltip:
                'The apartment can have access to a private or common garden.',
            f_garden_common: 'Access to a common garden',
            f_garden_common_tooltip:
                'A common garden is only accessible to any tenants of the building where the property is assessed. If there is a common garden, does the apartment assessed give access to it? If it does, select yes.',
            f_garden_private: 'Access to a private garden',
            f_garden_private_tooltip:
                'A private garden is restricted to the tenants of the assessed apartment and is adjacent to the apartment. The difference between a garden and a terrace is that a garden has grass.',
            f_volume: 'Main building volume',
            f_volume_tooltip: '',
            f_roof_area: 'Roof area',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'Mean roof angle',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'Flat roof proportion',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'Minimum roof height',
            f_min_height_tooltip: '',
            f_max_height: 'Maximum roof height',
            f_max_height_tooltip: '',
            f_n_parts: 'Parts of the main building',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'Roof panes',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'Known entities at this address',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'Economic activity class',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'Economic activity level',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'Garden area',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'Distance to middle street',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'Nearest flood zone',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'Nearest flood zone\n(insurance exclusion)',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'Flood risk',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'Flood risk\n(insurance exclusion)',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'Composite flood risk',
            f_flood_risk_composite_tooltip: '',
            level: 'Level of information',
            level_tooltip: '',
            f_ground_height_above_sea: 'Altitude (above sea level)',
            f_ground_height_above_sea_tooltip: '',
        },
        disclaimer: {
            avm: '',
            avm_tooltip: '',
            dvm:
                'This valuation report is the result of a remote assessment of the property by a real estate valuer. The valuer did not visit the property but relied on various data sources and a statistical model to make an independent valuation of the property. For more information regarding this valuation approach, please refer to the final page of this report.',
            dvm_tooltip: '',
            ovm:
                'This valuation report is the result of an on-site assessment of the property by a real estate expert. The independent valuation has been based on the property situation observed by the expert, and is further supported by a statistical model. For more information regarding this valuation approach, please refer to the final page of this report.',
            ovm_tooltip: '',
            part_1:
                'This valuation has been completed for the purpose expressed herein, no responsibility is accepted in the event of this report being used for any other purpose or by any other party than to whom it is addressed. Should any other party wish to use or rely upon this report for any purpose they must first obtain our written consent.\n',
            part_1_tooltip: '',
            part_2:
                'The publication of this report, in whole or in part, is prohibited without the written approval of Rockestate. The copyright of this report at all times remains the property of Rockestate. This report may not be assigned to a third party without the express consent of the owner of the copyright.',
            part_2_tooltip: '',
            reconstruction_value_title: 'Reconstruction value computation',
            reconstruction_value_title_tooltip: '',
            reconstruction_value:
                'Reconstruction value is the total cost to reconstruct a building considering the current construction requirements. The calculation includes VAT and cost of an architect, is independent on the location, and does not include demolishment works nor secondary buildings. As a result, it is possible to have a reconstruction value \nhigher than the market value. The reconstruction value is based on an ABEX index of 847.',
            reconstruction_value_tooltip: '',
            part_3:
                'In making this valuation report we express no opinion as to, and accept no liability for, the ability and willingness of the proposed mortgagor to meet his/her/its commitments under the proposed loan.',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'data input by Customer',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'Building style',
            style_of_house_tooltip: '',
            exterior_state: 'Exterior state of the property',
            exterior_state_tooltip: '',
            facade_material: 'Facade material',
            facade_material_tooltip: '',
            roof_material: 'Roof material',
            roof_material_tooltip: '',
            n_roof_windows: 'Number of roof windows',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'Number of dormers',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'Solar panel area',
            solar_panel_area_tooltip: '',
            f_building_listed: 'Listed for historical purposes',
            f_building_listed_tooltip: '',
            f_building_protected: 'Classification',
            f_building_protected_tooltip: '',
            garden_orientation: 'Orientation of garden',
            garden_orientation_tooltip: '',
            garden_arrangement: '(Potential of) garden arrangement',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'Exterior swimming pool',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'Exterior swimming pool size',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'Level of direct street traffic',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'Proximity of indirect street traffic',
            indirect_traffic_level_tooltip: '',
            surroundings: 'Description of the surroundings',
            surroundings_tooltip: '',
            other_positive: 'Other elements positively impacting the property valuation',
            other_positive_tooltip: '',
            other_negative: 'Other elements negatively impacting the property valuation',
            other_negative_tooltip: '',
            remarks: 'Remarks of the valuer',
            remarks_tooltip: '',
            internal_remarks: 'Internal remarks',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': 'Economic activity',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'Parcel and building shapes',
            parcels_and_building_shapes_tooltip: '',
        },
        reference_properties_location: {
            explanation_1:
                'The valuation of this property relies on 200 reference properties which have been selected in the property\u2019s neighbourhood. Each reference property shares a number of similarities in terms of location, size and other characteristics with the property under valuation. The underlying statistical model of this valuation is based on a Geographically Weighted Regression (GWR) where reference properties in the immediate proximity of the valued property receive a larger weighting compared to reference properties which are located further away.',
            explanation_1_tooltip: '',
            explanation_2:
                'The map below gives on overview of the location of the reference properties and their respective distance versus the valued property. The color gradient on the map gives a good indication of the average market value of properties in the area.',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1:
                'A property has various objective characteristics that contribute to its price, such as location, size and energy efficiency. The charts below compare some of these characteristics of this property with those of the reference properties.',
            explanation_1_tooltip: '',
            property_characteristics: 'Property characteristics',
            property_characteristics_tooltip: '',
            properties_in_the_area: 'Properties in the area',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1:
                'Overview of the official flood zones in the proximity of the property. Several categories of flood risk are calculated according to different flood risk scenarios, varying from Low to High.',
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation:
                'The address of the valued property is incorrect, what should I do?',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation:
                'It seems that something must have gone wrong when selecting the address, the building or the parcels related to the property. You can review the address information via the following link: {url.dashboard}.',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics:
                'The address of the valued property is correct, but some of the other characteristics in this valuation report are incorrect, what should I do?',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics:
                'If you believe that some information  in this report may substantially differ from the actual situation, which could lead to a material impact on the valuation of the property, follow this link {url.dashboard} to review and clarify the incorrect/missing information.',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value:
                'Why is the transaction price I paid different from the market value determined in this report?',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm:
                'This valuation report is the result of the remote assessment of the property in question by a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did not actually visit the property, it can happen that the impact of certain, specific, property characteristics has not been fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm:
                'This valuation report is the result of the assessment of a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did actually visit the property, the impact of certain, specific, property characteristics should be fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted:
                'Now that I have received my valuation report, does that mean my loan application was accepted?',
            q_loan_accepted_tooltip: '',
            a_loan_accepted:
                'We have shared this valuation report with you and your bank. This valuation report will be used by the bank as one of the elements in its credit risk approval process. The final decision remains theirs. Rockestate is not involved in any way in this decision.',
            a_loan_accepted_tooltip: '',
        },
        questions_feedback: {
            explanation_1:
                'Do you have a specific question or comment regarding this report? Or are you just looking for some more information on how Rockestate makes these valuation?',
            explanation_1_tooltip: '',
            explanation_2: 'Please visit our valuation portal: {url.valuation_app}.',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'Your valuation request has been submitted',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1:
                'Dear Sir, Madam,\n\nYou have successfully submitted a new valuation request with the following references:',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2:
                "Our valuation specialist team will be treating your request shortly. In the meantime, you can follow the progress of your request in your dashboard.\n\nKind regards,\nRockestate's valuation specialist team",
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'Your valuation request has been processed',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1:
                'Dear Sir, Madam,\n\nThe following request has been successfully reviewed and valued by our experts:',
            valuation_notification_1_tooltip: '',
            valuation_notification_2:
                "Kindly login to your dashboard to download the report.\n\nKind regards,\nRockestate's valuation specialist team",
            valuation_notification_2_tooltip: '',
            incoherent_features_subject: 'Your valuation request could not be processed',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1:
                'Dear Sir, Madam,\n\nThe valuation request you submitted (see references below) can not be further processed due to incorrect information:',
            incoherent_features_1_tooltip: '',
            incoherent_features_2:
                'Our valuation specialist noticed for this request that the following characteristics might be incorrect: ',
            incoherent_features_2_tooltip: '',
            incoherent_features_3:
                "Please ensure that the information is correct and adjust it in your dashboard.\nAs soon as everything is filled in, kindly submit again your request and our valuation specialist will complete the valuation. \n\nKind regards,\nRockestate's valuation specialist team",
            incoherent_features_3_tooltip: '',
            on_site_requested_value:
                "Dear Sir, Madam, \n\nAfter review from our valuation specialist, the valuation request you submitted (see references below) can not be completed : \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nDue to the uniqueness of this property and the contrast with other properties in the neighborhood, we suggest to send an on-site expert.\n\nKind regards,\n\nRockestate's valuation specialist team",
            on_site_requested_value_tooltip: '',
            on_site_requested_characteristics_subject:
                'An on-site valuation is recommended',
            on_site_requested_characteristics_subject_tooltip: '',
            on_site_requested_characteristics_1:
                'Dear Sir, Madam,\n\nAfter review from our valuation specialist, the valuation request you submitted (see references below) can not be completed :',
            on_site_requested_characteristics_1_tooltip: '',
            on_site_requested_characteristics_2:
                "Due to some characteristics (i.e: exclusive renovation, historical listed/protected building, Property not visible from the street), we suggest to send an on-site expert.\n\nKind regards, \nRockestate's valuation specialist team",
            on_site_requested_characteristics_2_tooltip: '',
            address_not_found:
                "Dear Sir, Madam,\n\nYou requested a valuation through our tool, however, there seems to be a problem regarding the address of the property. \n\nCould you please review the request for the property with the following references: \n- LOP ID: [LOP ID]\n- Valuation ID: [Valuation ID]\n- Address: [Address]\n\nWe could not find this address in our internal tools neither on internet. \nIf the address is incorrect, kindly adjust it directly in your dashboard.\n\nWe need all the information to be filled and fully completed to give [BANK] a correct estimated value of this property. As soon as the address is confirmed, our valuation specialist will continue with the valuation. \n\nKind regards,\n\nRockestate's valuation specialist team",
            address_not_found_tooltip: '',
        },
    },
    technical: {
        unit: {
            m: 'unit.m',
            m_tooltip: '',
            m2: 'unit.m2',
            m2_tooltip: '',
            m3: 'unit.m3',
            m3_tooltip: '',
            'kwh/m2year': 'unit.kwh/m2year',
            'kwh/m2year_tooltip': '',
            euro: 'unit.euro',
            euro_tooltip: '',
            'euro/month': 'unit.euro/month',
            'euro/month_tooltip': '',
        },
        address_text: {
            full_address: 'address_text.full_address',
            full_address_tooltip: 'address_text.full_address_tooltip',
        },
        valuation: {
            date: 'valuation.date',
            date_tooltip: '',
            type: 'valuation.type',
            type_tooltip: '',
            valuer: 'valuation.valuer',
            valuer_tooltip: '',
            monitor: 'valuation.monitor',
            monitor_tooltip: '',
            market_value: 'valuation.market_value',
            market_value_tooltip: '',
            forced_sale_value: 'valuation.forced_sale_value',
            forced_sale_value_tooltip: '',
            rental_value: 'valuation.rental_value',
            rental_value_tooltip: '',
            reconstruction_value: 'valuation.reconstruction_value',
            reconstruction_value_tooltip: '',
            index_value: 'valuation.index_value',
            index_value_tooltip: '',
            index_type: 'valuation.index_type',
            index_type_tooltip: '',
            explanation_1: 'valuation.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'valuation.explanation_2',
            explanation_2_tooltip: '',
            explanation_3: 'valuation.explanation_3',
            explanation_3_tooltip: '',
            explanation_4: 'valuation.explanation_4',
            explanation_4_tooltip: '',
            explanation_5: 'valuation.explanation_5',
            explanation_5_tooltip: '',
            explanation_6: 'valuation.explanation_6',
            explanation_6_tooltip: '',
        },
        value: {
            avm: 'value.avm',
            avm_tooltip: '',
            dvm: 'value.dvm',
            dvm_tooltip: '',
            ovm: 'value.ovm',
            ovm_tooltip: '',
            new_loan: 'value.new_loan',
            new_loan_tooltip: '',
            revaluation: 'value.revaluation',
            revaluation_tooltip: '',
            classic: 'value.classic',
            classic_tooltip: '',
            renovation_light: 'value.renovation_light',
            renovation_light_tooltip: '',
            renovation_full: 'value.renovation_full',
            renovation_full_tooltip: '',
            new_construction: 'value.new_construction',
            new_construction_tooltip: '',
            house: 'value.house',
            house_tooltip: '',
            apartment: 'value.apartment',
            apartment_tooltip: '',
            annex: 'value.annex',
            annex_tooltip: '',
            attached: 'value.attached',
            attached_tooltip: '',
            semi: 'value.semi',
            semi_tooltip: '',
            detached: 'value.detached',
            detached_tooltip: '',
            yes: 'value.yes',
            yes_tooltip: '',
            no: 'value.no',
            no_tooltip: '',
            traditional: 'value.traditional',
            traditional_tooltip: '',
            modern: 'value.modern',
            modern_tooltip: '',
            contemporary: 'value.contemporary',
            contemporary_tooltip: '',
            mediterranean: 'value.mediterranean',
            mediterranean_tooltip: '',
            other: 'value.other',
            other_tooltip: '',
            ground_floor: 'value.ground_floor',
            ground_floor_tooltip: '',
            '1': 'value.1',
            '1_tooltip': '',
            '2': 'value.2',
            '2_tooltip': '',
            '3': 'value.3',
            '3_tooltip': '',
            '4': 'value.4',
            '4_tooltip': '',
            '>4': 'value.>4',
            '>4_tooltip': '',
            private: 'value.private',
            private_tooltip: '',
            common: 'value.common',
            common_tooltip: '',
            'private+common': 'value.private+common',
            'private+common_tooltip': '',
            maintenance_required: 'value.maintenance_required',
            maintenance_required_tooltip: '',
            good: 'value.good',
            good_tooltip: '',
            very_good: 'value.very_good',
            very_good_tooltip: '',
            cinder_blocks: 'value.cinder_blocks',
            cinder_blocks_tooltip: '',
            wood: 'value.wood',
            wood_tooltip: '',
            plaster: 'value.plaster',
            plaster_tooltip: '',
            natural_stone: 'value.natural_stone',
            natural_stone_tooltip: '',
            metal: 'value.metal',
            metal_tooltip: '',
            concrete: 'value.concrete',
            concrete_tooltip: '',
            brick: 'value.brick',
            brick_tooltip: '',
            tiles: 'value.tiles',
            tiles_tooltip: '',
            bitumen_roofing: 'value.bitumen_roofing',
            bitumen_roofing_tooltip: '',
            slate_shingles: 'value.slate_shingles',
            slate_shingles_tooltip: '',
            black_concrete_tiles: 'value.black_concrete_tiles',
            black_concrete_tiles_tooltip: '',
            thatched: 'value.thatched',
            thatched_tooltip: '',
            asbestos: 'value.asbestos',
            asbestos_tooltip: '',
            home_liberal_profession_or_office: 'value.home_liberal_profession_or_office',
            home_liberal_profession_or_office_tooltip: '',
            home_other_professional_activity: 'value.home_other_professional_activity',
            home_other_professional_activity_tooltip: '',
            home: 'value.home',
            home_tooltip: '',
            no_economic_activity: 'value.no_economic_activity',
            no_economic_activity_tooltip: '',
            activity_unlikely: 'value.activity_unlikely',
            activity_unlikely_tooltip: '',
            activity_possible: 'value.activity_possible',
            activity_possible_tooltip: '',
            activity_likely: 'value.activity_likely',
            activity_likely_tooltip: '',
            activity_very_likely: 'value.activity_very_likely',
            activity_very_likely_tooltip: '',
            n: 'value.n',
            n_tooltip: '',
            ne: 'value.ne',
            ne_tooltip: '',
            e: 'value.e',
            e_tooltip: '',
            se: 'value.se',
            se_tooltip: '',
            s: 'value.s',
            s_tooltip: '',
            sw: 'value.sw',
            sw_tooltip: '',
            w: 'value.w',
            w_tooltip: '',
            nw: 'value.nw',
            nw_tooltip: '',
            limited: 'value.limited',
            limited_tooltip: '',
            details_previous_page: 'value.details_previous_page',
            details_previous_page_tooltip: '',
            low: 'value.low',
            low_tooltip: '',
            medium: 'value.medium',
            medium_tooltip: '',
            high: 'value.high',
            high_tooltip: '',
            unknown: 'value.unknown',
            unknown_tooltip: '',
            city: 'value.city',
            city_tooltip: '',
            suburbs: 'value.suburbs',
            suburbs_tooltip: '',
            village: 'value.village',
            village_tooltip: '',
            isolated: 'value.isolated',
            isolated_tooltip: '',
            none: 'value.none',
            none_tooltip: '',
            classified_facade: 'value.classified_facade',
            classified_facade_tooltip: '',
            green_neighbourhood: 'value.green_neighbourhood',
            green_neighbourhood_tooltip: '',
            sea_view: 'value.sea_view',
            sea_view_tooltip: '',
            no_direct_traffic: 'value.no_direct_traffic',
            no_direct_traffic_tooltip: '',
            exceptional_view: 'value.exceptional_view',
            exceptional_view_tooltip: '',
            tram: 'value.tram',
            tram_tooltip: '',
            train: 'value.train',
            train_tooltip: '',
            metro: 'value.metro',
            metro_tooltip: '',
            traffic: 'value.traffic',
            traffic_tooltip: '',
            busy_area: 'value.busy_area',
            busy_area_tooltip: '',
            abex: 'value.abex',
            abex_tooltip: '',
        },
        request: {
            references: 'request.references',
            references_tooltip: '',
            valuation_request_ref: 'request.valuation_request_ref',
            valuation_request_ref_tooltip: '',
            customer_ref: 'request.customer_ref',
            customer_ref_tooltip: 'request.customer_ref_tooltip',
            bank: 'request.bank',
            bank_tooltip: '',
            customer: 'request.customer',
            customer_tooltip: '',
            package: 'request.package',
            package_tooltip: '',
        },
        footer: {
            text: 'footer.text',
            text_tooltip: '',
        },
        url: {
            rockestate_main: 'url.rockestate_main',
            rockestate_main_tooltip: '',
            dashboard: 'url.dashboard',
            dashboard_tooltip: '',
            rockestate_info: 'url.rockestate_info',
            rockestate_info_tooltip: '',
            valuation_app: 'url.valuation_app',
            valuation_app_tooltip: '',
        },
        export: {
            by: 'export.by',
            by_tooltip: '',
            date: 'export.date',
            date_tooltip: '',
            reference: 'export.reference',
            reference_tooltip: '',
        },
        section: {
            reconstruction_value: 'section.reconstruction_value',
            reconstruction_value_tooltip: '',
            address_location: 'section.address_location',
            address_location_tooltip: '',
            secondary_buildings: 'section.secondary_buildings',
            secondary_buildings_tooltip: '',
            economic_activity: 'section.economic_activity',
            economic_activity_tooltip: '',
            roof: 'section.roof',
            roof_tooltip: '',
            warnings: 'section.warnings',
            warnings_tooltip: '',
            renovation: 'section.renovation',
            renovation_tooltip: '',
            general_info: 'section.general_info',
            general_info_tooltip: '',
            parcels_and_buildings: 'section.parcels_and_buildings',
            parcels_and_buildings_tooltip: '',
            c_parcels: 'section.c_parcels',
            c_parcels_tooltip: '',
            c_building: 'section.c_building',
            c_building_tooltip: '',
            building: 'section.building',
            building_tooltip: '',
            building_structure: 'section.building_structure',
            building_structure_tooltip: '',
            building_purpose_classification: 'section.building_purpose_classification',
            building_purpose_classification_tooltip: '',
            parcels: 'section.parcels',
            parcels_tooltip: '',
            surroundings: 'section.surroundings',
            surroundings_tooltip: '',
            valuations: 'section.valuations',
            valuations_tooltip: '',
            reference_properties_location: 'section.reference_properties_location',
            reference_properties_location_tooltip: '',
            reference_properties_characteristics:
                'section.reference_properties_characteristics',
            reference_properties_characteristics_tooltip: '',
            flood: 'section.flood',
            flood_tooltip: '',
            flood_simple: 'section.flood_simple',
            flood_simple_tooltip: '',
            faq: 'section.faq',
            faq_tooltip: '',
            questions_feedback: 'section.questions_feedback',
            questions_feedback_tooltip: '',
            contact_info: 'section.contact_info',
            contact_info_tooltip: '',
            disclaimer: 'section.disclaimer',
            disclaimer_tooltip: '',
        },
        front: {
            title: 'front.title',
            title_tooltip: '',
            core_title: 'front.core_title',
            core_title_tooltip: '',
        },
        mortgage: {
            purpose: 'mortgage.purpose',
            purpose_tooltip: '',
        },
        features: {
            renovation_cost: 'features.renovation_cost',
            renovation_cost_tooltip: '',
            f_building_type: 'features.f_building_type',
            f_building_type_tooltip: 'features.f_building_type_tooltip',
            f_number_of_facades: 'features.f_number_of_facades',
            f_number_of_facades_tooltip: '',
            f_parcel_area: 'features.f_parcel_area',
            f_parcel_area_tooltip: '',
            f_building_area: 'features.f_building_area',
            f_building_area_tooltip: '',
            f_x_annexes: 'features.f_x_annexes',
            f_x_annexes_tooltip: '',
            f_annexes_area: 'features.f_annexes_area',
            f_annexes_area_tooltip: '',
            f_area_largest_annex: 'features.f_area_largest_annex',
            f_area_largest_annex_tooltip: '',
            parcel_ids: 'features.parcel_ids',
            parcel_ids_tooltip: '',
            f_lng: 'features.f_lng',
            f_lng_tooltip: '',
            f_lat: 'features.f_lat',
            f_lat_tooltip: '',
            f_number_of_addresses: 'features.f_number_of_addresses',
            f_number_of_addresses_tooltip: '',
            f_living_area: 'features.f_living_area',
            f_living_area_tooltip: 'features.f_living_area_tooltip',
            f_construction_year: 'features.f_construction_year',
            f_construction_year_tooltip: 'features.f_construction_year_tooltip',
            f_epc: 'features.f_epc',
            f_epc_tooltip: 'features.f_epc_tooltip',
            f_epc_label: 'features.f_epc_label',
            f_epc_label_tooltip: '',
            f_epc_numeric: 'features.f_epc_numeric',
            f_epc_numeric_tooltip: '',
            f_bedrooms: 'features.f_bedrooms',
            f_bedrooms_tooltip: 'features.f_bedrooms_tooltip',
            f_floor: 'features.f_floor',
            f_floor_tooltip: 'features.f_floor_tooltip',
            f_bottom_floor: 'features.f_bottom_floor',
            f_bottom_floor_tooltip: 'features.f_bottom_floor_tooltip',
            f_top_floor: 'features.f_top_floor',
            f_top_floor_tooltip: 'features.f_top_floor_tooltip',
            f_floors: 'features.f_floors',
            f_floors_tooltip: 'features.f_floors_tooltip',
            f_balcony_present: 'features.f_balcony_present',
            f_balcony_present_tooltip: 'features.f_balcony_present_tooltip',
            f_garage_present: 'features.f_garage_present',
            f_garage_present_tooltip: 'features.f_garage_present_tooltip',
            f_n_closed_garage: 'features.f_n_closed_garage',
            f_n_closed_garage_tooltip: 'features.f_n_closed_garage_tooltip',
            f_n_parking_spot: 'features.f_n_parking_spot',
            f_n_parking_spot_tooltip: 'features.f_n_parking_spot_tooltip',
            f_cellar_attic: 'features.f_cellar_attic',
            f_cellar_attic_tooltip: '',
            f_garden_access: 'features.f_garden_access',
            f_garden_access_tooltip: 'features.f_garden_access_tooltip',
            f_garden_common: 'features.f_garden_common',
            f_garden_common_tooltip: 'features.f_garden_common_tooltip',
            f_garden_private: 'features.f_garden_private',
            f_garden_private_tooltip: 'features.f_garden_private_tooltip',
            f_volume: 'features.f_volume',
            f_volume_tooltip: '',
            f_roof_area: 'features.f_roof_area',
            f_roof_area_tooltip: '',
            f_mean_tilt: 'features.f_mean_tilt',
            f_mean_tilt_tooltip: '',
            f_percent_of_roof_flat: 'features.f_percent_of_roof_flat',
            f_percent_of_roof_flat_tooltip: '',
            f_min_height: 'features.f_min_height',
            f_min_height_tooltip: '',
            f_max_height: 'features.f_max_height',
            f_max_height_tooltip: '',
            f_n_parts: 'features.f_n_parts',
            f_n_parts_tooltip: '',
            f_n_roof_panes: 'features.f_n_roof_panes',
            f_n_roof_panes_tooltip: '',
            f_x_matched_entities: 'features.f_x_matched_entities',
            f_x_matched_entities_tooltip: '',
            c_economic_activity_class: 'features.c_economic_activity_class',
            c_economic_activity_class_tooltip: '',
            c_economic_activity_level: 'features.c_economic_activity_level',
            c_economic_activity_level_tooltip: '',
            f_garden_area: 'features.f_garden_area',
            f_garden_area_tooltip: '',
            f_distance_to_street: 'features.f_distance_to_street',
            f_distance_to_street_tooltip: '',
            f_dist_building_flood: 'features.f_dist_building_flood',
            f_dist_building_flood_tooltip: '',
            f_dist_building_flood_insurance_exclusion:
                'features.f_dist_building_flood_insurance_exclusion',
            f_dist_building_flood_insurance_exclusion_tooltip: '',
            f_flood_risk: 'features.f_flood_risk',
            f_flood_risk_tooltip: '',
            f_flood_risk_insurance_exclusion: 'features.f_flood_risk_insurance_exclusion',
            f_flood_risk_insurance_exclusion_tooltip: '',
            f_flood_risk_composite: 'features.f_flood_risk_composite',
            f_flood_risk_composite_tooltip: '',
            level: 'features.level',
            level_tooltip: '',
            f_ground_height_above_sea: 'features.f_ground_height_above_sea',
            f_ground_height_above_sea_tooltip: '',
        },
        disclaimer: {
            avm: 'disclaimer.avm',
            avm_tooltip: '',
            dvm: 'disclaimer.dvm',
            dvm_tooltip: '',
            ovm: 'disclaimer.ovm',
            ovm_tooltip: '',
            part_1: 'disclaimer.part_1',
            part_1_tooltip: '',
            part_2: 'disclaimer.part_2',
            part_2_tooltip: '',
            reconstruction_value_title: 'disclaimer.reconstruction_value_title',
            reconstruction_value_title_tooltip: '',
            reconstruction_value: 'disclaimer.reconstruction_value',
            reconstruction_value_tooltip: '',
            part_3: 'disclaimer.part_3',
            part_3_tooltip: '',
        },
        info: {
            data_from_customer: 'info.data_from_customer',
            data_from_customer_tooltip: '',
        },
        dvm_features: {
            style_of_house: 'dvm_features.style_of_house',
            style_of_house_tooltip: '',
            exterior_state: 'dvm_features.exterior_state',
            exterior_state_tooltip: '',
            facade_material: 'dvm_features.facade_material',
            facade_material_tooltip: '',
            roof_material: 'dvm_features.roof_material',
            roof_material_tooltip: '',
            n_roof_windows: 'dvm_features.n_roof_windows',
            n_roof_windows_tooltip: '',
            n_roof_dormers: 'dvm_features.n_roof_dormers',
            n_roof_dormers_tooltip: '',
            solar_panel_area: 'dvm_features.solar_panel_area',
            solar_panel_area_tooltip: '',
            f_building_listed: 'dvm_features.f_building_listed',
            f_building_listed_tooltip: '',
            f_building_protected: 'dvm_features.f_building_protected',
            f_building_protected_tooltip: '',
            garden_orientation: 'dvm_features.garden_orientation',
            garden_orientation_tooltip: '',
            garden_arrangement: 'dvm_features.garden_arrangement',
            garden_arrangement_tooltip: '',
            f_swimming_pool: 'dvm_features.f_swimming_pool',
            f_swimming_pool_tooltip: '',
            f_swimming_pool_area: 'dvm_features.f_swimming_pool_area',
            f_swimming_pool_area_tooltip: '',
            direct_traffic_level: 'dvm_features.direct_traffic_level',
            direct_traffic_level_tooltip: '',
            indirect_traffic_level: 'dvm_features.indirect_traffic_level',
            indirect_traffic_level_tooltip: '',
            surroundings: 'dvm_features.surroundings',
            surroundings_tooltip: '',
            other_positive: 'dvm_features.other_positive',
            other_positive_tooltip: '',
            other_negative: 'dvm_features.other_negative',
            other_negative_tooltip: '',
            remarks: 'dvm_features.remarks',
            remarks_tooltip: '',
            internal_remarks: 'dvm_features.internal_remarks',
            internal_remarks_tooltip: '',
        },
        '': {
            'clarify here': '.clarify here',
            'clarify here_tooltip': '',
        },
        fixed: {
            parcels_and_building_shapes: 'fixed.parcels_and_building_shapes',
            parcels_and_building_shapes_tooltip: '',
        },
        reference_properties_location: {
            explanation_1: 'reference_properties_location.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'reference_properties_location.explanation_2',
            explanation_2_tooltip: '',
        },
        reference_properties_characteristics: {
            explanation_1: 'reference_properties_characteristics.explanation_1',
            explanation_1_tooltip: '',
            property_characteristics:
                'reference_properties_characteristics.property_characteristics',
            property_characteristics_tooltip: '',
            properties_in_the_area:
                'reference_properties_characteristics.properties_in_the_area',
            properties_in_the_area_tooltip: '',
        },
        flood: {
            explanation_1: 'flood.explanation_1',
            explanation_1_tooltip: '',
        },
        faq: {
            q_incorrect_valuation: 'faq.q_incorrect_valuation',
            q_incorrect_valuation_tooltip: '',
            a_incorrect_valuation: 'faq.a_incorrect_valuation',
            a_incorrect_valuation_tooltip: '',
            q_incorrect_characteristics: 'faq.q_incorrect_characteristics',
            q_incorrect_characteristics_tooltip: '',
            a_incorrect_characteristics: 'faq.a_incorrect_characteristics',
            a_incorrect_characteristics_tooltip: '',
            q_transaction_vs_market_value: 'faq.q_transaction_vs_market_value',
            q_transaction_vs_market_value_tooltip: '',
            a_transaction_vs_market_value_dvm: 'faq.a_transaction_vs_market_value_dvm',
            a_transaction_vs_market_value_dvm_tooltip: '',
            a_transaction_vs_market_value_ovm: 'faq.a_transaction_vs_market_value_ovm',
            a_transaction_vs_market_value_ovm_tooltip: '',
            q_loan_accepted: 'faq.q_loan_accepted',
            q_loan_accepted_tooltip: '',
            a_loan_accepted: 'faq.a_loan_accepted',
            a_loan_accepted_tooltip: '',
        },
        questions_feedback: {
            explanation_1: 'questions_feedback.explanation_1',
            explanation_1_tooltip: '',
            explanation_2: 'questions_feedback.explanation_2',
            explanation_2_tooltip: '',
        },
        email: {
            submission_confirmation_subject: 'email.submission_confirmation_subject',
            submission_confirmation_subject_tooltip: '',
            submission_confirmation_1: 'email.submission_confirmation_1',
            submission_confirmation_1_tooltip: '',
            submission_confirmation_2: 'email.submission_confirmation_2',
            submission_confirmation_2_tooltip: '',
            valuation_notification_subject: 'email.valuation_notification_subject',
            valuation_notification_subject_tooltip: '',
            valuation_notification_1: 'email.valuation_notification_1',
            valuation_notification_1_tooltip: '',
            valuation_notification_2: 'email.valuation_notification_2',
            valuation_notification_2_tooltip: '',
            incoherent_features_subject: 'email.incoherent_features_subject',
            incoherent_features_subject_tooltip: '',
            incoherent_features_1: 'email.incoherent_features_1',
            incoherent_features_1_tooltip: '',
            incoherent_features_2: 'email.incoherent_features_2',
            incoherent_features_2_tooltip: '',
            incoherent_features_3: 'email.incoherent_features_3',
            incoherent_features_3_tooltip: '',
            on_site_requested_value: 'email.on_site_requested_value',
            on_site_requested_value_tooltip: '',
            on_site_requested_characteristics_subject:
                'email.on_site_requested_characteristics_subject',
            on_site_requested_characteristics_subject_tooltip: '',
            on_site_requested_characteristics_1:
                'email.on_site_requested_characteristics_1',
            on_site_requested_characteristics_1_tooltip: '',
            on_site_requested_characteristics_2:
                'email.on_site_requested_characteristics_2',
            on_site_requested_characteristics_2_tooltip: '',
            address_not_found: 'email.address_not_found',
            address_not_found_tooltip: '',
        },
    },
}
