import { Scatter } from 'vue-chartjs'

import utils from '@/shared/plugins/utils'
const quantile_formatter = utils.quantile_formatter

export default {
    extends: Scatter,
    props: ['ref_points', 'point_to_estimate'],
    created() {},
    mounted() {
        var line = [
            { x: this.point_to_estimate[0].x, y: 0 },
            { x: this.point_to_estimate[0].x, y: 6 },
        ]
        const lines = this.getQuarters(this.ref_points)
        const config = [
            {
                label: 'Valuation',
                data: this.point_to_estimate,
                pointRadius: 10,
                backgroundColor: '#ff6f00',
                borderColor: '#ff3100',
                borderWidth: 2,
                zIndex: 10,
                pointHoverRadius: 10,
            },
            {
                label: 'Reference Points',
                data: this.ref_points,
                backgroundColor: 'dodgerblue',
                borderColor: '#0080e3',
                pointRadius: 6,
                borderWidth: 2,
                pointHoverRadius: 6,
                tooltips: {},
            },
            {
                label: 'line',
                data: line,
                backgroundColor: '#ff3100',
                borderColor: '#ff3100',
                showLine: true,
                fill: false,
                pointRadius: 0,
                borderWidth: 2,
                zIndex: 10,
            },
        ]
        lines.forEach((el) => {
            config.push({
                label: 'line',
                data: el,
                backgroundColor: '#ff3100',
                borderColor: 'grey',
                showLine: true,
                fill: false,
                pointRadius: 0,
                borderWidth: 4,
                zIndex: 10,
            })
        })
        // Overwriting base render method with actual data.
        this.renderChart(
            {
                // This needs improvement, the data should be passed as props
                labels: 'label',
                datasets: config,
            },
            {
                tooltips: {
                    callbacks: {
                        label: (context) => {
                            if (context.datasetIndex == 1) {
                                return `${context.xLabel} (${quantile_formatter(
                                    context.index / this.ref_points.length
                                )})`
                            } else {
                                return context.xLabel
                            }
                        },
                        // title: function(tooltipItem, data) {
                        //   // return data['labels'][tooltipItem[0]['index']];
                        // },
                        // label: function(tooltipItem, data) {
                        //   // console.log(data['datasets'][1]['data'][tooltipItem['index']]);
                        //   // return data['datasets'][1]['data'][tooltipItem['index']].x;
                        // },
                        // afterLabel: function(tooltipItem, data) {
                        //   // var dataset = data['datasets'][0];
                        //   // var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                        //   // return '(' + percent + '%)';
                        // }
                    },
                },
                scales: {
                    yAxes: [
                        {
                            display: false,
                            //min:-3,
                            //max:3,
                        },
                    ],
                },
                maintainAspectRatio: false,
                legend: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
            }
        )
        let width = parseInt(document.getElementById('scatter-chart').style.width)
        this.$emit('input', {
            tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]._model.x /
                width) *
                100}%`,
        })
        window.addEventListener('resize', () => {
            // if(this.$data._chart.getDatasetMeta(0).data[0]) {
            let width = parseInt(document.getElementById('scatter-chart').style.width)
            this.$emit('input', {
                tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]._model
                    .x /
                    width) *
                    100}%`,
            })
            // }
        })
    },
    methods: {
        getQuarters(arr) {
            const checkArray = [0.25, 0.5, 0.75]
            const lines = []
            const sorted = arr.sort((a, b) => a.x - b.x)

            for (let i = 0; i < checkArray.length; i++) {
                const pos = (arr.length - 1) * checkArray[i]
                const base = Math.floor(pos)
                lines.push([
                    { x: sorted[base].x, y: 0 },
                    { x: sorted[base].x, y: -6 },
                ])
            }
            return lines
        },
    },
}
