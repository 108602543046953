import { Scatter } from 'vue-chartjs'

export default {
    extends: Scatter,
    props: ['ref_points', 'point_to_estimate', 'regression_line'],
    created() {},
    mounted() {
        // Overwriting base render method with actual data.
        this.renderChart(
            {
                // This needs improvement, the data should be passed as props
                // Language should be reactive
                labels: 'label',
                datasets: [
                    {
                        label: 'test1',
                        data: this.point_to_estimate,
                        pointRadius: 10,
                        showLine: false,
                        backgroundColor: '#ff6f00',
                        borderColor: '#ff3100',
                        borderWidth: 2,
                        zIndex: 10,
                        pointHoverRadius: 10,
                    },
                    {
                        label: 'test',
                        data: this.ref_points,
                        backgroundColor: 'dodgerblue',
                        borderColor: '#0080e3',
                        pointRadius: 4,
                        borderWidth: 2,
                        pointHoverRadius: 4,
                        showLine: false,
                    },
                    {
                        label: 'test',
                        data: this.regression_line,
                        backgroundColor: '#31b00b',
                        borderColor: '#31b00b',
                        showLine: true,
                        fill: false,
                        pointRadius: 0,
                        borderWidth: 3,
                    },
                ],
            },
            {
                scales: {
                    yAxes: [
                        {
                            type: 'logarithmic',
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: this.$t('price'),
                            },
                            ticks: {
                                min:
                                    Math.floor(this.regression_line[0].y / 10000 - 1) *
                                    10000,
                                callback: function(value, index, values) {
                                    return value.formatMoney(0, ',', '.') + '€'
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'logarithmic',
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: this.$t('surface'),
                            },
                            ticks: {
                                min: Math.floor(this.regression_line[0].x / 10 - 1) * 10,
                                callback: function(value, index, values) {
                                    return value.toString()
                                },
                            },
                        },
                    ],
                },
                maintainAspectRatio: false,
                legend: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
            }
        )
        let width = parseInt(document.getElementById('scatter-chart').style.width)
        this.$emit('input', {
            tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]._model.x /
                width) *
                100}%`,
        })
    },
}
