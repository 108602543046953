<i18n>
{
  "nl-BE": {
    "title_sent": "Uw aanvraag werd verzonden!",
    "title_blocked": "Het spijt ons dat u bent geblokkeerd",
    "more_questions": "U kunt de status van uw aanvraag volgen op het dashboard.",
    "or_not": "Graag stellen we u nog enkele vragen over de gebruikservaring.",
    "question_one": "Hoe beoordeelt u de ervaring bij het invoeren van deze aanvraag?",
    "question_two": "Welke documenten had de klant beschikbaar?",
    "add_document": "Een document toevoegen",
    "question_two_1": "EPC certificaat",
    "question_two_1_text": "Referentie",
    "question_two_2": "Immo advertentie",
    "question_two_2_text": "Bron",
    "question_two_2_link": "Link / referentie",
    "question_two_3": "Ondertekend bod",
    "question_two_4": "Compromis",
    "question_two_5": "Verkoopakte",
    "question_two_6": "Expertise rapport",
    "question_three_sent": "Andere opmerkingen",
    "question_three_blocked": "Vertel ons wat er is gebeurd en waarom u niet verder kunt gaan.",
    "textarea_placeholder": "Typ hier uw opmerkingen",
    "start_over": "Opnieuw beginnen zonder feedback verzenden",
    "send": "Feedback verzenden en opnieuw beginnen",
    "customer_present": "Heeft u dit formulier samen met de klant ingevuld?",
    "rating_description": "(1 = zeer slecht / 5 = zeer goed) ",
    "rating_1": "1: Zeer slecht",
    "rating_2": "2: Slecht",
    "rating_3": "3: Ok",
    "rating_4": "4: Goed",
    "rating_5": "5: Zeer goed",
    "yes": "Ja",
    "no": "Nee"
  },
  "en-BE": {
    "title_sent": "Your request was sent!",
    "title_blocked": "We're sorry you're blocked",
    "more_questions": "You can follow the progress of your request on the dashboard.",
    "or_not": "We would like to ask you a few more questions about your experience.",
    "question_one": "What was your overall experience for entering this valuation request?",
    "question_two": "Which documents did the customer have available?",
    "add_document": "Add a document",
    "question_two_1": "EPC Certificate",
    "question_two_1_text": "Reference",
    "question_two_2": "Ad listing",
    "question_two_2_text": "Source",
    "question_two_2_link": "Link / reference",
    "question_two_3": "Signed offer",
    "question_two_4": "Compromis",
    "question_two_5": "Sales Deed",
    "question_two_6": "Expert report",
    "question_three_sent": "Other remarks",
    "question_three_blocked": "Tell us what happened and why you cannot proceed.",
    "textarea_placeholder": "Enter your comments here",
    "start_over": "Start over without giving feedback",
    "send": "Send feedback and start over",
    "customer_present": "Did you fill in this form together with the customer?",
    "rating_description": "(1 = very bad / 5 = very good)",
    "rating_1": "1: Very bad",
    "rating_2": "2: Bad",
    "rating_3": "3: Ok",
    "rating_4": "4: Good",
    "rating_5": "5: Very good",
    "yes": "Yes",
    "no": "No"
  },
  "fr-BE": {
    "title_sent": "Votre demande a été envoyée !",
    "title_blocked": "Nous sommes désolés que vous soyez bloqué.",
    "more_questions": "Vous pouvez suivre le progrès de votre demande sur le dashboard.",
    "or_not": "Nous aimerions vous poser quelques questions supplémentaires sur votre expérience d'utilisation.",
    "question_one": "Comment évaluez-vous l'expérience globale pour remplir cette demande?",
    "question_two": "De quels documents le client disposait-il?",
    "add_document": "Ajouter un document",
    "question_two_1": "Certificat PEB",
    "question_two_1_text": "Référence",
    "question_two_2": "Annonce immobilière",
    "question_two_2_text": "Source",
    "question_two_2_link": "Lien / Référence",
    "question_two_3": "Offre signée",
    "question_two_4": "Compromis",
    "question_two_5": "Acte de vente",
    "question_two_6": "Rapport d'expertise",
    "question_three_sent": "Autres remarques",
    "question_three_blocked": "Expliquez-nous ce qui s'est passé et pourquoi vous ne pouvez pas continuer.",
    "textarea_placeholder": "Entrez vos commentaires ici",
    "start_over": "Recommencer sans donner de feedback",
    "send": "Envoyer le feedback et recommencer",
    "customer_present": "Avez-vous rempli ce formulaire avec le client?",
    "rating_description": "(1 = très mauvais / 5 = très bon)",
    "rating_1": "1: Très mauvais",
    "rating_2": "2: Mauvais",
    "rating_3": "3: Ok",
    "rating_4": "4: Bon",
    "rating_5": "5: Très bon",
    "yes": "Oui",
    "no": "Non"
  }
}
</i18n>

<template>
    <!-- TODO still need form keys & validations -->
    <div class="container is-fluid section is-max-desktop">
        <h2
            class="title is-size-3 has-text-weight_bold mgt-medium is-flex is_align-items-center"
        >
            {{ $t('title_' + flow) }}
        </h2>
        <p v-if="flow !== 'blocked'" class="has-text-text is-size-6">
            {{ $t('more_questions') }}
        </p>
        <!--<p v-if="flow !== 'blocked'" class="has-text-text is-size-6">
            {{ $t('or_not') }}
        </p>-->

        <div class="mt-6">
            <div class="columns is-multiline is-mobile">
                <!-- question 1 -->
                <div
                    v-if="flow !== 'blocked'"
                    class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
                >
                    <b-field
                        :label="$t('question_one') + ' ' + $t('rating_description')"
                        expanded
                    >
                        <b-rate
                            v-model="form.rating"
                            size="is-medium"
                            spaced
                            :texts="rating_texts"
                            show-text
                        ></b-rate>
                    </b-field>
                </div>
                <!-- end of question 1 -->
                <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet">
                    <b-field :label="$t('customer_present')">
                        <b-radio
                            v-model="form.customer_present"
                            name="name"
                            :native-value="true"
                        >
                            {{ $t('yes') }}
                        </b-radio>
                        <b-radio
                            v-model="form.customer_present"
                            class="pl-4"
                            name="name"
                            :native-value="false"
                        >
                            {{ $t('no') }}
                        </b-radio>
                    </b-field>
                </div>
                <!-- question 2 -->
                <div
                    v-if="flow !== 'blocked'"
                    class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
                >
                    <div class="columns is-multiline is-mobile">
                        <div class="column is-5">
                            <b-field :label="$t('question_two')">
                                <b-taginput
                                    ref="taginput"
                                    v-model="tags"
                                    :data="filteredTags"
                                    :open-on-focus="true"
                                    autocomplete
                                    icon="label"
                                    :placeholder="$t('add_document')"
                                    :allow-new="true"
                                    :before-adding="beforeAdding"
                                    :create-tag="createTag"
                                    @typing="typeTag"
                                >
                                    <template slot-scope="props">
                                        {{ $t(props.option.locale) }}
                                    </template>
                                    <template #empty>
                                        There are no items
                                    </template>
                                    <template #selected="props">
                                        <b-tag
                                            v-for="(tag, index) in props.tags"
                                            :key="index"
                                            type="is-primary"
                                            rounded
                                            :tabstop="false"
                                            ellipsis
                                            closable
                                            @close="
                                                $refs.taginput.removeTag(index, $event)
                                            "
                                        >
                                            {{ tag.description || $t(tag.locale) }}
                                        </b-tag>
                                    </template>
                                </b-taginput>
                            </b-field>
                        </div>

                        <!-- epc -->
                        <div class="column is-7">
                            <div class="columns is-gapless is-multiline is-mobile">
                                <div
                                    v-for="tag in tags.filter(
                                        (tag) =>
                                            tag.id === 'epc' || tag.id === 'adlisting'
                                    )"
                                    :key="tag.id"
                                    class="column is-12 mb-3"
                                >
                                    <b-field
                                        v-if="tag.id === 'epc'"
                                        :label="$t(tag.locale)"
                                    >
                                        <b-input
                                            v-model="form.documents.epc.reference"
                                            :placeholder="$t('question_two_1_text')"
                                        ></b-input>
                                    </b-field>
                                    <b-field
                                        v-if="tag.id === 'adlisting'"
                                        grouped
                                        :label="$t(tag.locale)"
                                    >
                                        <b-autocomplete
                                            v-model="form.documents.adlisting.source"
                                            :open-on-focus="true"
                                            :data="filteredSources"
                                            :placeholder="$t('question_two_2_text')"
                                            expanded
                                            @select="(option) => (selected = option)"
                                        ></b-autocomplete>
                                        <b-input
                                            v-model="form.documents.adlisting.reference"
                                            expanded
                                            :placeholder="$t('question_two_2_link')"
                                        ></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- question 3-->
                <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet">
                    <div class="columns is-gapless is-mobile">
                        <div class="column is-12">
                            <b-field :label="$t('question_three_' + flow)" expanded>
                                <b-input
                                    v-model="form.free_form_feedback"
                                    custom-class="placeholder-size has-fixed-size"
                                    size="is-small"
                                    rounded
                                    type="textarea"
                                    rows="3"
                                    :placeholder="$t('textarea_placeholder')"
                                ></b-input>
                            </b-field>
                        </div>
                    </div>
                </div>
                <div class="column is-12">
                    <div class="is-flex is-justify-content-space-between">
                        <b-button size="is-medium" type="is-primary" @click="startOver">
                            <span class="is-flex is-is_align-content-right">
                                <span v-if="info_to_send">{{ $t('send') }}</span>
                                <b-icon v-if="info_to_send" icon="send"></b-icon>
                                <span v-if="!info_to_send">{{ $t('start_over') }}</span>
                                <b-icon v-if="!info_to_send" icon="restart"></b-icon>
                            </span>
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            form: {
                rating: 0,
                documents: {
                    epc: { provided: false, reference: '' },
                    adlisting: { provided: false, source: '', reference: '' },
                },
                customer_present: null,
                free_form_feedback: '',
            },
            tags: [],
            inputtag_text: '',
        }
    },
    computed: {
        ...mapGetters(['getFullStore']),
        rating_texts() {
            return [
                this.$t('rating_1'),
                this.$t('rating_2'),
                this.$t('rating_3'),
                this.$t('rating_4'),
                this.$t('rating_5'),
            ]
            //['Very bad', 'Bad', 'Ok', 'Good', 'Very good']
            //['Zeer slecht','Slecht','Ok','Goed','Zeer goed']
            //['Très mauvais','Mauvais','Ok','Bon','Très bon']
        },
        flow() {
            return this.$route.query.msg === 'blocked' ? 'blocked' : 'sent'
        },
        filteredSources() {
            let sources = ['Immovlan', 'Immoweb', 'Zimmo', '...']
            return sources.filter((option) => {
                return (
                    option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.form.documents.adlisting.source.toLowerCase()) >= 0
                )
            })
        },
        filteredTags() {
            const default_tags = [
                {
                    locale: 'question_two_1',
                    id: 'epc',
                },
                {
                    locale: 'question_two_2',
                    id: 'adlisting',
                },
                {
                    locale: 'question_two_3',
                    id: 'offer',
                },
                {
                    locale: 'question_two_4',
                    id: 'compromis',
                },
                {
                    locale: 'question_two_5',
                    id: 'deed',
                },
                {
                    locale: 'question_two_6',
                    id: 'expertise',
                },
            ]
            return default_tags.filter((option) => {
                let tag_ids = this.tags.map((tag) => tag.id)

                return (
                    !tag_ids.includes(option.id) &&
                    this.$t(option.locale)
                        .toString()
                        .toLowerCase()
                        .indexOf(this.inputtag_text.toLowerCase()) >= 0
                )
            })
        },
        info_to_send() {
            return (
                this.form.rating != 0 ||
                this.form.customer_present !== null ||
                this.tags.length > 0 ||
                this.form.free_form_feedback != ''
            )
        },
    },
    methods: {
        ...mapMutations('Address', ['SET_FEATURES']),
        tagIndex(tag, taglist) {
            let tag_match = null
            if (typeof tag === 'string') {
                tag_match = taglist.map(
                    (t) =>
                        t.id == tag.toLowerCase() ||
                        this.$t(t.locale)
                            .toString()
                            .toLowerCase() == tag.toLowerCase()
                )
            } else {
                tag_match = taglist.map((t) => t.id == tag.id)
            }
            return tag_match.indexOf(true)
        },
        beforeAdding(tag) {
            this.inputtag_text = ''
            return this.tagIndex(tag, this.tags) == -1
        },
        createTag(tag) {
            // Check if the provided tag can be matched to a default tag
            let tag_index = this.tagIndex(tag, this.filteredTags)
            if (tag_index > -1) {
                let matched_tag = this.filteredTags[tag_index]
                return {
                    id: matched_tag.id,
                    locale: matched_tag.locale,
                    description: this.$t(matched_tag.locale).toString(),
                    type: 'default',
                }
            } else {
                return {
                    id: tag.toLowerCase(),
                    description: tag,
                    type: 'custom',
                }
            }
        },
        typeTag(text) {
            this.inputtag_text = text
        },
        startOver() {
            // Add documents from selected tags
            this.tags.forEach((tag) => {
                if (tag.id == 'epc' || tag.id == 'adlisting') {
                    this.$set(this.form.documents[tag.id], 'provided', true)
                    this.$set(this.form.documents[tag.id], 'description', tag.description)
                    this.$set(this.form.documents[tag.id], 'type', tag.type)
                } else {
                    this.$set(this.form.documents, tag.id, {
                        provided: true,
                        description: tag.description,
                        type: tag.type,
                    })
                }
            })
            let body = {
                store: this.getFullStore,
                feedback: this.form,
            }
            //TODO is this true
            if (this.info_to_send) {
                axios.post(
                    `${utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'feedback')}`,
                    body
                )
                this.$router.push({ params: { action: 'start' } })
            } else {
                this.$router.push({ params: { action: 'start' } })
            }
        },
    },
}
</script>

<style lang="scss">
.checkmark {
    height: 1rem;
}
.h-100 {
    height: 100%;
}
.placeholder-size::placeholder {
    font-size: 16px;
}
.textarea:not([rows]) {
    min-height: 14em !important;
    border-radius: 4px !important;
    font-size: 16px !important;
}
span {
    height: 100%;
}
</style>
