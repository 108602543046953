import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { translations } from './assets/config/config'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'en-BE',
    fallbackLocale: 'en-BE',
    messages: translations,
    silentTranslationWarn: false,
})
