/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apartment': {
    width: 28,
    height: 25,
    viewBox: '0 0 28 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 5.556h14V25H0V0h14v5.556zM2.8 22.222h2.8v-2.778H2.8v2.778zm2.8-5.555H2.8v-2.778h2.8v2.778zM2.8 11.11h2.8V8.333H2.8v2.778zm2.8-5.555H2.8V2.778h2.8v2.778zm2.8 16.666h2.8v-2.778H8.4v2.778zm2.8-5.555H8.4v-2.778h2.8v2.778zM8.4 11.11h2.8V8.333H8.4v2.778zm2.8-5.555H8.4V2.778h2.8v2.778zm14 16.666V8.333H14v2.778h2.8v2.778H14v2.778h2.8v2.777H14v2.778h11.2zm-2.8-11.11h-2.8v2.777h2.8V11.11zm-2.8 5.555h2.8v2.777h-2.8v-2.777z" _fill="#798DA6"/>'
  }
})
