<template>
    <div>
        <section class="section">
            <div v-if="feature_calculated" class="univariate_plot_container">
                <univariate-plot
                    v-model="chartData[0]"
                    :ref_points="ref_points[feature]"
                    :point_to_estimate="point_to_estimate[feature]"
                    class="univariate_plot"
                />
                <div
                    :style="`left: calc(${chartData[0].tooltipPosition} - 25px);`"
                    class="chart-tooltip"
                >
                    {{ Math.round(point_to_estimate[feature][0].x) }}
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import univariate_plot from './univariate_plot'

export default {
    components: {
        'univariate-plot': univariate_plot,
    },
    props: {
        feature: { type: String, default: 'f_building_area' },
        estimation_results_data: Object,
        input_data: Object,
    },
    data: function() {
        return {
            // Processed data for graphs
            point_to_estimate: {},
            ref_points: {},
            chartData: [
                { tooltipPosition: '-100000px' },
                { tooltipPosition: '-100000px' },
                { tooltipPosition: '-100000px' },
            ],
            // Flags
            feature_calculated: false,
        }
    },
    created() {
        // Preprocess feature
        this.ref_points[
            this.feature
        ] = this.estimation_results_data.reference_listings.map((ref_point) => {
            return { x: ref_point[this.feature], y: this.randn_bm(-5, 5, 1) }
        })
        this.point_to_estimate[this.feature] = [
            { x: this.input_data[this.feature], y: 0 },
        ]
        this.feature_calculated = true
    },
    methods: {
        randn_bm(min, max, skew) {
            var u = 0,
                v = 0
            while (u === 0) u = Math.random() //Converting [0,1) to (0,1)
            while (v === 0) v = Math.random()
            let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

            num = num / 10.0 + 0.5 // Translate to 0 -> 1
            if (num > 1 || num < 0) num = randn_bm(min, max, skew) // resample between 0 and 1 if out of range
            num = Math.pow(num, skew) // Skew
            num *= max - min // Stretch to fill range
            num += min // offset to min
            return num
        },
    },
}
</script>

<style scoped lang="scss">
.section {
    padding: 35px 0 0 0;
    margin: 0;
    width: 100%;
}
.univariate_plot_container {
    /* margin-bottom:1em; */
    width: 100%;
    position: relative;
}
.univariate_plot {
    /* max-width:500px; */
    height: 90px;
    /* margin-top: 40px; */
    max-width: 100%;
    width: 100%;
    margin: auto;
}
.section .chart-tooltip {
    width: 50px;
    top: -35px;
}

.chart-tooltip {
    position: absolute;
    top: -30px;
    left: -1000000px;
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 rgba(0, 47, 80, 0.1);
    background-color: rgba(0, 24, 55, 0.9);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    background-color: rgba(0, 24, 55, 0.9);
    z-index: 100;
    &::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: calc(50% - 6px);
        top: 30px;
        border: 6px solid;
        border-color: rgba(0, 24, 55, 0.9) transparent transparent transparent;
    }
}
</style>
