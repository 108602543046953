var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex",class:[
        _vm.$vssWidth > 1123
            ? 'is-justify-content-flex-start'
            : 'is-flex-direction-column is-justify-content-center' ]},[_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[_c('div',{ref:"box",class:{
                'choose-type-box': true,
                'not-choosen': _vm.value !== 'house',
                chosen: _vm.value === 'house',
                disable: !_vm.isPredraft,
            },on:{"click":function($event){return _vm.selectType('house')},"mouseenter":function($event){_vm.leftIcon = '#fff'},"mouseleave":_vm.leaveLeft}},[_c('div',{staticClass:"type-icon home"},[(_vm.activeIcon === 'house')?_c('svg-icon',{attrs:{"icon":"home","hight":"50","scale":"1.4","color":"#fff"}}):_c('svg-icon',{attrs:{"icon":"home","hight":"50","scale":"1.4","color":_vm.leftIcon}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('house'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('house_comment'))+" ")])])])]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[_c('div',{staticClass:"choose-type-box",class:{
                chosen: _vm.value === 'apartment',
                'not-choosen': _vm.value !== 'apartment',
                'mt-4': _vm.$vssWidth < 1123,
                disable: !_vm.isPredraft,
            },on:{"click":function($event){return _vm.selectType('apartment')},"mouseenter":function($event){_vm.rightIcon = '#fff'},"mouseleave":_vm.leaveRight}},[_c('div',{staticClass:"type-icon apartment"},[(_vm.activeIcon === 'apartment')?_c('svg-icon',{attrs:{"icon":"apartment","hight":"50","scale":"1.4","color":"#fff"}}):_c('svg-icon',{attrs:{"icon":"apartment","hight":"50","scale":"1.4","color":_vm.rightIcon}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('apartment'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('apartment_comment'))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }