<template>
    <div class="columns">
        <div class="column">
            <h2
                class="title is-size-4 is-family-secondary has-text-weight-bold mgt-medium is-flex is-align-items-center"
            >
                <span class="is-flex is-align-content-center">
                    <slot name="title"></slot>
                    <b-tooltip
                        size="is-medium"
                        class="pl-4"
                        :label="label"
                        position="is-right"
                        multilined
                    >
                        <div
                            class="is-rounded has-text-primary has-background-link is-tootip-btn is-flex is-align-items-center is-justify-content-center has-text-weight-bold"
                        >
                            ?
                        </div>
                    </b-tooltip>
                </span>
            </h2>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Tooltip right',
        },
    },
}
</script>

<style lang="scss"></style>
