<template>
    <div class="full">
        <section id="univariate_plots" class="section">
            <div v-if="correlation_plot_calculated" class="correlation_plot_container">
                <correlation-plot
                    id="correlation_plot"
                    :ref_points="ref_points"
                    :point_to_estimate="point_to_estimate"
                    :regression_line="regression_line"
                    class="univariate_plot"
                />
            </div>
        </section>
    </div>
</template>

<script>
import correlation_plot from './correlation_plot'

export default {
    components: {
        'correlation-plot': correlation_plot,
    },
    props: ['input_data', 'estimation_results_data'],
    data: function() {
        return {
            // Processed data for graphs
            point_to_estimate: [{ x: 0, y: 0 }],
            ref_points: [
                { x: 0, y: 0 },
                { x: 0, y: 0 },
            ],
            // Flags
            correlation_plot_calculated: false,
            chartData: {
                tooltipPosition: '-1000000px',
            },
        }
    },
    created() {
        setTimeout(() => {
            // Preprocess data
            this.ref_points = this.estimation_results_data.reference_listings.map(
                function(ref_point) {
                    return {
                        x: ref_point.f_living_area ? ref_point.f_living_area : 0,
                        y: ref_point.prijs,
                    }
                }.bind(this)
            )
            this.point_to_estimate = [
                {
                    x: this.input_data.f_living_area,
                    y: this.estimation_results_data.estimation_q50_euro,
                },
            ]
            this.regression_line = [
                {
                    x: this.estimation_results_data.regr_lines.f_living_area.x[0],
                    y: this.estimation_results_data.regr_lines.f_living_area.y[0],
                },
                {
                    x: this.estimation_results_data.regr_lines.f_living_area.x[1],
                    y: this.estimation_results_data.regr_lines.f_living_area.y[1],
                },
            ]
            this.correlation_plot_calculated = true
        }, 400)
    },
    methods: {
        randn_bm(min, max, skew) {
            var u = 0,
                v = 0
            while (u === 0) u = Math.random() //Converting [0,1) to (0,1)
            while (v === 0) v = Math.random()
            let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

            num = num / 10.0 + 0.5 // Translate to 0 -> 1
            if (num > 1 || num < 0) num = randn_bm(min, max, skew) // resample between 0 and 1 if out of range
            num = Math.pow(num, skew) // Skew
            num *= max - min // Stretch to fill range
            num += min // offset to min
            return num
        },
    },
}
</script>

<style scoped>
#correlation_plot,
.full,
#univariate_plots,
.correlation_plot_container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
}
.correlation_plot_container {
    position: relative;
}
.univariate_plot_container {
    /* margin-bottom:1em; */
    width: 100%;
    height: 100%;
}
.univariate_plot {
    /* max-width:500px; */
    height: 300px;
    max-width: 400px;
    margin: auto;
}
</style>
