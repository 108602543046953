var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container section is-fluid"},[_c('title-with-tooltip',{attrs:{"label":_vm.t('tooltip_text')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('customer_ref_title')))]},proxy:true}])}),_c('div',{staticClass:"columns is-mobile is-multiline"},[_c('div',{staticClass:"column is-9-fullhd is-10-tablet is-12-mobile"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendRequest($event)}}},[_c('b-field',{attrs:{"message":[
                        !_vm.$v.customer_ref.required ? _vm.$t('field_required') : null,
                        !_vm.$v.customer_ref.numeric_validation
                            ? _vm.$t('only_numbers')
                            : null,
                        !_vm.$v.customer_ref.forbidden_substring
                            ? _vm.t('forbidden_substring')
                            : null,
                        !_vm.$v.customer_ref.maxLength ? _vm.$t('text_too_long') : null ],"type":{
                        'is-danger':
                            _vm.$v.customer_ref.$error ||
                            (!_vm.$v.$anyDirty &&
                                _vm.getFullStore.conflicting_valuation_requests.length >
                                    0),
                        'is-success': !_vm.$v.customer_ref.$invalid,
                    }}},[_c('b-input',{attrs:{"icon-right":_vm.ckeckIcon,"placeholder":_vm.customer_ref_placeholder,"disabled":_vm.getFullStore.valuation_request_ref !== null},on:{"blur":function($event){return _vm.$v.customer_ref.$touch()},"input":function($event){return _vm.$v.customer_ref.$touch()}},model:{value:(_vm.customer_ref),callback:function ($$v) {_vm.customer_ref=$$v},expression:"customer_ref"}})],1)],1)]),_c('div',{staticClass:"column is-9-fullhd is-10-tablet is-12-mobile"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-dark","icon-left":"arrow-left","to":{
                        name: 'request',
                        params: {
                            lang: _vm.$route.params.lang,
                            action: 'start',
                        },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right","disabled":_vm.$v.customer_ref.$invalid},on:{"click":_vm.sendRequest}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))])])],1)])]),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},model:{value:(_vm.isConflictModalActive),callback:function ($$v) {_vm.isConflictModalActive=$$v},expression:"isConflictModalActive"}},[(_vm.isConflictModalActive)?_c('div',{staticClass:"exit-modal"},[_c('h2',{staticClass:"title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('conflict_modal.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('conflict_modal.content')))]),_c('p',[_vm._v(_vm._s(_vm.$t('conflict_modal.cta')))]),_c('div',[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":function($event){return _vm.SET_CONFLICTING_VALUATION_REQUESTS([])}}},[_vm._v(" "+_vm._s(_vm.$t('conflict_modal.back'))+" ")]),_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","tag":"router-link","to":{
                        name: 'dashboard',
                        params: { lang: _vm.$route.params.lang },
                        query: {
                            valuation_request_refs: _vm.getFullStore.conflicting_valuation_requests.join(
                                ','
                            ),
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('conflict_modal.dashboard'))+" ")]),_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":_vm.ignoreConflict}},[_vm._v(" "+_vm._s(_vm.$t('conflict_modal.continue'))+" ")])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }