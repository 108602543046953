<template>
    <div id="main-form">
        <div class="columns is-mobile is-multiline is-centered is-gapless">
            <div class="column is-4-desktop is-3-fullhd is-12-table is-12-mobile">
                <base-sidebar
                    class="sidebar"
                    :step="step"
                    :animation="animation"
                    @route-back="goBack"
                    @next-step="nextStep"
                    @submit-report="submit"
                />
            </div>
            <div class="column is-8-desktop is-9-fullhd is-12-tablet is-12-mobile">
                <div class="main-section is-relative">
                    <!-- load if estimate data is loaded -->
                    <step1 v-if="step === 1 && getEstimate" />
                    <!-- loader if data takes too long probably change it to project loader in future -->
                    <b-loading
                        :is-full-page="false"
                        :active="step === 1 && !getEstimate"
                        :can-cancel="false"
                    ></b-loading>
                    <step2 v-if="step === 2 && getEstimate" />
                    <step3 v-if="step === 3 && getEstimate" />
                    <step4 v-if="step === 4 && getEstimate" />
                    <step5 v-if="step === 5 && getEstimate" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Step1 from './Avm/FormSteps/Step1.vue'
import Step2 from './Avm/FormSteps/Step2.vue'
import Step3 from './Avm/FormSteps/Step3.vue'
import Step4 from './Avm/FormSteps/Step4.vue'
import BaseSidebar from './Avm/SidebarSteps/BaseSidebar'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'AppForm',
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        BaseSidebar,
    },
    data() {
        return {
            sidebarActive: false, // false
            spinnerActive: false,
            step: 0, // 0
            formValid: false,
            animation: {
                on_animated: false,
                button2: true,
                button3: false,
                button4: false,
                button5: false,
            },
        }
    },
    computed: {
        ...mapGetters('Address', ['getFeatures', 'getEstimate']),
        ...mapGetters(['getFullStore', 'flow_type']),
    },
    watch: {
        getFeatures: {
            deep: true,
            handler(value) {
                if (value.building_id) this.get_estimate()
            },
        },
    },
    created() {
        this.step = parseInt(this.$route.params.step)
        this.getStore().then(this.get_estimate())
    },
    methods: {
        ...mapActions(['getStore']),
        ...mapActions('Address', ['get_estimate']),
        goBack() {
            if (this.step === 1) {
                if (this.flow_type == 'avm_demo') {
                    this.$router.push({
                        name: 'request',
                        params: {
                            lang: this.$route.params.lang,
                            action: 'extra-info',
                        },
                        query: {
                            valuation_request_ref: this.getFullStore
                                .valuation_request_ref,
                        },
                    })
                } else if (this.flow_type == 'avm_input_only') {
                    this.$router.push({
                        name: 'dashboard',
                        params: {
                            lang: this.$route.params.lang,
                        },
                    })
                }
            } else {
                this.step--
                this.$router.push({
                    name: 'avm',
                    params: { step: this.step },
                    query: {
                        valuation_request_ref: this.getFullStore.valuation_request_ref,
                    },
                })
            }
        },
        nextStep() {
            if (this.step !== 4) {
                this.step++
                this.$router.push({
                    name: 'avm',
                    params: { step: this.step },
                    query: {
                        valuation_request_ref: this.getFullStore.valuation_request_ref,
                    },
                })
            }
        },
        submit() {
            // submit pdf
        },
    },
}
</script>

<style src="@/assets/redesigned/index.scss" lang="scss">
.h-100 {
    height: 100%;
}
</style>
<style scoped>
.sidebar {
    height: calc(100vh - 77px);
}
.main-section {
    height: 100%;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1023.99px) {
    .main-section {
        height: 700px;
    }
    #main-form {
        .column {
            order: 0;
            &:first-child {
                order: 1;
            }
        }
        #step4 {
            padding: 20px;
        }
    }
}
</style>
