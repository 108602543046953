<i18n>
{
    "fr-BE":{
        "sidebar2": {
            "title1": "Comment déterminer correctement le prix cible?",
            "title2": "Que voit-on exactement sur la carte?",
            "subtitle": {
                "line1": "Pour vous aider à estimer la propriété, nous utilisons 200 points de référence dans la région.",
                "line2": "Ce sont des propriétés qui étaient récemment à vendre dans la région."
            },
            "text2": "Sur base de cette légende, vous pouvez voir dans quelle fourchette de prix votre quartier se situe sur la carte:",
            "statistic": "Prix moyen des maisons (k€)",
            "map_marker1": "La maison à estimer",
            "map_marker2": "Référence importante à proximité",
            "map_marker3": "Référence mineure plus éloignée",
            "submit": "Voir la valeur de l'emplacement"
        }
    },
    "nl-BE":{
        "sidebar2": {
            "title1": "Hoe bepalen we de richtprijs juist?",
            "title2": "Wat zien we juist op de map?",
            "subtitle": {
                "line1": "Om je de woning te helpen schatten, gebruiken we 200 referentie punten in de omgeving.",
                "line2": "Dit zijn panden die onlangs in de buurt te koop stonden."
            },
            "text2": "Aan de hand van deze legende kan u zien in welke prijsklasse uw buurt valt op de map:",
            "statistic": "Gemiddelde huizenprijs (k€)",
            "map_marker1": "De te schatten woning",
            "map_marker2": "Belangrijke referentie vlakbij",
            "map_marker3": "Minder belangrijke referentie ver weg",
            "submit": "Bekijk de waarde van de locatie"
        }
    },
    "en-BE":{
        "sidebar2": {
            "title1": "How do we determine the target price correctly?",
            "title2": "What exactly do we see on the map?",
            "subtitle": {
                "line1": "To help you estimate the property, we use 200 reference points in the area.",
                "line2": "These are properties that were recently for sale in the area."
            },
            "text2": "Based on this legend, you can see in which price range your neighborhood falls on the map:",
            "statistic": "Average house price (k€)",
            "map_marker1": "The property to be estimated",
            "map_marker2": "Important reference nearby",
            "map_marker3": "Minor reference far away",
            "submit": "View the value of the location"
        }
    }
}
</i18n>

<template>
    <div id="sidebar2" class="sidebar-inner">
        <h1>{{ $t('sidebar2.title1') }}</h1>
        <div class="subtitle mb-0">
            <p class="mt-2">
                {{ $t('sidebar2.subtitle.line1') }}
            </p>
            <p v-if="!error" class="p-blue mt-2">
                {{ $t('sidebar2.subtitle.line2') }}
            </p>
        </div>
        <div class="separator"></div>
        <h2 v-if="!error">{{ $t('sidebar2.title2') }}</h2>
        <section v-if="!error" class="legend">
            <div class="legend-box">
                <img src="@/assets/icons/ic-location-small.svg" alt="" />
                {{ $t('sidebar2.map_marker1') }}
            </div>
            <div class="legend-box">
                <div class="ball-size"><div class="size__l"></div></div>
                {{ $t('sidebar2.map_marker2') }}
            </div>
            <div class="legend-box">
                <div class="ball-size"><div class="size__m"></div></div>
                <img src="@/assets/icons/ic-arrow-down.svg" alt="" />
            </div>
            <div class="legend-box">
                <div class="ball-size"><div class="size__s"></div></div>
                {{ $t('sidebar2.map_marker3') }}
            </div>
            <div class="subtitle pt-4">
                {{ $t('sidebar2.text2') }}
            </div>
            <div class="heatmap is-flex is-justify-content-space-between mt-5">
                <div
                    v-for="hm in heatmap"
                    :key="'hm' + hm.val"
                    class="heatmap-block"
                    :style="{ background: hm.color }"
                >
                    <div v-if="hm.val" class="heatmap-line"></div>
                    <div class="heatmap-value is-size-7">{{ hm.val }}</div>
                </div>
            </div>
            <div class="heatmap-description has-text-centered mt-5 pt-3">
                {{ $t('sidebar2.statistic') }}
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Sidebar1',
    props: {
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            heatmap: [
                {
                    val: null,
                    color: '#5E4FA2',
                },
                {
                    val: 150,
                    color: '#3387BC',
                },
                {
                    val: 200,
                    color: '#66C2A5',
                },
                {
                    val: 250,
                    color: '#AADCA4',
                },
                {
                    val: 300,
                    color: '#E6F598',
                },
                {
                    val: 350,
                    color: '#FFFEBE',
                },
                {
                    val: 400,
                    color: '#FEE08B',
                },
                {
                    val: 450,
                    color: '#FDAD60',
                },
                {
                    val: 500,
                    color: '#F46D43',
                },
                {
                    val: 550,
                    color: '#D43D4F',
                },
                {
                    val: 600,
                    color: '#9E0142',
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.heatmap {
    width: 100%;
    &-block {
        position: relative;
        height: 26px;
        width: 100%;
    }
    &-line {
        position: absolute;
        height: 6px;
        width: 1px;
        background: #435d6d;
        bottom: -6px;
        left: -1px;
    }
    &-value {
        color: #435d6d;
        position: absolute;
        bottom: -25px;
        left: -10px;
    }
}

#sidebar2 {
    min-height: calc(100% - 191px);
    transition: 0.3s all;
    position: relative;
    .legend {
        margin: 20px 0;
    }
}
</style>
