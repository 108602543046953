/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" d="M21 9.207L11 1 1 9.207" _stroke="#798DA6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M18.122 6.85v9.725H3.878V6.851" _stroke="#798DA6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M18.122 9.75V1.21h-3.459v2.796M12.89 10.968H9.14v5.607h3.75v-5.607z" _stroke="#798DA6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
