<i18n>
{
	"nl-BE": {
		"FAQ_title": "Veelgestelde vragen",
		"question_1": "Waarom dien ik dit webformulier in te vullen?",
		"answer_1": ["ING werkt momenteel samen met Rockestate om een ​​nieuwe oplossing voor het waarderen van vastgoed te testen die kan worden gebruikt bij het verlenen van een hypothecair krediet. ", " In de eerste plaats is het de bedoeling om hiermee te beantwoorden aan een aantal nieuwe regels die in voege treden later dit jaar.Verder zal deze oplossing ook bijdragen aan de vereenvoudiging van het transactieproces, aangezien het aantal dossiers waarvoor een expertise ter plaatse vereist is vermoedelijk zal verminderen. ", " Uw deelname aan deze Proof - of -Concept is belangrijk.Het geeft u de kans om mee vorm te geven aan dit nieuwe proces en ervoor te zorgen dat het aan uw behoeften en verwachtingen voldoet."],
		"question_2": "Wanneer dien ik dit webformulier in te vullen?",
		"answer_2": ["Voor elke nieuwe aanvraag voor een hypothecaire krediet die betrekking heeft op een huis of appartement. Enkel voor nieuwbouw hoeft u voorlopig nog geen formulier invullen. "],
		"question_3": "Dien ik dit webformulier ook in te vullen voor renovatie?",
		"answer_3": ["Ja. Kredietaanvragen met een renovatie component (renovatie of aankoop + renovatie) vallen hier ook onder."],
		"question_4": "Moet ik dit webformulier invullen als een expert al ter plaatse gaat?",
		"answer_4": ["Ja. Kredietaanvragen waarvoor een expert ter plaatse gaat vallen hier ook onder."],
		"question_5": "Betekent dit dat de huidige ING-regels inzake expertises gewijzigd zijn?",
		"answer_5": ["Nee. De bestaande ING-regels blijven van kracht tot nader order."],
		"question_6": "Ik heb niet het exacte adres van het gebouw. Wat moet ik doen?",
		"answer_6": ["De waarde van een huis of appartement is sterk afhankelijk van de ligging. Als u het adres van het gebouw niet correct kunt selecteren, moet u proberen de locatie van het gebouw bij benadering op de kaart te identificeren. Dit helpt ons om beter rekening te houden met de waarde van die specifieke locatie."],
		"question_7": "Waarom moet ik de percelen selecteren die betrekking hebben op een huis?",
		"answer_7": ["De waarde van een woning wordt niet alleen bepaald door het gebouw, maar ook door het omliggende terrein. Om de waarde correct in te schatten, moeten we daarom weten welke percelen deel uitmaken van de eigendom. ", " Doorgaans heeft een huis slechts één perceel dat we automatisch kunnen identificeren.Indien een huis echter meerdere percelen heeft, dan is het belangrijk dat u die extra percelen op de kaart aangeeft."],
		"question_8": "Waar kan ik informatie vinden zoals EPC, bouwjaar, bewoonde oppervlakte, etc.?",
		"answer_8": ["Als de potentiële kredietnemer u deze informatie niet meteen kan geven, raden wij u aan hem te helpen deze informatie te vinden door ofwel (i) de online advertentie op een vastgoedportaal (bv. Immoweb, Zimmo, ImmoVlan) te raadplegen, of het te (ii) vragen aan de verkoper of makelaar. ", " Goed om weten: Bij het te koop aanbieden van een huis of appartement is het in principe verplicht om de EPC - waarde in de advertentie vermelden."],
		"question_9": "Ik mis enkele inputparameters. Wat doe ik?",
		"answer_9": ["Er kunnen zich situaties voordoen waarbij u niet alle vereiste informatie kunt verzamelen. U kunt in dat geval nog steeds een aanvraag indienen. We willen hierbij wel benadrukken dat de kwaliteit van de schatting aanzienlijk zal afnemen als bepaalde inputparameters ontbreken. Daarom is het belangrijk om te proberen alle gevraagde informatie in te voeren waar mogelijk."],
		"question_10": "Kan ik een nieuwe aanvraag indienen voor dezelfde LOP ID?",
		"answer_10": ["Nee. Dit is momenteel niet mogelijk. Als u een LOP-ID invoert waarvoor al een aanvraag werd ingediend, zal u alle informatie over deze aanvraag kunnen zien. Het indienen van een nieuwe aanvraag zal (voorlopig) echter niet lukken."],
		"question_11": "Ik heb nog niet alle informatie. Kan ik mijn lopende aanvraag opslaan?",
		"answer_11": ["Dit is voorlopig nog niet mogelijk. We zijn momenteel bezig met het ontwikkelen van deze functionaliteit die het mogelijk maakt om een aanvraag tijdelijk op te slaan voordat deze wordt ingediend."],
		"question_12": "Waar vind ik meer info over Rockestate?",
		"answer_12": ["Meer informatie over Rockestate vindt u hier: ", "https://www.rockestate.io/about"],
		"question_13": "Waar vind ik meer info over over de data die Rockestate gebruikt?",
		"answer_13": ["Meer informatie over de data die Rockestate gebruikt, vindt u hier: ", "https://www.rockestate.io/blog/open-geo-data-rockestates-belangrijkste-ingredient"]
	},
	"en-BE": {
		"FAQ_title": "Frequently Asked Questions",
		"question_1": "Why should I fill in this webform?",
		"answer_1": ["ING is currently working together with Rockestate to test a new real estate valuation methodology which can be used as part of ING’s mortgage loan origination process. ", " This methodology aims at addressing a number of regulatory requirements that will enter into force later this year.It will also contribute to a simplified transaction process as it should decrease the number of mortgage loans for which an expert will need to visit the property that is being financed. ", " Your participation in this Proof - of -Concept is important.It gives you a chance to help shape this new process ensuring that it meets your needs and expectations."],
		"question_2": "When should I fill in this webform?",
		"answer_2": ["You are expected to create a new application for every mortgage loan related to a house or apartment, except for new construction."],
		"question_3": "Should I also fill in this webform for renovations?",
		"answer_3": ["Yes. Mortgage loan applications with a renovation component (renovation or purchase + renovation) are also included."],
		"question_4": "Should I fill in this webform if an expert will visit the property?",
		"answer_4": ["Yes. Mortgage loan applications where an expert needs to be sent on-site are also included."],
		"question_5": "Have the existing ING rules in terms of property valuation changed?",
		"answer_5": ["No. The existing ING rules remain in place until further notice."],
		"question_6": "I don’t have the exact address of the building. What should I do?",
		"answer_6": ["The value of a house or apartment strongly depends on its location. If you are unable to correctly select the building’s address, you should try to identify the approximate location of the building on the map. This will allow us to correctly take into account the value of that specific location."],
		"question_7": "Why am I asked to select the parcels related to a house?",
		"answer_7": ["The value of a house is not only determined by the building but also by also by the terrain surrounding it. To correctly estimate its value, we therefore need to know which parcels are related to the house.m ", " In the majority of situations, a house has one parcel which we will be able to automatically identify.However, if a house would have multiple parcels, it is important that you indicate those additional parcels on the map."],
		"question_8": "Where can I find the input parameters such as EPC, construction year, living area, etc.?",
		"answer_8": ["If the potential Borrower is not able to give you this information right away, we suggest that you help him find this information by either (i) checking the online ad listing on a real estate portal (e.g. Immoweb, Zimmo, ImmoVlan), or (ii) ask the seller or real estate agent. ", " Useful to know: When putting a house or apartment for sale, it is mandatory to communicate the EPC - value in the listing."],
		"question_9": "I am missing some input parameters. What should I do?",
		"answer_9": ["There will be situations that you may not be able to collect all the required information. In that case, you can still submit your application. We would like to stress the  fact that the quality of a valuation will significantly drop when certain input parameters are missing. Hence the importance of trying to get all the input parameters."],
		"question_10": "Can I submit a new application for the same LOP ID?",
		"answer_10": ["No. This is currently not possible. If you input a LOP ID for which an application has already been submitted, then you will be able to see all the info related to this application. You will only be able to see this in read-only mode and no overwrite is possible at this stage."],
		"question_11": "I don’t have all the information yet. Can I save my pending application?",
		"answer_11": ["This is not yet possible. We are currently developing this functionality which will make it possible to save a draft of an application before submitting it."],
		"question_12": "Can I have more info about Rockestate?",
		"answer_12": ["More information regarding Rockestate can be found here: ", "https://www.rockestate.io/about"],
		"question_13": "Can I have more info about the data Rockestate uses?",
		"answer_13": ["More information regarding the data used by Rockestate can be here: ", "https://www.rockestate.io/blog/open-geo-data-rockestates-key-ingredient"]
	},
	"fr-BE": {
		"FAQ_title": "Foire Aux Questions",
		"question_1": "Pourquoi dois-je remplir ce formulaire ?",
		"answer_1": ["ING travaille avec Rockestate pour tester une nouvelle méthode d'évaluation de biens immobiliers qui peut être utilisée dans le cadre du processus d'octroi de prêt hypothécaire par ING. ", "Cette méthode vise à répondre à un certain nombre d 'exigences réglementaires qui entreront en vigueur plus tard cette année. Cela contribuera également à simplifier le processus de transaction, car il devrait réduire le nombre de prêts hypothécaires pour lesquels un expert devra visiter le bien financé. ", "Votre participation à ce Proof - of -Concept est importante. Cela vous donne une opportunité de contribuer à la mise en forme de ce nouveau processus afin qu 'il réponde à vos besoins et à vos attentes."],
		"question_2": "Quand dois-je remplir ce formulaire ?",
		"answer_2": ["Vous créez une nouvelle demande pour chaque demande de prêt hypothécaire liée à une maison ou un appartement, à l'exception des nouvelles constructions."],
		"question_3": "Dois-je également remplir ce formulaire pour les rénovations ?",
		"answer_3": ["Oui. Les demandes de prêt hypothécaire qui contiennent un volet rénovation (rénovation ou achat + rénovation) sont également incluses."],
		"question_4": "Dois-je remplir ce formulaire si un expert évalue le bien sur place ?",
		"answer_4": ["Oui. Les demandes de prêt hypothécaire pour lesquelles un expert est envoyé sur place sont également incluses."],
		"question_5": "Les règles existantes d'ING en matière de valorisation immobilière ont-elles changé ?",
		"answer_5": ["Non. Les règles existantes restent en vigueur jusqu'à nouvel ordre."],
		"question_6": "Je n'ai pas l'adresse exacte du bien. Que devrais-je faire ?",
		"answer_6": ["La valeur d'une maison ou d'un appartement dépend fortement de son emplacement. Si vous ne parvenez pas à sélectionner correctement l'adresse du bâtiment, essayez alors d'identifier l'emplacement approximatif du bien sur la carte. Cela nous permettra de prendre correctement en compte la valeur de cet emplacement spécifique."],
		"question_7": "Pourquoi me demande-t-on de sélectionner les parcelles liées à une maison ?",
		"answer_7": ["La valeur d'une maison n'est pas seulement déterminée par le bâtiment, mais aussi par le terrain qui l'entoure. Pour estimer correctement sa valeur, il faut donc savoir quelles parcelles sont liées à la maison. ", " Dans la plupart des situations, une maison est liée à une seule parcelle que nous pourrons identifier automatiquement.Cependant, comme une transaction peut concerner plusieurs parcelles, il est important de les renseigner toutes."],
		"question_8": "Où puis-je trouver les données telles que PEB, année de construction, surface habitable, etc. ?",
		"answer_8": ["Si l'emprunteur potentiel n'est pas en mesure de vous donner ces informations immédiatement, nous vous suggérons de l'aider à les trouver soit (i) en vérifiant l’annonce en ligne sur un portail immobilier (tel que Immoweb, Zimmo, ImmoVlan), ou ( ii) en demandant au vendeur ou à l'agent immobilier. ", " Bon à savoir: lors de la mise en vente d 'une maison ou d'un appartement, il est obligatoire de communiquer la valeur PEB dans l 'annonce."],
		"question_9": "Il me manque quelques données. Que devrais-je faire ?",
		"answer_9": ["Dans certaines situations, vous ne pourrez peut - être pas collecter toutes les informations requises.Dans ce cas, vous pouvez quand - même soumettre votre demande.Nous tenons à souligner le fait que la qualité d 'une valorisation diminue considérablement lorsque certains paramètres manquent. D'où l 'importance d'essayer d 'obtenir tous les paramètres à remplir."],
		"question_10": "Puis-je soumettre une nouvelle demande pour le même identifiant LOP ?",
		"answer_10": ["Non, ce n'est actuellement pas possible. Si vous saisissez un identifiant LOP pour lequel un dossie a déjà été soumis, vous pourrez voir toutes les informations relatives à cette candidature. Par contre, vous ne pourrez pas la modifier."],
		"question_11": "Je n'ai pas encore toutes les informations. Puis-je sauvegarder ma demande en attendant ?",
		"answer_11": ["Ce n'est pas encore possible. Nous développons actuellement cette fonctionnalité qui vous permettra de sauvegarder un brouillon de la demande avant de la soumettre."],
		"question_12": "Puis-je avoir plus d'informations sur Rockestate ?",
		"answer_12": ["Vous trouverez plus d'informations sur Rockestate ici : ", "https://www.rockestate.io/about"],
		"question_13": "Puis-je avoir plus d'informations sur les données utilisées par Rockestate ?",
		"answer_13": ["Pour plus d'informations sur les données utilisées par Rockestate, cliquez ici : ", "https://www.rockestate.io/blog/geo-donnees-ouvertes-un-des-ingredients-principaux-de-rockestate"]
	}
}
</i18n>

<template>
    <div class="container is-fluid is-faq">
        <div class="is-flex is-justify-content-center is-w100 pt-6 mb-6">
            <h1 class="title">{{ $t('FAQ_title') }}</h1>
        </div>
        <div class="is-block">
            <b-collapse
                v-for="(collapse, index) of collapses"
                :key="index"
                class="mb-4"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
            >
                <template #trigger="props">
                    <div class="card-header has-background-trasparent" role="button">
                        <p class="card-header-title">
                            {{ collapse.title }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content has-background-white">
                    <div class="content">
                        <div
                            v-for="(par, i) in collapse.text[0]"
                            :key="i"
                            class=""
                            :class="{
                                paragraph: true,
                                'mb-4': i < Object.keys(collapse.text[0]).length - 1,
                            }"
                        >
                            <!-- https://stackoverflow.com/a/42740388 -->
                            <a v-if="par.startsWith('http')" :href="par">{{ par }}</a>
                            <div v-else>{{ par }}</div>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    data() {
        return {
            isOpen: 0,
            faqCount: 13,
            collapses: [],
        }
    },
    watch: {
        $route() {
            this.reactiveList()
        },
    },
    created() {
        this.reactiveList()
    },
    methods: {
        reactiveList() {
            for (let i = 0; i < this.faqCount; i++) {
                this.$set(this.collapses, i, {
                    title: this.$t(`question_${i + 1}`),
                    text: [this.$t(`answer_${i + 1}`)],
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.is-faq {
    .is-back {
        font-weight: 500;
    }
    h1 {
        font-size: 24px;
    }
}
</style>
