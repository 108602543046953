<template>
    <div id="app">
        <navbar
            ref="navbar"
            :email="$store.state.auth.email"
            :fixed="$route.name === 'report'"
        ></navbar>
        <router-view />
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import Navbar from './components/Navbar.vue'
export default {
    components: {
        navbar: Navbar,
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Rockestate estimator',
        // all titles will be injected into this template
        // titleTemplate: '%s | Rockestate estimator',
    },
    data() {
        return {
            email: null,
        }
    },
    mounted() {
        console.log('Rockestate app ' + process.env.VUE_APP_VERSION)
        // Check that the frontend verion matches what the backend thinks it should be
        // If it doesn't, refresh with the url containing the version number
        // This should defeat aggressive corporate caches.
        if (process.env.VUE_APP_AUTOREFRESH_STALE_FRONTEND.toLowerCase() === 'true') {
            axios
                .get(utils.urlJoin(process.env.VUE_APP_AUTH_API_URL, 'user'), {
                    params: { frontend_version: process.env.VUE_APP_VERSION_FULL },
                })
                .then(function(response) {
                    let frontend_version = response.data.frontend_version
                    if (
                        frontend_version &&
                        // Using numeric localCompare to get v1.2.0-8 < v1.2.0-10 < v2 < v10

                        frontend_version.localeCompare(
                            process.env.VUE_APP_VERSION_FULL,
                            'en',
                            { numeric: true }
                        ) === 1
                    ) {
                        console.warn(
                            `current frontend version ${process.env.VUE_APP_VERSION_FULL}` +
                                ` expecting ${frontend_version}`
                        )
                        if (window.location.toString().indexOf(frontend_version) === -1) {
                            console.warn(` ==> refreshing`)
                            window.location.replace(
                                [
                                    location.protocol,
                                    '//',
                                    location.host,
                                    location.pathname,
                                    '?v=',
                                    frontend_version,
                                ].join('') // https://stackoverflow.com/a/5817566
                            )
                        } else {
                            console.warn(' version mismatch, even after refresh')
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },
    methods: {
        find_cookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    var cookie_value = c.substring(name.length, c.length)
                    return cookie_value
                }
            }
            return null
        },
        set_cookie(cname, cvalue, exdays) {
            var d = new Date()
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
            var expires = 'expires=' + d.toUTCString()
            document.cookie = cname + '=' + cvalue + '; ' + expires + '; Path=/;'
        },
    },
}
</script>

<style lang="scss">
h1,
h2,
h3 {
    font-family: 'Montserrat', sans-serif;
}

#app {
    -webkit-print-color-adjust: exact;
}
</style>
