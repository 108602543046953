<i18n>
{
  "en-BE": {
    "title": "We're almost there. These are the final questions.",
    "field_required": "This field is required",
    "number_only": "Please enter only numeric values",
    "value_too_large": "The number is too large",
    "valid_years": "Between 1000 and 2021",
    "floors_not_consecutive": "The selected floors are not consecutive",
    "missing_features": "The following features are missing and will prevent automatic valuation: ",
    "incoherent_feature": "Please review the value in this field",
    "renovation_cost_zero_invalid": "Please enter a renovation cost higher than 0",
    "save": "Save",
    "sumbit_incomplete": "Do you want to submit this incomplete form?",
    "warning": "Warning",
    "send": "Send",
    "yes": "Yes",
    "no": "No",
    "previous": "Previous",
    "next": "Next",
    "select_message": "Select one",
    "ground_floor": "0 (Ground floor)",
    "leave_modal": {
        "title": "You have unsaved changes.",
        "content": "Do you want to save before you leave?",
        "confirm": "Exit",
        "cancel": "Stay on the page",
        "save": "Save and exit"
    }
  },
  "nl-BE": {
    "title": "We zijn er bijna. Nog enkele vraagjes om af te ronden.",
    "field_required": "Dit veld is verplicht",
    "number_only": "Gelieve enkel cijfers in te vullen",
    "value_too_large": "Het aantal is te groot",
    "valid_years": "Tussen 1900 en 2021",
    "floors_not_consecutive": "De geselecteerde verdiepingen zijn niet opeenvolgend",
    "incoherent_feature": "Gelieve de waarde in dit veld te controleren",
    "renovation_cost_zero_invalid": "Gelieve renovatiekosten hoger dan 0 in te voeren",
    "save": "Opslaan",
    "wrong_value": "Sommige informatie ontbreekt nog of is onverwacht. Wilt u dit onvolledige formulier toch indienen?",
    "warning": "Opgelet",
    "send": "Verzenden",
    "yes": "Ja",
    "no": "Nee",
    "previous": "Vorige",
    "next": "Volgende",
    "select_message": "Selecteer",
    "ground_floor": "0 (Gelijkvloers)",
    "leave_modal": {
        "title": "Er zijn niet opgeslagen wijzigingen.",
        "content": "Wil je opslaan voor je vertrekt?",
        "confirm": "Afsluiten",
        "cancel": "Blijf op de pagina",
        "save": "Opslaan en afsluiten"
    }
  },
  "fr-BE": {
    "title": "Nous y sommes presque. Quelques dernières questions pour conclure.",
    "field_required": "Ce champ est obligatoire",
    "number_only": "Veuillez entrer uniquement des chiffres",
    "value_too_large": "Ce nombre est trop grand",
    "valid_years": "Entre 1900 et 2021",
    "floors_not_consecutive": "Les étages sélectionnés ne sont pas consécutifs",
    "incoherent_feature": "Veuillez vérifier la valeur de ce champ",
    "renovation_cost_zero_invalid": "Veuillez entrer un coût de renovation supérieur à 0",
    "save": "Enregistrer",
    "wrong_value": "Certaines informations sont manquantes ou ont une valeur inattendue. Voulez-vous soumettre ces valeurs ?",
    "warning": "Attention",
    "send": "Envoyer",
    "yes": "Oui",
    "no": "Non",
    "previous": "Précédent",
    "next": "Suivant",
    "select_message": "Sélectionnez",
    "ground_floor": "0 (Rez-de-chaussée)",
    "leave_modal": {
        "title": "Vous avez des modifications non enregistrées.",
        "content": "Voulez-vous les enregistrer avant de quitter?",
        "confirm": "Quitter",
        "cancel": "Rester sur cette page",
        "save": "Enregistrer et quitter"
    }
  }
}
</i18n>

<template>
    <div class="container is-fluid section is-max-desktop">
        <div class="columns is-mobile is-multiline is-variable is-6">
            <div class="column is-12">
                <h2
                    class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('title') }}
                </h2>
            </div>
            <!-- not found -->
            <extra-info-not-found
                v-if="getFeatures.buildingnotfound"
                :valid="validAll"
                @notFound="notFoundValid = $event"
            />
            <!-- first line  -->
            <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet">
                <div class="is-flex is-align-content-flex-end">
                    <b-field custom-class="is-small-tooltip">
                        <template slot="label">
                            {{ $t('features.f_epc') }}
                            <b-tooltip
                                type="is-primary"
                                :label="$t('features.f_epc_tooltip')"
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>
                        </template>
                        <b-radio-button
                            v-model="structural.is_epc_number"
                            :native-value="true"
                            size="is-medium"
                            type="is-primary"
                        >
                            <span>{{ $t('unit.kwh/m2year') }}</span>
                        </b-radio-button>

                        <b-radio-button
                            v-model="structural.is_epc_number"
                            :native-value="false"
                            size="is-medium"
                            type="is-primary"
                        >
                            <span>Label</span>
                        </b-radio-button>
                    </b-field>
                    <b-field
                        v-if="structural.is_epc_number"
                        class="is-w100 ml-5 mt-5 pt-2"
                        :type="{
                            'is-danger':
                                !$v.structural.f_epc_numeric.decimal ||
                                !$v.structural.f_epc_numeric.required ||
                                !$v.structural.f_epc_numeric.maxValue ||
                                (is_incoherent('f_epc') &&
                                    !$v.structural.f_epc_numeric.$dirty),
                        }"
                        :message="[
                            !$v.structural.f_epc_numeric.decimal
                                ? $t('number_only')
                                : null,
                            !$v.structural.f_epc_numeric.maxValue
                                ? $t('value_too_large')
                                : null,
                            !$v.structural.f_epc_numeric.required
                                ? $t('field_required')
                                : null,
                            is_incoherent('f_epc') && !$v.structural.f_epc_numeric.$dirty
                                ? $t('incoherent_feature')
                                : null,
                        ]"
                    >
                        <b-input
                            v-model="structural.f_epc_numeric"
                            size="is-medium"
                            :disabled="!isDraft"
                            expanded
                            @input="$v.structural.f_epc_numeric.$touch()"
                        ></b-input>
                    </b-field>
                    <b-field
                        v-if="!structural.is_epc_number"
                        class="is-w100 ml-5 mt-5 pt-2"
                        :type="{
                            'is-danger': !$v.structural.f_epc_label.required,
                        }"
                        :message="[
                            !$v.structural.f_epc_label.required
                                ? $t('field_required')
                                : null,
                        ]"
                    >
                        <b-autocomplete
                            v-model="structural.f_epc_label"
                            size="is-medium"
                            placeholder="B"
                            :open-on-focus="true"
                            :data="epc_labels"
                            :disabled="!isDraft"
                        ></b-autocomplete>
                    </b-field>
                </div>
            </div>

            <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet">
                <div class="columns is-multiline is-mobile is-variable is-6">
                    <div class="column is-6-desktop is-12-mobile">
                        <text-input
                            v-model="structural.f_construction_year"
                            :disabled="!isDraft"
                            :label="$t('features.f_construction_year')"
                            :tooltip="$t('features.f_construction_year_tooltip')"
                            :type="{
                                'is-danger':
                                    !$v.structural.f_construction_year.decimal ||
                                    !$v.structural.f_construction_year.required ||
                                    ((!$v.structural.f_construction_year.minValue ||
                                        !$v.structural.f_construction_year.maxValue) &&
                                        $v.structural.f_construction_year.decimal) ||
                                    (is_incoherent('f_construction_year') &&
                                        !$v.structural.f_construction_year.$dirty),
                            }"
                            :message="[
                                (!$v.structural.f_construction_year.minValue ||
                                    !$v.structural.f_construction_year.maxValue) &&
                                $v.structural.f_construction_year.decimal
                                    ? $t('valid_years')
                                    : null,
                                !$v.structural.f_construction_year.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_construction_year.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_construction_year') &&
                                !$v.structural.f_construction_year.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.structural.f_construction_year.$touch()"
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <text-input
                            v-model="structural.f_living_area"
                            :disabled="!isDraft"
                            :label="$t('features.f_living_area')"
                            :tooltip="$t('features.f_living_area_tooltip')"
                            :type="{
                                'is-danger':
                                    !$v.structural.f_living_area.required ||
                                    !$v.structural.f_living_area.maxValue ||
                                    !$v.structural.f_living_area.decimal ||
                                    (is_incoherent('f_living_area') &&
                                        !$v.structural.f_living_area.$dirty),
                            }"
                            :message="[
                                !$v.structural.f_living_area.decimal
                                    ? $t('number_only')
                                    : null,
                                !$v.structural.f_living_area.maxValue
                                    ? $t('value_too_large')
                                    : null,
                                !$v.structural.f_living_area.required
                                    ? $t('field_required')
                                    : null,
                                is_incoherent('f_living_area') &&
                                !$v.structural.f_living_area.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.structural.f_living_area.$touch()"
                        />
                    </div>
                </div>
            </div>

            <!-- apartment -->
            <div
                v-if="is_apartment"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="columns is-multiline is-mobile is-variable is-6">
                    <!-- Number of bedrooms -->
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_bedrooms"
                            :disabled="!isDraft"
                            :label="$t('features.f_bedrooms')"
                            :tooltip="$t('features.f_bedrooms_tooltip')"
                            :min="0"
                            :max="20"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_bedrooms') &&
                                    !$v.apartment.f_bedrooms.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_bedrooms') &&
                                !$v.apartment.f_bedrooms.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_bedrooms.$touch()"
                        />
                    </div>
                    <!-- Balcony -->
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_balcony_present"
                            :label="$t('features.f_balcony_present')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_balcony_present_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_balcony_present') &&
                                    !$v.apartment.f_balcony_present.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_balcony_present') &&
                                !$v.apartment.f_balcony_present.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_balcony_present.$touch()"
                        />
                    </div>

                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_bottom_floor"
                            :disabled="!isDraft"
                            :label="$t('features.f_bottom_floor')"
                            :tooltip="$t('features.f_bottom_floor_tooltip')"
                            :min="-5"
                            :max="0"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_bottom_floor') &&
                                    !$v.apartment.f_bottom_floor.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_bottom_floor') &&
                                !$v.apartment.f_bottom_floor.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_bottom_floor.$touch()"
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_top_floor"
                            :disabled="!isDraft"
                            :label="$t('features.f_top_floor')"
                            :tooltip="$t('features.f_top_floor_tooltip')"
                            :max="40"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_top_floor') &&
                                    !$v.apartment.f_top_floor.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_top_floor') &&
                                !$v.apartment.f_top_floor.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_top_floor.$touch()"
                        />
                    </div>
                    <range-selector
                        v-model="apartment.f_floors"
                        :label="$t('features.f_floors')"
                        :tooltip="$t('features.f_floors_tooltip')"
                        :message="[
                            !$v.apartment.f_floors.consecutive
                                ? $t('floors_not_consecutive')
                                : null,
                            is_incoherent('f_floors') && !$v.apartment.f_floors.$dirty
                                ? $t('incoherent_feature')
                                : null,
                        ]"
                        :type="{
                            'is-danger':
                                !$v.apartment.f_floors.consecutive ||
                                (is_incoherent('f_floors') &&
                                    !$v.apartment.f_floors.$dirty),
                        }"
                        :range-list="floor_list"
                        :button-label-renderer="
                            (floor) => (floor === 0 ? $t('ground_floor') : floor)
                        "
                        @input="$v.apartment.f_floors.$touch()"
                    />
                    <!-- Garden -->
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_garden_common"
                            :label="$t('features.f_garden_common')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_garden_common_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_garden_common') &&
                                    !$v.apartment.f_garden_common.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_garden_common') &&
                                !$v.apartment.f_garden_common.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_garden_common.$touch()"
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_garden_private"
                            :label="$t('features.f_garden_private')"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_garden_private_tooltip')"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_garden_private') &&
                                    !$v.apartment.f_garden_private.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_garden_private') &&
                                !$v.apartment.f_garden_private.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_garden_private.$touch()"
                        />
                    </div>

                    <!-- Garage -->
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_n_closed_garage"
                            :disabled="!isDraft"
                            :tooltip="$t('features.f_n_closed_garage_tooltip')"
                            :is-big="true"
                            :label="$t('features.f_n_closed_garage')"
                            :min="0"
                            :max="20"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_n_closed_garage') &&
                                    !$v.apartment.f_n_closed_garage.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_n_closed_garage') &&
                                !$v.apartment.f_n_closed_garage.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_n_closed_garage.$touch()"
                        />
                    </div>
                    <div class="column is-6-desktop is-12-tablet">
                        <int-selector
                            v-model="apartment.f_n_parking_spot"
                            :disabled="!isDraft"
                            :is-big="true"
                            :label="$t('features.f_n_parking_spot')"
                            :tooltip="$t('features.f_n_parking_spot_tooltip')"
                            :min="0"
                            :max="40"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_n_parking_spot') &&
                                    !$v.apartment.f_n_parking_spot.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_n_parking_spot') &&
                                !$v.apartment.f_n_parking_spot.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_n_parking_spot.$touch()"
                        />
                    </div>

                    <div class="column is-6-desktop is-12-tablet">
                        <radio-selector
                            v-model="apartment.f_cellar_attic"
                            :label="$t('features.f_cellar_attic')"
                            :disabled="!isDraft"
                            :options="[
                                {
                                    label: $t('yes'),
                                    value: true,
                                },
                                {
                                    label: $t('no'),
                                    value: false,
                                },
                            ]"
                            :type="{
                                'is-danger':
                                    is_incoherent('f_cellar_attic') &&
                                    !$v.apartment.f_cellar_attic.$dirty,
                            }"
                            :message="[
                                is_incoherent('f_cellar_attic') &&
                                !$v.apartment.f_cellar_attic.$dirty
                                    ? $t('incoherent_feature')
                                    : null,
                            ]"
                            @input="$v.apartment.f_cellar_attic.$touch()"
                        />
                    </div>
                </div>
            </div>
            <!-- Renovations -->
            <div
                v-if="allow_renovation"
                class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"
            >
                <div class="is-flex is-align-content-flex-end">
                    <b-field custom-class="is-small-tooltip">
                        <template slot="label">
                            {{ $t('section.renovation') }}
                            <!--<b-tooltip
                                type="is-primary"
                                :label="$t('features.renovation_tooltip')"
                                multilined
                            >
                                <div
                                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>-->
                        </template>
                        <b-radio-button
                            v-model="structural.has_renovation"
                            :native-value="false"
                            size="is-medium"
                            type="is-primary"
                            @click="$v.structural.renovation_cost.$touch()"
                        >
                            <span>{{ $t('no') }}</span>
                        </b-radio-button>

                        <b-radio-button
                            v-model="structural.has_renovation"
                            :native-value="true"
                            size="is-medium"
                            type="is-primary"
                            @click="$v.structural.renovation_cost.$touch()"
                        >
                            <span>{{ $t('yes') }}</span>
                        </b-radio-button>
                    </b-field>
                    <b-field
                        v-if="structural.has_renovation"
                        class="is-w100 ml-5"
                        :type="{
                            'is-danger':
                                !$v.structural.renovation_cost.decimal ||
                                !$v.structural.renovation_cost.required ||
                                (is_incoherent('renovation_cost') &&
                                    !$v.structural.renovation_cost.$dirty) ||
                                renovation_cost_zero_invalid,
                        }"
                        :message="[
                            !$v.structural.renovation_cost.decimal
                                ? $t('number_only')
                                : null,

                            !$v.structural.renovation_cost.required
                                ? $t('field_required')
                                : null,
                            renovation_cost_zero_invalid
                                ? $t('renovation_cost_zero_invalid')
                                : null,
                            is_incoherent('renovation_cost') &&
                            !$v.structural.renovation_cost.$dirty
                                ? $t('incoherent_feature')
                                : null,
                        ]"
                    >
                        <template slot="label">
                            {{ $t('features.renovation_cost') }}
                        </template>
                        <b-input
                            v-model="structural.renovation_cost"
                            size="is-medium"
                            :disabled="!isDraft"
                            expanded
                            @input="$v.structural.renovation_cost.$touch()"
                        ></b-input>
                    </b-field>
                </div>
            </div>

            <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-6">
                <div class="is-flex is-justify-content-space-between">
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        @click="prev_page"
                    >
                        {{ $t('previous') }}
                    </b-button>
                    <b-button
                        v-if="
                            (isDraft || sending) &&
                                flow_type === 'avm_input_only' &&
                                $v.$anyDirty
                        "
                        size="is-medium"
                        type="is-dark"
                        icon-left="content-save"
                        :disabled="form_any_error"
                        :loading="sending"
                        @click="submitForm('save-draft')"
                    >
                        {{ $t('save') }}
                    </b-button>
                    <b-button
                        v-if="(isDraft || sending) && flow_type === 'avm_input_only'"
                        size="is-medium"
                        type="is-primary"
                        icon-right="send"
                        :disabled="!form_complete"
                        :loading="sending"
                        @click="submitForm('submit')"
                    >
                        {{ $t('send') }}
                    </b-button>
                    <b-button
                        v-if="!isDraft && !sending && flow_type === 'avm_input_only'"
                        size="is-medium"
                        type="is-dark"
                        icon-left="view-dashboard"
                        tag="router-link"
                        :to="{
                            name: 'dashboard',
                            params: {
                                lang: $route.params.lang,
                            },
                        }"
                    >
                        {{ $t('dashboard') }}
                    </b-button>
                    <b-button
                        v-if="flow_type === 'avm_demo'"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :disabled="!form_complete"
                        :loading="sending"
                        @click="submitForm('submit')"
                    >
                        {{ $t('next') }}
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal
            v-model="isExitingModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="isExitingModalActive" class="exit-modal">
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('leave_modal.title') }}
                </h2>
                <p>{{ $t('leave_modal.content') }}</p>
                <div>
                    <b-button
                        v-if="isExiting && cancelFn"
                        size="is-medium"
                        type="is-dark"
                        @click="cancelFn"
                    >
                        {{ $t('leave_modal.cancel') }}
                    </b-button>
                    <b-button
                        v-if="isExiting && proceedFn"
                        size="is-medium"
                        type="is-primary"
                        @click="
                            () => {
                                submitForm('save-draft')
                                proceedFn()
                            }
                        "
                    >
                        {{ $t('leave_modal.save') }}
                    </b-button>
                    <b-button
                        v-if="isExiting && proceedFn"
                        size="is-medium"
                        type="is-dark"
                        @click="proceedFn"
                    >
                        {{ $t('leave_modal.confirm') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
    numeric,
    required,
    requiredIf,
    requiredUnless,
    decimal,
    minValue,
    maxValue,
} from 'vuelidate/lib/validators'
//import ExtraInfoApartment from '@/components/request/05.1-ExtraInfoApartment.vue'
import ExtraInfoNotFound from '@/components/request/05.2-ExtraInfoNotFound.vue'
import IntSelector from '../elements/IntSelector.vue'
import RadioSelector from '../elements/RadioSelector.vue'
import TextInput from '../elements/TextInput.vue'
import RangeSelector from '../elements/RangeSelector.vue'
import utils from '@/shared/plugins/utils'

function default_data() {
    return {
        isExitingModalActive: false,
        touching_sides: null,
        sending: false,
        notFoundValid: false,
        validAll: false,
        structural: {
            f_construction_year: null,
            f_epc_numeric: null,
            f_epc_label: null,
            is_epc_number: true,
            f_living_area: null,
            has_renovation: false,
            renovation_cost: 0,
        },
        apartment: {
            f_bedrooms: 0,
            f_balcony_present: null,
            f_bottom_floor: 0,
            f_top_floor: 0,
            f_floors: [],
            f_garden_common: null,
            f_garden_private: null,
            f_n_closed_garage: 0,
            f_n_parking_spot: 0,
            f_cellar_attic: null,
        },
    }
}
export default {
    components: {
        //ExtraInfoApartment,
        ExtraInfoNotFound,
        IntSelector,
        RadioSelector,
        TextInput,
        RangeSelector,
    },
    props: {
        isExiting: {
            type: Boolean,
            required: false,
            default: false,
        },
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        ...default_data(),
        state: {
            isSaved: false,
        },
    }),
    validations: {
        structural: {
            f_epc_numeric: {
                decimal,
                required: requiredIf('is_epc_number'),
                maxValue: maxValue(1500),
            },
            f_epc_label: {
                required: requiredUnless('is_epc_number'),
            },
            f_construction_year: {
                // min - max year
                decimal,
                required,
                minValue: minValue(1000),
                maxValue: maxValue(2021),
            },
            f_living_area: {
                decimal,
                required,
                maxValue: maxValue(99999),
            },
            renovation_cost: {
                decimal,
                required,
            },
        },
        apartment: {
            f_bedrooms: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_balcony_present: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_floors: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
                consecutive: function(floors) {
                    floors.sort((a, b) => a - b)
                    let is_consecutive = true
                    for (let i = 1; i < floors.length; i++)
                        is_consecutive = is_consecutive && floors[i - 1] === floors[i] - 1
                    if (!is_consecutive) console.log(floors)
                    return is_consecutive
                },
            },
            f_garden_common: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_garden_private: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_n_closed_garage: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_n_parking_spot: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_cellar_attic: {
                required: requiredIf(function() {
                    return this.is_apartment
                }),
            },
            f_bottom_floor: {},
            f_top_floor: {},
        },
    },

    computed: {
        ...mapGetters('Address', ['getFeatures', 'getFullBuildingData']),
        ...mapGetters(['getFullStore', 'isDraft', 'flow_type']),
        is_apartment() {
            return this.getFeatures.f_building_type === 'apartment'
        },
        allow_renovation() {
            return process.env.VUE_APP_ALLOW_RENOVATION.toLowerCase() === 'true'
        },
        renovation_cost_zero_invalid() {
            return (
                this.structural.has_renovation &&
                !this.$v.structural.renovation_cost.$invalid &&
                this.getFeatures.renovation_cost <= 0
            )
        },
        epc_labels() {
            return Object.keys(utils.epc_mapping[this.getFeatures.region])
        },
        form_complete() {
            return !this.$v.$invalid && !this.renovation_cost_zero_invalid
        },
        form_any_error() {
            return (
                !this.$v.structural.f_living_area.decimal ||
                !this.$v.structural.f_construction_year.decimal ||
                !this.$v.structural.f_epc_numeric.decimal ||
                !this.$v.structural.renovation_cost.decimal
            )
        },
        floor_list() {
            let l = []
            for (
                let i = this.apartment.f_bottom_floor;
                i <= this.apartment.f_top_floor;
                i += 1
            ) {
                l.push(i)
            }
            return l
        },
    },
    watch: {
        isExiting(val) {
            if (this.$v.$anyDirty) {
                this.isExitingModalActive = val
            } else if (this._props.proceedFn) {
                this._props.proceedFn()
            }
        },
        'structural.f_construction_year'(val) {
            this.SET_FEATURES({ f_construction_year: val === '' ? null : parseInt(val) })
        },
        'structural.f_epc_label'(val) {
            this.SET_FEATURES({ f_epc_label: val === '' ? null : val })
        },
        'structural.f_epc_numeric'(val) {
            this.SET_FEATURES({ f_epc_numeric: val === '' ? null : parseFloat(val) })
        },
        'structural.is_epc_number'(val) {
            // Make sure to erase the content of a textbox when changing the epc type
            if (val) {
                this.structural.f_epc_label = null
            } else {
                this.structural.f_epc_numeric = null
            }
            this.SET_FEATURES({
                is_epc_number: val,
            })
        },
        'structural.f_living_area'(val) {
            this.SET_FEATURES({
                f_living_area: val === '' ? null : parseFloat(val),
            })
        },
        'structural.renovation_cost'(val) {
            this.SET_FEATURES({
                renovation_cost: val === '' ? null : parseFloat(val),
            })
        },
        'structural.has_renovation'(val) {
            if (!val) {
                this.structural.renovation_cost = '0'
            }
        },
        'apartment.f_bedrooms'(val) {
            this.SET_FEATURES({
                f_bedrooms: val,
            })
        },
        'apartment.f_balcony_present'(val) {
            this.SET_FEATURES({
                f_balcony_present: val,
            })
        },
        'apartment.f_bottom_floor'(val) {
            this.SET_FEATURES({
                f_bottom_floor: val,
            })
        },
        'apartment.f_top_floor'(val) {
            this.SET_FEATURES({
                f_top_floor: val,
            })
        },
        'apartment.f_floors'(val) {
            this.SET_FEATURES({
                f_floors: val,
            })
        },
        'apartment.f_garden_common'(val) {
            this.SET_FEATURES({
                f_garden_common: val,
            })
        },
        'apartment.f_garden_private'(val) {
            this.SET_FEATURES({
                f_garden_private: val,
            })
        },
        'apartment.f_n_closed_garage'(val) {
            this.SET_FEATURES({
                f_n_closed_garage: val,
            })
        },
        'apartment.f_n_parking_spot'(val) {
            this.SET_FEATURES({
                f_n_parking_spot: val,
            })
        },
        'apartment.f_cellar_attic'(val) {
            this.SET_FEATURES({
                f_cellar_attic: val,
            })
        },
    },
    mounted() {
        // updated separate key data from store
        for (const key in this.apartment) {
            this.apartment[key] =
                typeof this.getFeatures[key] !== 'undefined'
                    ? this.getFeatures[key]
                    : default_data().apartment[key]
        }
        for (const key in this.structural) {
            this.structural[key] =
                typeof this.getFeatures[key] !== 'undefined'
                    ? this.getFeatures[key]
                    : default_data().structural[key]
        }
        if (this.getFeatures.is_epc_number === null) {
            this.structural.is_epc_number = true
        }
        if (
            this.getFeatures.f_bedrooms < 1 ||
            typeof this.getFeatures.f_bedrooms !== 'number'
        ) {
            this.apartment.f_bedrooms = 0
        }
        if (this.getFeatures.renovation_cost) {
            this.structural.has_renovation = true
        }
    },
    methods: {
        ...mapMutations(['UPDATE_STATUS']),
        ...mapMutations('Address', ['SET_FEATURES']),
        ...mapActions(['updateStore']),
        prev_page() {
            this.$router.push({
                params: {
                    lang: this.$route.params.lang,
                    action: 'view-building-parcels',
                },
                query: this.$route.query,
            })
        },
        next_page() {
            this.updateStore({
                data: {},
                lang: this.$i18n.locale,
                action: 'confirm',
            }).then(() => {
                let next_route = {}
                if (process.env.VUE_APP_FEEDBACK_FORM === 'always') {
                    next_route = {
                        params: { action: 'feedback' },
                        query: {
                            valuation_request_ref: this.getFullStore
                                .valuation_request_ref,
                        },
                    }
                } else {
                    next_route = {
                        name: 'dashboard',
                    }
                }
                this.$router.push(next_route)
            })
        },
        increment() {
            this.form.number += 1
        },
        decrement() {
            if (this.form.number) {
                this.form.number -= 1
            }
        },
        is_incoherent(feature) {
            return (
                this.getFullBuildingData?.last_dvm_rejection?.incoherent_features &&
                this.getFullBuildingData.last_dvm_rejection.incoherent_features.includes(
                    feature
                ) &&
                this.isDraft
            )
        },
        async submitForm(action) {
            if (this.isDraft) {
                this.sending = true
                if (action === 'submit') {
                    this.UPDATE_STATUS({
                        locked: true,
                        status: 'submitted',
                    })
                    await this.updateStore({
                        data: this.getFullBuildingData,
                        lang: this.$i18n.locale,
                        action: 'submit',
                    })
                    this.$v.$reset()
                    this.next_page()
                } else {
                    await this.updateStore({
                        data: this.getFullBuildingData,
                        lang: this.$i18n.locale,
                        action: 'save-draft',
                    })
                    this.$v.$reset()
                }

                this.sending = false
            }
        },
    },
}
</script>

<style lang="scss">
.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .control {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}
.is-modal-message {
    max-width: 400px;
    display: inline-block;
}
.media {
    align-items: center !important;
}
.animation-content {
    z-index: 98;
}
.exit-modal {
    padding: 3rem;
    background: white;
    z-index: 99;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 14px 0px #002f501a;

    h2.title {
        margin-top: 0 !important;
        padding-bottom: 0 !important;
    }

    p {
        margin-bottom: 24px;
        font-size: 16px;
    }

    div {
        display: flex;
        justify-content: center;

        button {
            margin: 0 5px;
        }
    }
}
</style>
