<i18n>
{
    "fr-BE": 
    {
        "sidebar1": {
            "submit": "Voir les propriétés de la maison",
            "new": "Commencer une nouvelle évaluation"
        },
        "sidebar2": {
            "submit": "Voir le prix cible"
        },
        "sidebar3": {
            "submit": "Voir en détail"
        }
    },
    "nl-BE":{
        "sidebar1": {
            "submit": " Bekijk de eigenschappen van de woning",
            "new": "Nieuwe waardebepaling starten"
        },
        "sidebar2": {
            "submit": "Bekijk de richtprijs"
        },
        "sidebar3": {
            "submit": "Bekijk in detail"
        }
    },
    "en-BE":{
        "sidebar1": {
            "submit": "View the properties of the house",
            "new": "Start new valuation"
        },
        "sidebar2": {
            "submit": "View the target price"
        },
        "sidebar3": {
            "submit": "View in detail"
        }
    }
}
</i18n>
<template>
    <div id="base-sidebar">
        <div class="container is-fluid is-relative h-100">
            <div class="my-5 is-flex is-clickable" @click="$emit('route-back')">
                <img src="@/assets/icons/bttn-back.svg" class="back-arrow" />
                <div class="pl-3">
                    {{ addressInfo.full_address }}
                </div>
            </div>
            <Sidebar1 v-if="step === 1" :error="errorMessage" />
            <Sidebar2 v-else-if="step === 2" />
            <Sidebar3 v-else-if="step === 3" />
            <Sidebar4 v-else />

            <div v-if="!errorMessage">
                <div v-for="key in 5" :key="key" class="submit-button_wapper ">
                    <div
                        v-if="step === key && key < 4"
                        class="has-background-primary has-text-white has-text-centered py-4 px-3 is-size-5 is-clickable"
                        @click="$emit('next-step')"
                    >
                        {{ $t(`sidebar${key}.submit`) }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="submit-button_wapper ">
                    <router-link
                        :to="{
                            name: 'request',
                            params: { action: 'start', lang: $route.params.lang },
                        }"
                        tag="div"
                        class="has-background-primary has-text-white has-text-centered py-4 px-3 is-size-5 is-clickable"
                    >
                        {{ $t(`sidebar1.new`) }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar1 from './Sidebar1.vue'
import Sidebar2 from './Sidebar2.vue'
import Sidebar3 from './Sidebar3.vue'
import Sidebar4 from './Sidebar4.vue'
export default {
    components: {
        Sidebar1,
        Sidebar2,
        Sidebar3,
        Sidebar4,
    },
    props: {
        step: {
            type: Number,
            default: 2,
        },
        animation: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('Address', ['addressInfo', 'errorMessage']),
    },
}
</script>

<style lang="scss" scoped>
.submit-button_wapper {
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
}
</style>
