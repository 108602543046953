/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 14,
    height: 19,
    viewBox: '0 0 14 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.968.445c3.706 0 6.7 2.993 6.7 6.7 0 1.46-.5 2.85-1.284 3.955l-5.38 7.556L1.585 11.1A6.595 6.595 0 01.268 7.145c0-3.707 2.993-6.7 6.7-6.7zm3.136 6.7a3.136 3.136 0 11-6.273 0 3.136 3.136 0 016.273 0z" _fill="#0096FF" fill-opacity=".2"/>'
  }
})
