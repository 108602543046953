import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import router from '../../router'

const cookie_polling_interval = parseFloat(process.env.VUE_APP_POLLING_INTERVAL)
const auth = {
    namespaced: true,
    state: {
        authenticated: null,
        email: null,
        organization: null,
        roles: [],
        jwtInterval: null,
        showTimeoutPopup: false,
    },
    mutations: {
        SET_USER(state, data) {
            state.email = data.claims?.email || data.username || null
            state.authenticated = data.authenticated || null
            state.organization = data.organization || null
        },
        SET_ROLES(state, data) {
            state.roles = data.roles || []
        },
        SET_INTERVAL(state, data) {
            if (data.cb) {
                state.jwtInterval = setInterval(data.cb, cookie_polling_interval * 1000)
            } else {
                clearInterval(state.jwtInterval)
                state.jwtInterval = null
            }
        },
        SET_TIMEOUTPOPUP(state, data) {
            state.showTimeoutPopup = data.showTimeoutPopup
        },
    },
    actions: {
        check_authentication(context) {
            return axios
                .get(utils.urlJoin(process.env.VUE_APP_AUTH_API_URL, 'user'))
                .then((response) => {
                    context.commit('SET_USER', response.data)
                    if (response.data.authenticated) {
                        return axios
                            .get(
                                utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                                    'user',
                                    'roles',
                                ])
                            )
                            .then((roles_response) => {
                                context.commit('SET_ROLES', roles_response.data)
                            })
                    }
                })
        },
        start_interval(context) {
            context.commit('SET_INTERVAL', {
                cb: () => {
                    const jwt_ = utils.getCookie('re-jwt')
                    if (jwt_ && jwt_ !== '') {
                        const parsed_ = utils.parseJwt(jwt_)
                        const { exp } = parsed_

                        if (exp * 1000 - Date.now() > 60000) return

                        if (exp * 1000 - Date.now() <= cookie_polling_interval * 1000) {
                            utils.deleteCookie('re-jwt')
                            context.commit('SET_USER', {})
                            context.commit('SET_ROLES', {})
                            context.commit('SET_TIMEOUTPOPUP', {
                                showTimeoutPopup: false,
                            })
                            context.commit('SET_INTERVAL', { cb: null })
                            if (router.currentRoute.name !== 'login') {
                                router.push('/default/login?msg=session-expired')
                            }
                            return
                        }

                        if (exp * 1000 - Date.now() <= 60000) {
                            context.commit('SET_TIMEOUTPOPUP', { showTimeoutPopup: true })
                        }
                    } else {
                        context.commit('SET_USER', {})
                        context.commit('SET_ROLES', {})
                        context.commit('SET_TIMEOUTPOPUP', { showTimeoutPopup: false })
                        context.commit('SET_INTERVAL', { cb: null })
                        if (router.currentRoute.name !== 'login') {
                            router.push('/default/login?msg=session-expired')
                        }
                    }
                },
            })
        },
        end_interval(context) {
            context.commit('SET_INTERVAL', { cb: null })
        },
    },
}
export default auth
