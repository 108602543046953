import { render, staticRenderFns } from "./Sidebar2.vue?vue&type=template&id=26b3b2a0&scoped=true&"
import script from "./Sidebar2.vue?vue&type=script&lang=js&"
export * from "./Sidebar2.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar2.vue?vue&type=style&index=0&id=26b3b2a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26b3b2a0",
  null
  
)

/* custom blocks */
import block0 from "./Sidebar2.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports