const root = process.env.VUE_APP_LOCALE_OVERWRITE.toLowerCase()

export const localeMixin = {
    methods: {
        t(locale) {
            return this.$te(`${root}.${locale}`)
                ? this.$t(`${root}.${locale}`)
                : this.$t(locale)
        },
    },
}
