var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address_map is-relative"},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.noBuildingMatch)?_c('div',{staticClass:"is-user-message"},[_c('b-message',{attrs:{"active":_vm.noBuildingMatch,"title":_vm.$t('message'),"type":"is-info","has-icon":"","aria-close-label":"Close message"}},[_c('span',{staticClass:"is-flex is-flex-direction-column"},[_c('span',[_c('strong',{staticClass:"is-capitalized"},[_vm._v(" "+_vm._s(_vm.$t('address'))+" ")]),_vm._v(" "+_vm._s(_vm.addressInfo.full_address)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('full_address')))])])])],1):_vm._e(),_c('re-map',{attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"level":_vm.getFeatures.level,"center":{ lat: _vm.getFeatures.f_lat, lng: _vm.getFeatures.f_lng },"map-scene2d":_vm.get_map_scene2d,"read-only":!_vm.isPredraft || _vm.$route.params.action === 'confirm-main-building',"focus-layer":_vm.focusLayer,"layers-control-visible":false,"flood-visible":false},on:{"update:mapScene2d":function($event){return _vm.SET_MAP_SCENE2D($event)},"update:buildingId":function($event){return _vm.SELECT_MAIN_BUILDING_ID($event)},"update:parcelIds":function($event){return _vm.SET_PARCEL_IDS($event)}}}),_c('div',{staticClass:"card-wrapper is-absolute"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"is-flex is-justify-content-center"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","type":"is-dark","icon-left":"arrow-left","to":{
                            name: 'request',
                            params: {
                                lang: _vm.$route.params.lang,
                                action: _vm.prev_action,
                            },
                            query: _vm.$route.query,
                        }}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),(_vm.$te('explanation-' + _vm.$route.params.action))?_c('div',{staticClass:"px-4 has-text-black has-text-centered is-w100"},[_vm._v(" "+_vm._s(_vm.$t('explanation-' + _vm.$route.params.action))+" "),_c('b-tooltip',{staticClass:"pl-3 is-clickable is-relative",attrs:{"size":"is-medium","label":_vm.tooltip_label,"multilined":""}},[_c('div',{staticClass:"is-rounded has-text-primary has-background-link is-tootip-btn is-flex is-align-items-center is-justify-content-center has-text-weight-bold"},[_vm._v(" ? ")])])],1):_vm._e(),_c('b-button',{attrs:{"disabled":!_vm.next_possible,"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(" "+_vm._s(_vm.next_action === 'extra-info' && _vm.isPredraft ? _vm.$t('confirm') : _vm.$route.params.action === 'confirm-main-building' ? _vm.$t('yes') : _vm.$t('next'))+" ")])])],1)]),(_vm.$te('cannot-find-' + _vm.$route.params.action))?_c('footer',{staticClass:"card-footer"},[_c('div',{staticClass:"is-flex is-justify-content-center is-w100"},[_c('a',{staticClass:"has-text-primary has-text-centered py-3",on:{"click":_vm.unhappyFlow}},[_vm._v(" "+_vm._s(_vm.$t('cannot-find-' + _vm.$route.params.action))+" ")])])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }