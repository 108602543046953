<i18n>
{
    "fr-BE":{
        "step5": {
            "title": "Télécharger les résultats",
            "subtitle": "Entrez l'adresse email ci-dessous à laquelle nous pouvons envoyer les résultats.",
            "text1": "Le graphique montre comment ce prix cible est lié aux prix de 200 maisons de la région.",
            "text2": "Cette évaluation automatique est une évaluation statistique basée sur les informations fournies et une comparaison avec plusieurs autres propriétés voisines. Il n'y a pas d'examen par un expert immobilier et l'évaluation finale peut changer en conséquence.",
            "send": "envoyer"
        }
    },
    "nl-BE":{
        "step5": {
            "title": "Resultaten downloaden",
            "subtitle": "Vul hieronder het email adres in waar we de resultaten naartoe mogen sturen.",
            "text1": "Op de grafiek ziet u hoe deze richtprijs zich verhoudt tot de woning prijzen van de 200 woningen in de buurt.",
            "text2": "Deze automatische waardeering is een statistische waardeering op basis van verstrekte informatie en een vergelijking met verschillende andere naburige eigendommen. Er is geen controle door een onroerend goed deskundige, en de uiteindelijke waardeering kan daardoor veranderen.",
            "send": "Verzenden"
        }
    },
    "en-BE":{
        "step5": {
            "title": "Download results",
            "subtitle": "Enter the email address below to which we can send the results.",
            "text1": "The graph shows how this target price relates to the house prices of the 200 houses in the area.",
            "text2": "This automatic valuation is a statistical valuation based on information provided and a comparison with several other neighboring properties. There is no review by a real estate expert, and the final valuation may change as a result.",
            "send": "Send"
        }   
    }
}
</i18n>

<template>
    <div id="step5" class="is-flex is-flex-direction-column is-align-items-center px-6">
        <div class="small-title-box">
            <!-- <transition name="fade-move-opacity-small"> -->
            <transition name="fade-move-opacity-small">
                <div v-show="animation.part1" class="small-title">
                    {{ $t('step5.text1') }}
                    <b-message type="is-warning" has-icon>
                        {{ $t('step5.text2') }}
                    </b-message>
                </div>
            </transition>
        </div>
        <transition name="fade-opacity">
            <div class="chart1">
                <cost-plot
                    :part="part"
                    :estimation_results_data="addressInfo.estimationResultsData"
                    :input_data="getFeatures"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import CostPlot from './CostPlot.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Step5',
    components: { CostPlot },
    props: ['modalActive'],
    data() {
        return {
            email: '',
            animation: {
                part1: false,
                part2: false,
                part3: false,
                part2boxes: false,
            },
            part: 1,
            errors: {},
        }
    },
    computed: {
        ...mapGetters('Address', ['getFeatures', 'addressInfo']),
    },
    created() {
        this.animation.part1 = true
        setTimeout(() => {
            this.animation.part2 = true
        }, 400)
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
#step5 {
    .chart1 {
        max-width: 940px;
        width: 100%;
        height: 150px;
        transition: 0.5s all;
        margin-top: 50px;
    }
    .small-title {
        max-width: 940px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #798da6;
        position: relative;
        transition: 0.4s all;
        &-box {
            margin-bottom: 80px;
            margin-top: 100px;
            transition: 0.4s all;
        }
    }
}
</style>
