<template>
    <div></div>
</template>

<script>
import Rockestate3D from './renderer3d'

export default {
    components: {},
    props: {
        buildingId: {
            type: String,
            default: null,
        },
        solarPotential: {
            type: Boolean,
            default: false,
        },
        autoRotate: {
            type: Boolean,
            default: true,
        },
        showAttributions: {
            type: Boolean,
            default: true,
        },
        mapScene3d: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {}
    },
    watch: {
        buildingId: {
            immediate: true,
            handler(buildingId, oldBuildingId) {
                if (!buildingId || this.mapScene3d.building_id === buildingId) {
                    return
                }
                this.$axios
                    .get(process.env.VUE_APP_API_URL + '/map/scene', {
                        params: {
                            building_id: buildingId,
                            solar_potential: this.solarPotential,
                            radius: 40,
                            hide_attributions: !this.showAttributions,
                        },
                    })
                    .then((response) => {
                        this.$emit('update:mapScene3d', response.data)
                    })
                    .catch((error) => console.log(error))
            },
        },
        mapScene3d: {
            immediate: true,
            handler(mapScene3d, oldMapScene3d) {
                this.$nextTick(function () {
                    if (!mapScene3d.building_id) {
                        return
                    }
                    Rockestate3D.attach(this.$el)
                    Rockestate3D.autoRotate = this.autoRotate
                    Rockestate3D.build_neighbourhood(mapScene3d)
                })
            },
        },
    },
    mounted() {
        Rockestate3D.api_url = process.env.VUE_APP_API_URL
        Rockestate3D.attribution_text = this.showAttributions ? 'Source: ' : ''
        Rockestate3D.attach(this.$el)
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
div {
    height: 100%;
    width: 100%;
}
</style>
