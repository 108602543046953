import { Scatter } from 'vue-chartjs'

import utils from '@/shared/plugins/utils'
const value_formatter = utils.value_formatter
const quantile_formatter = utils.quantile_formatter

export default {
    extends: Scatter,
    props: ['values', 'point', 'part', 'value', 'left', 'right'],
    created() {
        if (this.$route.params.step == 4) {
            setTimeout(() => {
                this.updateChart()
            }, 1000)
        }
    },
    mounted() {
        this.point.forEach((e) => {
            e.y += 5
        })
        this.values.forEach((e) => {
            e.y += 5
        })
        this.values.sort((a, b) => a.x - b.x)
        this.initChart()
    },
    methods: {
        initChart() {
            var line = [
                { x: this.point[0].x, y: 0 },
                { x: this.point[0].x, y: 11 },
            ]

            // Overwriting base render method with actual data.
            this.renderChart(
                {
                    // This needs improvement, the data should be passed as props
                    labels: 'label',
                    datasets: [
                        {
                            label: 'test1',
                            data: this.point,
                            pointRadius: 10,
                            backgroundColor: '#ff6f00',
                            borderColor: '#ff3100',
                            borderWidth: 2,
                            pointHoverRadius: 10,
                        },
                        {
                            label: 'test',
                            data: this.values,
                            backgroundColor: 'dodgerblue',
                            borderColor: '#0080e3',
                            pointRadius: 6,
                            borderWidth: 2,
                            pointHoverRadius: 6,
                        },
                        {
                            label: 'line',
                            data: line,
                            backgroundColor: '#ff3100',
                            borderColor: '#ff3100',
                            showLine: true,
                            fill: false,
                            pointRadius: 0,
                            borderWidth: 2,
                        },
                    ],
                },
                {
                    // height: 150,
                    // animation: {
                    //   duration: 0
                    // },
                    scales: {
                        yAxes: [
                            {
                                display: false,
                                //min:-3,
                                // max:10,
                            },
                        ],
                        xAxes: [
                            {
                                // type: 'logarithmic',
                                display: true,
                                ticks: {
                                    min: 0,
                                    stepSize: 50000,
                                    callback: function(value, index, values) {
                                        // REVIEW updated money format perv. was causing error
                                        // P.S maybe you need to change -currency- or -de-DE- for format change

                                        return value_formatter(value)
                                        //value.formatMoney(0, ',', '.') + '€'
                                    },
                                },
                            },
                        ],
                    },
                    // animation: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: false,
                    layout: {
                        padding: {
                            left: 10,
                            right: 20,
                            top: 0,
                            bottom: 0,
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: (context) => {
                                if (context.datasetIndex == 1) {
                                    return `${value_formatter(
                                        context.xLabel
                                    )} (${quantile_formatter(
                                        context.index / this.values.length
                                    )})`
                                } else if (context.datasetIndex == 0) {
                                    return value_formatter(context.xLabel)
                                } else {
                                    return context.xLabel
                                }
                            },
                        },
                    },
                }
            )

            setTimeout(() => {
                let width = parseInt(document.getElementById('scatter-chart').style.width)
                this.$emit('input', {
                    tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]
                        ._model.x /
                        width) *
                        100}%`,
                    leftLinePosition: `-1px`,
                    rightLinePosition: `-1px`,
                })
            }, 400)
            window.addEventListener('resize', () => {
                let width = parseInt(document.getElementById('scatter-chart').style.width)
                // with if statement get undefined error
                if (this.$data._chart.getDatasetMeta(0).data[0]) {
                    this.$emit('input', {
                        tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]
                            ._model.x /
                            width) *
                            100}%`,
                        leftLinePosition: `${(this.$data._chart.getDatasetMeta(3).data[0]
                            ._model.x /
                            width) *
                            100}%`,
                        rightLinePosition: `${(this.$data._chart.getDatasetMeta(4).data[0]
                            ._model.x /
                            width) *
                            100}%`,
                    })
                }
            })
        },
        updateChart() {
            /*this.$data._chart.config.options.scales.xAxes[0].ticks.min =
                this.point[0].x - this.left * 4*/
            this.$data._chart.config.options.scales.xAxes[0].ticks.max =
                this.point[0].x * 2
            this.$data._chart.config.data.datasets.push({
                label: 'line',
                data: [
                    { x: this.point[0].x - this.left, y: 0 },
                    { x: this.point[0].x - this.left, y: 12 },
                ],
                borderDash: [5, 5],
                backgroundColor: '#ff3100',
                borderColor: '#ff3100',
                showLine: true,
                fill: false,
                pointRadius: 0,
                borderWidth: 2,
            })

            this.$data._chart.config.data.datasets.push({
                label: 'line',
                data: [
                    { x: this.point[0].x + this.right, y: 0 },
                    { x: this.point[0].x + this.right, y: 12 },
                ],
                borderDash: [5, 5],
                backgroundColor: '#ff3100',
                borderColor: '#ff3100',
                showLine: true,
                fill: false,
                pointRadius: 0,
                borderWidth: 2,
            })
            this.$data._chart.update()

            this.$emit('input', {
                tooltipPosition: `-1px`,
                leftLinePosition: `-1px`,
                rightLinePosition: `-1px`,
            })

            setTimeout(() => {
                var chart = document.getElementById('scatter-chart').getContext('2d')
                let xAxis = this.$data._chart.scales['x-axis-1']
                let gradient = chart.createLinearGradient(
                    xAxis.getPixelForValue(this.point[0].x - this.left),
                    0,
                    xAxis.getPixelForValue(this.point[0].x),
                    0
                )
                gradient.addColorStop(0, 'rgba(255, 0, 0, 0)')
                gradient.addColorStop(1, 'rgba(255, 0, 0, 0.8)')
                let gradientInvert = chart.createLinearGradient(
                    xAxis.getPixelForValue(this.point[0].x),
                    0,
                    xAxis.getPixelForValue(this.point[0].x + this.right),
                    0
                )
                gradientInvert.addColorStop(0, 'rgba(255, 0, 0, 0.8)')
                gradientInvert.addColorStop(1, 'rgba(255, 0, 0, 0)')
                this.$data._chart.config.data.datasets.push({
                    label: 'gradient',
                    data: [
                        { x: this.point[0].x, y: 12 },
                        { x: this.point[0].x - this.left, y: 12 },
                    ],
                    backgroundColor: gradient,
                    showLine: true,
                    fill: true,
                    fillColor: gradient,
                    pointRadius: 0,
                    borderWidth: 0,
                    borderColor: gradient,
                    pointBorderColor: gradient,
                    pointBackgroundColor: gradient,
                    pointHoverBackgroundColor: gradient,
                    pointHoverBorderColor: gradient,
                })
                this.$data._chart.config.data.datasets.push({
                    label: 'gradientInvert',
                    data: [
                        { x: this.point[0].x, y: 12 },
                        { x: this.point[0].x + this.right, y: 12 },
                    ],
                    backgroundColor: gradientInvert,
                    showLine: true,
                    fill: true,
                    fillColor: gradientInvert,
                    pointRadius: 0,
                    borderWidth: 0,
                    borderColor: gradientInvert,
                    pointBorderColor: gradientInvert,
                    pointBackgroundColor: gradientInvert,
                    pointHoverBackgroundColor: gradientInvert,
                    pointHoverBorderColor: gradientInvert,
                })
                this.$data._chart.update()
                // let point = Object.values(this.$data._chart.data.datasets[0]._meta)[0]
                // console.log(point)

                let width = parseInt(document.getElementById('scatter-chart').style.width)

                this.$emit('input', {
                    tooltipPosition: `${(this.$data._chart.getDatasetMeta(0).data[0]
                        ._model.x /
                        width) *
                        100}%`,
                    leftLinePosition: `${(this.$data._chart.getDatasetMeta(3).data[0]
                        ._model.x /
                        width) *
                        100}%`,
                    rightLinePosition: `${(this.$data._chart.getDatasetMeta(4).data[0]
                        ._model.x /
                        width) *
                        100}%`,
                })
            }, 1000)
        },
    },
}
