<i18n>
{
  "nl-BE": {
       "help_me": "Help me, ik ben geblokkeerd!",
       "FAQ": "Meer informatie" 
  },
  "en-BE": {
      "help_me": "Help me, I am blocked!",
       "FAQ": "FAQ" 
  },
  "fr-BE": {
      "help_me": "Aidez-moi, je suis bloqué !",
       "FAQ": "FAQ" 
  }
}
</i18n>

<template>
    <div class="is-info-warpper is-fixed">
        <b-tooltip
            type="is-light"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
            position="is-left"
        >
            <template #content>
                <div class="is-flex is-flex-direction-column px-3 has-text-centered">
                    <a class="py-1" @click="openPage">
                        {{ $t('help_me') }}
                    </a>
                    <router-link class="py-1" :to="{ params: { action: 'faq' } }">
                        {{ $t('FAQ') }}
                    </router-link>
                </div>
            </template>
            <b-button
                rounded
                type="is-link"
                class="has-text-primary is-tootip-btn is-flex is-align-items-center is-justify-content-center"
            >
                <span class="has-text-weight-bold is-size-4">?</span>
            </b-button>
        </b-tooltip>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations(['SET_HELP_PAGE']),
        openPage() {
            if (!this.$route.query.msg) {
                this.SET_HELP_PAGE(this.$route.params.action)
                this.$router.push({
                    params: { action: 'feedback' },
                    query: { msg: 'blocked' },
                })
            }
        },
    },
}
</script>

<style lang="scss">
.is-info-warpper {
    bottom: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    position: relative;
    z-index: 999;
    .is-size-4 {
        line-height: 1;
    }
}
</style>
