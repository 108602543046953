/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ruler': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: '<path pid="0" d="M4.067 18.567L.934 15.433a.805.805 0 010-1.166L13.6 1.6a.805.805 0 011.167 0L17.9 4.733a.806.806 0 010 1.167L5.234 18.567c-.334.333-.867.333-1.167 0z" _fill="#798DA6" fill-opacity=".2" _stroke="#798DA6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M2.067 13.133l2.167 2.134M5.533 9.666L7.7 11.8M9 6.2l2.133 2.166M12.467 2.733L14.6 4.9M3.8 11.4l1.3 1.267M7.267 7.933L8.533 9.2M10.733 4.466L12 5.766" _stroke="#798DA6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
