<template>
    <div class="column is-12-desktop is-12-tablet">
        <b-field custom-class="is-small-tooltip">
            <template v-if="!isEmptyStr(label)" slot="label">
                {{ label }}
                <b-tooltip
                    v-if="!isEmptyStr(tooltip)"
                    class="ml-2 mb-1"
                    type="is-primary"
                    :label="tooltip"
                    multilined
                >
                    <div
                        class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                    >
                        ?
                    </div>
                </b-tooltip>
            </template>
        </b-field>
        <div class="pt-1">
            <b-field grouped group-multiline :type="type" :message="message">
                <b-checkbox-button
                    v-for="item in rangeList"
                    :key="item"
                    :value="value"
                    :native-value="item"
                    @input="$emit('input', $event)"
                >
                    <span>
                        {{ buttonLabelRenderer(item) }}
                    </span>
                </b-checkbox-button>
            </b-field>
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        message: {
            type: Array,
            default: null,
        },
        type: {
            type: Object,
            required: false,
            default: null,
        },
        rangeList: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        buttonLabelRenderer: {
            type: Function,
            required: false,
            default: (num) => num,
        },
    },
    methods: {
        isEmptyStr: (str) => utils.isEmptyStr(str),
    },
}
</script>
